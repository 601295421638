export const hospitalToasts: any = {
  Create: "Hospital Created successfully",
  Save: "Hospital details updated successfully",
  Invite: "Hospital Initiated successfully",
  ReInitiate: "Hospital ReInitiated successfully",
  Activate: "Hospital Activated successfully",
  Deactivate: "Hospital Deactivated successfully",
  Approve: "Hospital Approved successfully",
  SomethingWentWrong: "Something went wrong",
};
