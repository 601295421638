import "./HospitalUserForm.scss";
import { ReactComponent as Logo } from "../../../assets/svg/hospital-user-form-logo.svg";
import { ReactComponent as HospitalUser } from "../../../assets/svg/hospital-user-form.svg";
import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import Input from "../../shared/Input/Input";
import Button from "@mui/material/Button";
import { ReactComponent as Add } from "../../../assets/svg/add.svg";
import { ReactComponent as Submit } from "../../../assets/svg/submitted.svg";
import { ReactComponent as Submission } from "../../../assets/svg/submission.svg";
import { ReactComponent as Expired } from "../../../assets/svg/expired.svg";
import { ReactComponent as Mail } from "../../../assets/svg/mail.svg";

import { hospitalForms_LABELS_LITERALS } from "../../../constants/labels/Hospital-Integration/HospitalUserForm";
import {
  useGetHospitalRegistrationInformationQuery,
  useLazySubmitHospitalDataQuery,
} from "../../../services/rtkQueryServices/hospitalService";
import { useNavigate, useParams } from "react-router-dom";
import { routePaths } from "../../../constants/routePaths";
import { snackbarWrapper } from "../../shared/SnackbarWrapper/SnackbarWrapper";
import { hospitalToasts } from "../../../constants/toasts/hospitalToasts";
import { regex } from "../../../constants/regex";
import { validationMessages } from "../../../constants/forms/validationMessages";

function HospitalUserForm(props: any) {
  const { tenantApplicationInformationId } = useParams();
  const { data: hospitalDetails, error } =
    useGetHospitalRegistrationInformationQuery(tenantApplicationInformationId);
  const [submitHospitalData, hospitalData] = useLazySubmitHospitalDataQuery();
  const [formInitialValues, setForminitialValues] = useState<any>({});
  const [hospitalInformation, setHospitalInformation] = useState<any>();
  const navigate = useNavigate();
  useEffect(() => {
    error && navigate(routePaths.error);
    hospitalDetails &&
      setForminitialValues({
        tenantApplicationInformationId:
          hospitalDetails.data.tenantApplicationInformationId,
        hospitalName: hospitalDetails.data.hospitalName,
        npis:
          hospitalDetails.data.npis?.length > 0
            ? hospitalDetails.data.npis
            : [""],
        email: hospitalDetails.data.emailAddress,
        contactFirstName: hospitalDetails.data.contactFirstName,
        contactLastName: hospitalDetails.data.contactLastName,
        ehrType: hospitalDetails.data.ehrTypeName,
        ehrTypeId: hospitalDetails.data.ehrTypeId,
        appType: hospitalDetails.data.applicationTypeName,
        appTypeId: hospitalDetails.data.applicationTypeId,
        baseURL: "",
        valid: true,
      });
    hospitalDetails && setHospitalInformation(hospitalDetails?.data);
  }, [hospitalDetails, error]);

  const validationSchema = () =>
    Yup.object({
      hospitalName: Yup.string().required("Hospital name is required"),
      contactFirstName: Yup.string().required("Contact First name is required"),
      contactLastName: Yup.string().required("Contact Last name is required"),
      email: Yup.string()
        .required("Email address name is required")
        .matches(regex.emailValidation, validationMessages.validEmail),
      npis: Yup.array(),
      ehrType: Yup.string().required("EHR type is required"),
      appType: Yup.string().required("App type is required"),
      baseURL: Yup.string().required("Base URL is required"),
    });

  return (
    <>
      {hospitalInformation && (
        <div className="hospital-user-container">
          <div className="hospital-user-form-wrapper h-100 p-5 d-flex gap-4 position-relative">
            {hospitalInformation?.tenantStatusId === 2 ||
            hospitalInformation?.tenantStatusId === 8 ? (
              <>
                <div className="hospital-user-form-left-wrapper d-flex flex-column justify-content-between align-items-center">
                  <Logo />
                  <HospitalUser />
                </div>
                <div className="hospital-user-form-right-wrapper p-3">
                  {hospitalData.isSuccess ? (
                    <div className="d-flex justify-content-center submit-form">
                      <Submit />
                      <div>
                        Details submitted successfully,
                        <br />
                        Our team will get back to you.
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mb-4 hospital-user-form-title">
                        {hospitalForms_LABELS_LITERALS.hospitalRegistration}
                      </div>
                      <Formik
                        enableReinitialize={true}
                        initialValues={formInitialValues}
                        validationSchema={validationSchema}
                        onSubmit={(values: any) => {
                          submitHospitalData(values).then((res) => {
                            if (res.isError) {
                              snackbarWrapper(
                                (res.error as any).data?.message ||
                                  hospitalToasts.SomethingWentWrong,
                                "error"
                              );
                            }
                          });
                        }}
                      >
                        {(formik) => (
                          <Form
                            onSubmit={formik.handleSubmit}
                            className="hospital-user-form-container"
                          >
                            <div className="hospital-user-form d-flex flex-row gap-4">
                              <div className="hospital-user-form-left">
                                <div>
                                  <Input
                                    name="hospitalName"
                                    errorMsg={
                                      formik.touched.hospitalName &&
                                      formik.errors.hospitalName &&
                                      (formik.errors as any).hospitalName
                                    }
                                    onInput={(e) => {
                                      formik.handleChange(e);
                                    }}
                                    required
                                    maxLength={256}
                                    label={
                                      hospitalForms_LABELS_LITERALS.hospitalName
                                    }
                                    placeholder={
                                      hospitalForms_LABELS_LITERALS.hospitalName
                                    }
                                    value={formik.values.hospitalName}
                                  />
                                </div>
                                <div>
                                  <Input
                                    name="contactFirstName"
                                    errorMsg={
                                      formik.touched.contactFirstName &&
                                      formik.errors.contactFirstName &&
                                      (formik.errors as any).contactFirstName
                                    }
                                    onInput={(e) => {
                                      formik.handleChange(e);
                                    }}
                                    required
                                    maxLength={128}
                                    label={
                                      hospitalForms_LABELS_LITERALS.contactFirstName
                                    }
                                    value={formik.values.contactFirstName}
                                  />
                                </div>
                                <div>
                                  <Input
                                    name="appType"
                                    errorMsg={
                                      formik.touched.appType &&
                                      formik.errors.appType &&
                                      (formik.errors as any).appType
                                    }
                                    required
                                    label={
                                      hospitalForms_LABELS_LITERALS.appType
                                    }
                                    value={formik.values.appType}
                                    disabled
                                  />
                                </div>
                                <div>
                                  <Input
                                    name="baseURL"
                                    label={
                                      hospitalForms_LABELS_LITERALS.r4Endpoint
                                    }
                                    showHelpTextIcon
                                    labelHelpText={
                                      hospitalForms_LABELS_LITERALS.baseURLHelpText
                                    }
                                    required
                                    maxLength={100}
                                    onInput={(e) => {
                                      formik.handleChange(e);
                                    }}
                                    errorMsg={
                                      formik.touched.baseURL &&
                                      formik.errors.baseURL &&
                                      (formik.errors as any).baseURL
                                    }
                                    value={formik.values.baseURL}
                                  />
                                </div>
                              </div>
                              <div className="hospital-user-form-right">
                                <div>
                                  <Input
                                    name="email"
                                    maxLength={128}
                                    errorMsg={
                                      formik.touched.email &&
                                      formik.errors.email &&
                                      (formik.errors as any).email
                                    }
                                    required
                                    label={
                                      hospitalForms_LABELS_LITERALS.emailAddress
                                    }
                                    value={formik.values.email}
                                    disabled
                                  />
                                </div>
                                <div>
                                  <Input
                                    name="contactLastName"
                                    maxLength={128}
                                    errorMsg={
                                      formik.touched.contactLastName &&
                                      formik.errors.contactLastName &&
                                      (formik.errors as any).contactLastName
                                    }
                                    onInput={(e) => {
                                      formik.handleChange(e);
                                    }}
                                    required
                                    label={
                                      hospitalForms_LABELS_LITERALS.contactLastName
                                    }
                                    value={formik.values.contactLastName}
                                  />
                                </div>

                                <div>
                                  <Input
                                    name="ehrType"
                                    errorMsg={
                                      formik.touched.ehrType &&
                                      formik.errors.ehrType &&
                                      (formik.errors as any).ehrType
                                    }
                                    required
                                    label={
                                      hospitalForms_LABELS_LITERALS.EHRType
                                    }
                                    value={formik.values.ehrType}
                                    disabled
                                  />
                                </div>
                                <FieldArray
                                  name="npis"
                                  render={(arrayHelpers) =>
                                    formik.values.npis &&
                                    formik.values.npis.length > 0 &&
                                    formik.values.npis.map(
                                      (value: any, index: any) => (
                                        <div
                                          key={index}
                                          className="npi-input-container"
                                        >
                                          <Input
                                            maxLength={10}
                                            onClear={() =>
                                              arrayHelpers.remove(index)
                                            }
                                            placeholder={`NPI${index + 1}`}
                                            required={
                                              index + 1 !==
                                              formik.values.npis.length
                                            }
                                            errorMsg={
                                              index + 1 !==
                                                formik.values.npis.length &&
                                              formik.touched.ehrType &&
                                              formik.errors.ehrType &&
                                              "Npi is required"
                                            }
                                            disabled={false}
                                            label={`NPI${index + 1}`}
                                            name={`npis.${index}`}
                                            value={value}
                                            onChange={formik.handleChange}
                                            showClear={
                                              !(
                                                index === 0 &&
                                                formik.values.npis.length === 1
                                              )
                                            }
                                          />
                                          {index + 1 ===
                                            formik.values.npis.length && (
                                            <button
                                              className="add-button"
                                              type="button"
                                              disabled={value.length < 10}
                                              onClick={() =>
                                                arrayHelpers.insert(
                                                  index + 1,
                                                  ""
                                                )
                                              }
                                            >
                                              <Add />
                                            </button>
                                          )}
                                        </div>
                                      )
                                    )
                                  }
                                ></FieldArray>
                              </div>
                            </div>
                            <div className="d-flex justify-content-end mt-2">
                              <Button
                                // className="right-form-button"
                                variant="contained"
                                color="primary"
                                type="submit"
                              >
                                {hospitalForms_LABELS_LITERALS.submit}
                              </Button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </>
                  )}
                </div>
              </>
            ) : hospitalInformation?.tenantStatusId === 3 ? (
              <div className="hospital-user-form-right-wrapper p-3 w-100 expired-form d-flex justify-content-center flex-column align-items-center">
                <Logo className="hospitalform-logo" />
                <div className="expired-svg">
                  <Expired />
                </div>
                <div className="form-details">
                  {hospitalForms_LABELS_LITERALS.linkExpired}
                </div>
                <div className="d-flex align-items-center support-contact">
                  <div className="contact-text">{hospitalForms_LABELS_LITERALS.contactSupport}</div>
                  <Mail />
                  <div>{hospitalForms_LABELS_LITERALS.supportEmailId}</div>
                </div>
              </div>
            ) : (
              <div className="hospital-user-form-right-wrapper p-3 w-100 expired-form d-flex justify-content-center flex-column align-items-center">
                <Logo className="hospitalform-logo" />
                <div className="form-details">
                  {hospitalForms_LABELS_LITERALS.hospitalDetailsSubmitted}
                </div>
                <div className="submitted-svg">
                  <Submission />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default HospitalUserForm;
