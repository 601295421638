import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
import { addProviderRequest } from "../../models/Provider/addProviderRequest";
export const hospitalApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    createHospital: builder.query({
      query: (hospital: addProviderRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.CREATE_HOSPITAL}`,
        method: "POST",
        body: JSON.stringify(hospital),
      }),
    }),
    getAuditsByTenantId: builder.query({
      query: (tenantId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AUDITS_BY_TENANT_ID}`,
        method: "POST",
        body: JSON.stringify(tenantId),
        headers: {
          "Content-Type": "application/json",
        },
      }),
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useCreateHospitalQuery,
  useLazyCreateHospitalQuery,
  useGetAuditsByTenantIdQuery,
} = hospitalApi;
