import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const emailTemplateApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getEmailTemplateVersionsByEmailTemplateId: builder.query({
      query: (emailTemplateId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_EMAIL_TEMPLATE_VERSIONS_BY_EMAIL_TEMPLATE_ID}${emailTemplateId}`,
        method: "GET",
      }),
    }),
    GetEmailTemplateVersionByTemplateId: builder.query({
      query: (emailTemplateId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_EMAIL_TEMPLATE_VERSION_BY_EMAIL_TEMPLATE_ID}${emailTemplateId}`,
        method: "GET",
      }),
    }),
    updateEmailTemplateTypeStatus: builder.query({
      query: (request) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.UPDATE_EMAIL_TEMPLATE_TYPE_STATUS}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(request),
      }),
    }),
    updateEmailTemplateStatus: builder.query({
      query: (request) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.UPDATE_EMAIL_TEMPLATE_STATUS}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(request),
      }),
    }),
    createOrUpdateEmailTemplate: builder.query({
      query: (request) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.CREATE_OR_UPDATE_EMAIL_TEMPLATE}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(request),
      }),
    }),
  }),
});

export const {
  useLazyGetEmailTemplateVersionByTemplateIdQuery,
  useLazyGetEmailTemplateVersionsByEmailTemplateIdQuery,
  useLazyUpdateEmailTemplateTypeStatusQuery,
  useLazyCreateOrUpdateEmailTemplateQuery,
  useGetEmailTemplateVersionByTemplateIdQuery,
  useLazyUpdateEmailTemplateStatusQuery,
} = emailTemplateApi;
