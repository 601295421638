import { useMemo, useState } from "react";
import { user_password_update_LABELS } from "../../constants/labels/UserManagement/UserPasswordUpdate";
import { UserService } from "../../services/UserService";
import * as Yup from "yup";
import { validationMessages } from "../../constants/forms/validationMessages";
import { regex } from "../../constants/regex";
import { Formik, Form } from "formik";
import { ReactComponent as Email } from "../../assets/svg/mail.svg";
import Input from "../shared/Input/Input";
import Button from "@mui/material/Button";
import "./UpdatePassword.scss";
import { useLazyUpdateUserPasswordQuery } from "../../services/rtkQueryServices/authenticationService";

function UpdatePassword(props: any) {
  const initialFormValue = useMemo<any>(() => {
    return {
      password: "",
      confirmPassword: "",
    };
  }, []);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const formInitialValues = initialFormValue;
  const [updateUserPassword] = useLazyUpdateUserPasswordQuery();
  const validationSchema = () =>
    Yup.object({
      currentPassword: Yup.string()
        .required(validationMessages.passwordRequired)
        .min(8, validationMessages.passwordLength),
      password: Yup.string()
        .required(validationMessages.passwordRequired)
        .min(8, validationMessages.passwordLength)
        .matches(
          regex.passwordValidation,
          validationMessages.passwordRegexMatch
        ),
      confirmPassword: Yup.string()
        .required(validationMessages.passwordRequired)
        .oneOf([Yup.ref("password")], validationMessages.confirmPassword),
    });
  return (
    <>
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="bg-white w-100 update-password-wrapper p-5 d-flex justify-content-center align-items-center">
          <div className="px-5 py-4 update-password w-100">
            <Formik
              initialValues={{
                ...formInitialValues,
              }}
              validationSchema={validationSchema}
              onSubmit={(values: any) => {
                let updateRequest = {
                  userEmail: UserService?.getClaims?.email,
                  ...values,
                };
                updateUserPassword(updateRequest).then((res: any) => {
                  if (res?.data?.isSuccess) {
                    UserService.logOut();
                  } else {
                    setErrorMessage(res?.error?.data?.message);
                  }
                });
              }}
            >
              {(formik) => (
                <Form
                  onSubmit={formik.handleSubmit}
                  className="login-form-container"
                >
                  <div className="login-form">
                    <div className="login-header mb-4">
                      {user_password_update_LABELS.changePassword}
                    </div>
                    <div className="login-mail mb-4">
                      <Email className="me-2" />
                      {UserService?.getClaims?.email}
                    </div>
                    <Input
                      name="currentPassword"
                      placeholder="Enter current password"
                      type="password"
                      errorMsg={
                        formik.touched.currentPassword &&
                        formik.errors.currentPassword &&
                        (formik.errors as any).currentPassword
                      }
                      value={formik.values.currentPassword}
                      onInput={formik.handleChange}
                      required
                    />
                    <Input
                      name="password"
                      placeholder="New Password"
                      type="password"
                      errorMsg={
                        formik.touched.password &&
                        formik.errors.password &&
                        (formik.errors as any).password
                      }
                      value={formik.values.password}
                      onInput={formik.handleChange}
                      required
                    />
                    <Input
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      type="password"
                      errorMsg={
                        formik.touched.confirmPassword &&
                        formik.errors.confirmPassword &&
                        (formik.errors as any).confirmPassword
                      }
                      required
                      value={formik.values.confirmPassword}
                      onInput={formik.handleChange}
                    />
                  </div>
                  {errorMessage && (
                    <span className="error-message mb-2">{errorMessage}</span>
                  )}
                  <div className="login-actions d-flex justify-content-end">
                    <Button
                      type="submit"
                      disabled={!formik.dirty || !formik.isValid}
                      variant="contained"
                    >
                      {user_password_update_LABELS.submit}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
