export const DashboardMetricTypeEnums = {
  registeredHospitals: 1,
  launchedHospitals: 2,
  approvedButNeverLaunched: 3,
  patientsSaved: 4,
  patientsPresented: 5,
  launchFailures: 6,
  patientsNotAvailable: 7,
  patientsDeleted: 8,
};
