export const API_CONSTANTS = {
  BASE_URL: process.env.REACT_APP_ADMIN_BASE_URL,
  BASE_AUTHENTICATION_URL: process.env.REACT_APP_AUTHENTICATION_BASE_URL,
  TOGGLE_USER_STATUS: "/User/ToggleUserStatus",
  CREATE_HOSPITAL: "/Hospital/CreateHospital",
  UPDATE_HOSPITAL: "/Hospital/UpdateHospital",
  GET_ALL_HOSPITAL: "/Hospital/GetAllHospitals",
  GET_TENANT_APPLICATION_INFORMATION:
    "/Hospital/GetTenantApplicationInformation/",
  LOGIN: "/Authentication/login",
  GET_USER_ROLES: "/Authentication/GetAllRoles",
  UPDATE_PASSWORD: "/Authentication/UpdatePassword",
  UPDATE_USER_PASSWORD: "/Authentication/UpdateUserPassword",
  GET_HOSPITAL_REGISTRATION_DETAILS: "/Hospital/GetHospitalRegistrationData/",
  UPDATE_CLIENTID: "/Hospital/UpdateClientId",
  GET_ALL_USERS: "/User/GetAllUsers",
  GET_HOSPITAL_REGISTRARTION_DETAILS:
    "/Hospital/GetTenantApplicationInformation?",
  GET_HOSPITAL_BY_TENANT_ID: "/Hospital/GetHospitalByTenantId?tenantId=",
  VALIDATE_OTP: "/OTPValidation/ValidateOTP",
  RESEND_OTP: "/OtpValidation/ResendOTP",
  REGISTER_USER: "/Authentication/Register",
  GET_USER_NOTIFICATIONS: "/Notification/GetAllUserNotificationsByUser/",
  GET_ALL_NOTIFICATIONS: "/Notification/GetAllNotifications/",
  GET_ALL_PENDING_ACTIONS: "/Notification/GetPendingActions",
  SUBSCRIBE_EMAIL_NOTIFICATION: "/Notification/SubscribeEmailNotifications/",
  UNSUBSCRIBE_EMAIL_NOTIFICATION:
    "/Notification/UnSubscribeEmailNotifications/",
  FETCH_SUBSCRIBED_NOTIFICATION_LEVEL_BY_USER_EMAIL:
    "/Notification/FetchSubscribedNotificationLevelByUserEmail/",
  TOGGLE_USER_EMAIL_NOTIFICATION_STATUS:
    "/Notification/UpdateUserNotificationStatus?userId=",
  UPDATE_USER: "/Authentication/UpdateUser",
  RESET_PASSWORD: "/Authentication/ResetPassword",
  GET_USER_EMAIL_BY_USER_ID: "/Authentication/GetUserById?userId=",
  FETCH_USER_BY_EMAIL: "/User/FetchUserByEmail/",
  GET_AUDITS_BY_TENANT_ID: "/Hospital/GetAuditsByTenantId",
  GET_AUDITS_BY_USER_ID: "/User/GetUserAudits",
  GET_ALL_EHR_TYPES: "/MasterData/GetAllEhrTypes",
  GET_ALL_APPLICATION_TYPES: "/MasterData/GetAllApplicationTypes",
  GET_TENANT_STATUS: "/MasterData/GetAllTenantStatuses",
  GET_NOTIFICATION_LEVELS: "/MasterData/GetAllNotificationLevels",
  GET_MONITORING_APPLICATION_LOGS: "/ApplicationMonitoring/GetLogs",
  GET_ALL_TENANT_NAMES: "/Hospital/GetAllTenantNames",
  GET_MONITORING_METRICS: "/ApplicationMonitoring/GetMetrics",
  GET_RESOURCE_METRICS_LOGS: "/ApplicationMonitoring/GetResourceMetricLogs",
  GET_EMAIL_TEMPLATE_TYPES: "/MasterData/GetAllEmailTemplateTypes",
  GET_EMAIL_TEMPLATE_VERSIONS_BY_EMAIL_TEMPLATE_ID:
    "/EmailTemplate/GetEmailTemplateVersionsByEmailTemplateTypeId?emailTemplateTypeId=",
  GET_EMAIL_TEMPLATE_VERSION_BY_EMAIL_TEMPLATE_ID:
    "/EmailTemplate/GetEmailTemplateVersion?emailTemplateId=",
  UPDATE_EMAIL_TEMPLATE_TYPE_STATUS:
    "/EmailTemplate/UpdateEmailTemplateTypeStatus",
  CREATE_OR_UPDATE_EMAIL_TEMPLATE: "/EmailTemplate/CreateOrUpdateEmailTemplate",
  UPDATE_EMAIL_TEMPLATE_STATUS: "/EmailTemplate/UpdateEmailTemplateStatus",
  REFRESH_TOKEN: "/Authentication/RefreshToken",
  SIGN_OUT: "/Authentication/SignOut",
  GET_TENANT_APPLICATION_URLS: "/Hospital/GetTenantApplicationUrls",
  GET_URL_AUDITS: "/Hospital/GetUrlAudits",
  VERIFY_PATIENT_DETAILS: "/PatientDelete/VerifyPatientDetails",
  DELETE_PATIENT: "/PatientDelete/DeletePatient",
  GET_DASHBAORD_METRICS: "/Dashboard/GetDashboardMetrics",
  DOWNLOAD_METRIC_EXCEL_FILE: "/Dashboard/DownloadMetricExcelFile",
  DOWNLOAD_CONSOLIDATED_METRICS_EXCEL_FILE:
    "/Dashboard/DownloadMetricsConsolidatedExcelFile",
  GET_DASHBOARD_DATE_FILTER_RANGE: "/Dashboard/GetDashboardDateFilterRange",
};
