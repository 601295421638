import { API_CONSTANTS } from "../../constants/apiConstants";
import { addProviderRequest } from "../../models/Provider/addProviderRequest";
import { UserService } from "../UserService";
import baseApi from "./baseApiService";
export const hospitalApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getHospitalRegistrationInformation: builder.query({
      query: (tenantId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_HOSPITAL_REGISTRATION_DETAILS}${tenantId}`,
        method: "GET",
      }),
    }),
    submitHospitalData: builder.query({
      query: (hospitalData) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.UPDATE_CLIENTID}`,
        method: "PUT",
        body: JSON.stringify(hospitalData),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getTenantApplicationInformation: builder.query({
      query: (tenantId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_TENANT_APPLICATION_INFORMATION}${tenantId}`,
        method: "GET",
      }),
    }),
    registerHospital: builder.query({
      query: (hospitalRequest: addProviderRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.CREATE_HOSPITAL}`,
        method: "POST",
        body: JSON.stringify(hospitalRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    updateHospital: builder.query({
      query: (updateHospital) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.UPDATE_HOSPITAL}`,
        method: "PUT",
        body: JSON.stringify(updateHospital),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getAllHospitals: builder.query({
      query: (allHospitals) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_HOSPITAL}`,
        method: "POST",
        body: JSON.stringify(allHospitals),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getTenantApplicationUrls: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_TENANT_APPLICATION_URLS}`,
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getUrlAudits: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_URL_AUDITS}`,
        method: "POST",
        body: JSON.stringify(requestBody),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const {
  useGetHospitalRegistrationInformationQuery,
  useLazyGetHospitalRegistrationInformationQuery,
  useLazySubmitHospitalDataQuery,
  useLazyRegisterHospitalQuery,
  useLazyUpdateHospitalQuery,
  useGetAllHospitalsQuery,
  useLazyGetAllHospitalsQuery,
  useLazyGetTenantApplicationInformationQuery,
  useLazyGetTenantApplicationUrlsQuery,
  useGetUrlAuditsQuery,
} = hospitalApi;
