import React, { useEffect, useMemo, useState } from "react";
import "./Dashboard.scss";
import { DashboardMetricTypeEnums } from "../../constants/data/DashboardMetricTypeEnums";
import { ReactComponent as RegisteredHospitals } from "../../../src/assets/svg/RegisteredHospitals.svg";
import { ReactComponent as LaunchedHospitals } from "../../../src/assets/svg/LaunchedHospitals.svg";
import { ReactComponent as ApprovedButNeverLaunched } from "../../../src/assets/svg/ApprovedButNeverLaunched.svg";
import { ReactComponent as Presentations } from "../../../src/assets/svg/Presentations.svg";
import { ReactComponent as UnavailablePatients } from "../../../src/assets/svg/UnAvailablePatients.svg";
import { ReactComponent as LaunchFailure } from "../../../src/assets/svg/LaunchFailure.svg";
import { ReactComponent as DeletedPatients } from "../../../src/assets/svg/DeletedPatients.svg";
import { ReactComponent as PatientsSaved } from "../../../src/assets/svg/PatientsSaved.svg";
import Info from "../shared/SVG/Info";
import Download from "../shared/SVG/Download";
import MultiSelectDropdown from "../shared/MultiSelectDropdown/MultiSelectDropdown";
import { Button } from "@mui/material";
import { Roles } from "../../constants/data/Roles";
import { metricsInfoTexts } from "../../constants/metricsInfoTexts";
import * as dateFns from "date-fns";
import DaterangePicker from "../shared/DaterangePicker";
import {
  useAppTypeQuery,
  useEhrTypeQuery,
} from "../../services/rtkQueryServices/masterDataApi";
import { useTenantNamesQuery } from "../../services/rtkQueryServices/monitoringService";
import {
  useLazyGetDashboardMetricsQuery,
  useLazyDownloadExcelForDashboardMetricQuery,
  useLazyDownloadConsolidatedMetricsExcelForDashboardMetricQuery,
  useGetDateFilterRangeQuery,
} from "../../services/rtkQueryServices/DashboardApi";
import { Utility } from "../../utils/Utility";
import Apply from "../shared/SVG/Apply";
import ClearAll from "../shared/SVG/ClearAll";

function Dashboard() {
  const [dashboardData, setDashboardData] = useState<any>([]);
  const [filterCriteria, setFilterCriteria] = useState<any>({
    fromDate: Utility.getCurrentUTCDate(),
    toDate: Utility.getCurrentUTCDate(),
  });
  const [isDownloadAllMetricEnabled, setIsDownloadAllMetricEnabled] =
    useState<boolean>(true);
  const [isApplyFiltersEnabled, setIsApplyFiltersEnabled] =
    useState<boolean>(false);
  const [hospitalList, serHospitalList] = useState<string[]>([]);
  const [dateRange, setDateRange] = useState<any>([]);
  const [defaultDateRange, setDefaultDateRange] = useState<any>([]);
  const [dateFilterRangeDaysCount, setDateFilterRangeDaysCount] =
    useState<Number>(0);
  const [ehrType, setEhrType] = useState<string[]>([]);
  const [appType, setAppType] = useState<string[]>([]);
  const [setMetricsData, metricsData] = useLazyGetDashboardMetricsQuery();
  const [title, setTitle] = useState<string>();
  const [downloadExcel] = useLazyDownloadExcelForDashboardMetricQuery();
  const [downloadConsolidatedMetricsExcel] =
    useLazyDownloadConsolidatedMetricsExcelForDashboardMetricQuery();
  const dateFilterRange = useGetDateFilterRangeQuery(null);
  const [enableClear, setEnableClear] = useState<any>();
  useEffect(() => {
    if (dateRange.length) {
      setMetricsData(filterCriteria);
    }
  }, [filterCriteria]);
  useEffect(() => {
    if (metricsData.isSuccess) {
      setDashboardData(
        metricsData.data &&
          metricsData.data.data
            ?.slice()
            .sort((a: any, b: any) =>
              a.dashboardMetricType > b.dashboardMetricType ? 1 : -1
            )
      );
    }
  }, [metricsData]);
  function RenderDashboardCardIcon(metricType: any) {
    return (
      <>
        {DashboardMetricTypeEnums.registeredHospitals ===
        metricType.metricType ? (
          <RegisteredHospitals />
        ) : DashboardMetricTypeEnums.launchedHospitals ===
          metricType.metricType ? (
          <LaunchedHospitals />
        ) : DashboardMetricTypeEnums.approvedButNeverLaunched ===
          metricType.metricType ? (
          <ApprovedButNeverLaunched />
        ) : DashboardMetricTypeEnums.patientsSaved === metricType.metricType ? (
          <PatientsSaved />
        ) : DashboardMetricTypeEnums.patientsPresented ===
          metricType.metricType ? (
          <Presentations />
        ) : DashboardMetricTypeEnums.launchFailures ===
          metricType.metricType ? (
          <LaunchFailure />
        ) : DashboardMetricTypeEnums.patientsNotAvailable ===
          metricType.metricType ? (
          <UnavailablePatients />
        ) : DashboardMetricTypeEnums.patientsDeleted ===
          metricType.metricType ? (
          <DeletedPatients />
        ) : (
          <ApprovedButNeverLaunched />
        )}
      </>
    );
  }
  const setHoverText = (
    dropDownMenuList: any,
    selectedValue: string,
    valuePropertyName: string,
    displayPropertyName: string
  ) => {
    let hoverText = "";
    for (let i = 0; i < selectedValue.length; i++) {
      const displayValue = dropDownMenuList.find(
        (obj: any) => obj[valuePropertyName] === selectedValue[i]
      );
      if (displayValue != undefined) {
        hoverText = hoverText + ", " + displayValue[displayPropertyName];
      }
    }
    setTitle(hoverText.slice(1));
  };
  function RenderDashboardCardInfoText(metricType: any, metricName: any) {
    return (
      <>
        {DashboardMetricTypeEnums.registeredHospitals === metricType.metricType
          ? metricsInfoTexts.RegisteredHospitals
          : DashboardMetricTypeEnums.launchedHospitals === metricType.metricType
          ? metricsInfoTexts.LaunchedHospitals
          : DashboardMetricTypeEnums.approvedButNeverLaunched ===
            metricType.metricType
          ? metricsInfoTexts.ApprovedButNeverLaunched
          : DashboardMetricTypeEnums.patientsSaved === metricType.metricType
          ? metricsInfoTexts.NumberOfPatientsCreated
          : DashboardMetricTypeEnums.patientsPresented === metricType.metricType
          ? metricsInfoTexts.NumberOfPatientsPresentations
          : DashboardMetricTypeEnums.launchFailures === metricType.metricType
          ? metricsInfoTexts.LaunchFailures
          : DashboardMetricTypeEnums.patientsNotAvailable ===
            metricType.metricType
          ? metricsInfoTexts.NumberOfPatientsNotAvailable
          : DashboardMetricTypeEnums.patientsDeleted === metricType.metricType
          ? metricsInfoTexts.PatientsDeleted
          : metricName.metricName}
      </>
    );
  }

  const EHRs = useEhrTypeQuery(null);
  const appTypes = useAppTypeQuery(null);
  const ehrTypesList = useMemo(() => {
    let menuItems = EHRs.data?.data.map((item: any) => {
      return {
        ehrTypeId: item.id,
        ehrTypeName: Utility.convertToPascalCase(item.name),
      };
    });
    return menuItems;
  }, [EHRs]);
  const appTypesList = useMemo(() => {
    let menuItems = appTypes.data?.data.map((item: any) => {
      return {
        appTypeId: item.id,
        appTypeName: Utility.convertToPascalCase(item.name),
      };
    });
    return menuItems;
  }, [appTypes]);
  const hospitalNamesList = useTenantNamesQuery(null);
  const hospitals = hospitalNamesList.data?.data;
  const hospitalData =
    hospitals &&
    Object.keys(hospitals).map((id) => {
      return {
        hospitalId: parseInt(id),
        hospitalName: hospitals[id],
      };
    });
  useEffect(() => {
    if (dateFilterRange?.isSuccess && !dateFilterRange.isFetching) {
      setFilterCriteria({
        fromDate: dateRange ? dateRange[0].toLocaleDateString("fr-CA") : null,
        toDate: dateRange ? dateRange[1].toLocaleDateString("fr-CA") : null,
      });
    }
  }, [dateFilterRange]);

  const defaultDate = () => {
    let fromDate = new Date(dateFilterRange?.data?.data?.toDate);
    let toDate = new Date(dateFilterRange?.data?.data?.toDate);
    dateFilterRange?.data?.data?.toDate &&
      setDefaultDateRange([
        new Date(fromDate.setDate(fromDate.getDate() - 1)),
        new Date(toDate.setDate(toDate.getDate() - 1)),
      ]);
  };

  const allowedDateRange = useMemo(() => {
    setDateFilterRangeDaysCount(
      dateFns.differenceInDays(
        new Date(dateFilterRange?.data?.data.toDate),
        new Date(dateFilterRange?.data?.data.fromDate)
      ) - 1
    );
    let fromDate = new Date(dateFilterRange?.data?.data?.toDate);
    let toDate = new Date(dateFilterRange?.data?.data?.toDate);
    dateFilterRange?.data?.data?.toDate &&
      setDateRange([
        new Date(fromDate.setDate(fromDate.getDate() - 1)),
        new Date(toDate.setDate(toDate.getDate() - 1)),
      ]);
    defaultDate();
    return dateFilterRange?.data?.data;
  }, [dateFilterRange]);

  return (
    <>
      <div className="h-100">
        <div className="dashboard-filter-wrapper d-flex flex-column justify-content-center">
          <div className="d-flex flex-grow-1 gap-3 filters-container flex-wrap">
            <div>
              <MultiSelectDropdown
                placeholder={"Select Hospital"}
                searchFilterDropdownPlaceholder={"Hospital"}
                searchFilterDropdownMenuList={hospitalData}
                displayPropertyName={"hospitalName"}
                valuePropertyName={"hospitalId"}
                showSearchFilterDropdown
                value={(hospitalList.length && hospitalList) || [""]}
                title={title}
                onChange={(val: any) => {
                  serHospitalList(val);
                  setHoverText(hospitalData, val, "hospitalId", "hospitalName");
                  setIsDownloadAllMetricEnabled(false);
                  setIsApplyFiltersEnabled(true);
                }}
              />
            </div>
            <div>
              <MultiSelectDropdown
                placeholder={"Select EHR"}
                searchFilterDropdownPlaceholder={"EHR Type"}
                searchFilterDropdownMenuList={ehrTypesList}
                displayPropertyName={"ehrTypeName"}
                valuePropertyName={"ehrTypeId"}
                showSearchFilterDropdown
                value={(ehrType.length && ehrType) || [""]}
                title={title}
                onChange={(val: any) => {
                  setEhrType(val);
                  setHoverText(ehrTypesList, val, "ehrTypeId", "ehrTypeName");
                  setIsDownloadAllMetricEnabled(false);
                  setIsApplyFiltersEnabled(true);
                }}
              />
            </div>
            <div>
              <MultiSelectDropdown
                placeholder={"Select App"}
                searchFilterDropdownPlaceholder={"App Type"}
                searchFilterDropdownMenuList={appTypesList}
                displayPropertyName={"appTypeName"}
                valuePropertyName={"appTypeId"}
                showSearchFilterDropdown
                value={(appType.length && appType) || [""]}
                title={title}
                onChange={(val: any) => {
                  setAppType(val);
                  setHoverText(appTypesList, val, "appTypeId", "appTypeName");
                  setIsDownloadAllMetricEnabled(false);
                  setIsApplyFiltersEnabled(true);
                }}
              />
            </div>
            <div>
              <div className="d-flex align-items-center gap-1 datepicker-section">
                <DaterangePicker
                  dateRange={dateRange}
                  allowedDateRange={allowedDateRange}
                  onChange={(val: any) => {
                    setDateRange(val);
                    setIsDownloadAllMetricEnabled(false);
                    setIsApplyFiltersEnabled(true);
                  }}
                />
                <span className="help-icon">
                  <Info width="24" height="24" fillColor="#777777" />
                  <div className="help-info">
                    {"Selected date range should not exceed last " +
                      dateFilterRangeDaysCount +
                      " days."}
                    <div className="note">
                      <strong>UTC Timezone is considered.</strong>
                    </div>
                  </div>
                </span>
              </div>
            </div>
            <Button
              className={`apply ${isApplyFiltersEnabled ? "" : "disabled"}`}
              disabled={!isApplyFiltersEnabled}
              onClick={() => {
                setFilterCriteria({
                  tenantInformationIds:
                    hospitalList[0] === "" ? [] : hospitalList,
                  fromDate: dateRange
                    ? dateRange[0].toLocaleDateString("fr-CA")
                    : null,
                  toDate: dateRange
                    ? dateRange[1].toLocaleDateString("fr-CA")
                    : null,
                  ehrTypeIds: ehrType[0] === "" ? [] : ehrType,
                  applicationTypeIds: appType[0] === "" ? [] : appType,
                });
                setIsDownloadAllMetricEnabled(true);
                setIsApplyFiltersEnabled(false);
                setEnableClear(true);
              }}
            >
              <Apply Strokecolor={!isApplyFiltersEnabled && "#AAAAAA"} />
              <span className="m-2">Apply</span>
            </Button>
            <Button
              className={enableClear ? "reset" : "disable-reset"}
              onClick={() => {
                if (enableClear) {
                  setFilterCriteria({
                    fromDate: defaultDateRange
                      ? defaultDateRange[0].toLocaleDateString("fr-CA")
                      : null,
                    toDate: defaultDateRange
                      ? defaultDateRange[1].toLocaleDateString("fr-CA")
                      : null,
                  });
                  setDateRange(defaultDateRange);
                  serHospitalList([]);
                  setEhrType([]);
                  setAppType([]);
                  setEnableClear(false);
                  setIsDownloadAllMetricEnabled(true);
                  setIsApplyFiltersEnabled(false);
                }
              }}
            >
              <ClearAll strokeColor={!enableClear && "#A6A6A6"} />{" "}
              <span>Clear all</span>
            </Button>
          </div>
        </div>

        <div className="dashboard-cards-content ">
          <div className="dashboard-filter-info d-flex justify-content-between flex-wrap">
            <div className="filter-text align-self-center">
              Metrics are displayed for the period
              <span className="filter-text-date">{` ${Utility.getFormattedDate(
                filterCriteria?.fromDate
              )} –  ${Utility.getFormattedDate(
                filterCriteria?.toDate
              )}.`}</span>
            </div>
            <div
              className={
                "flex-wrap download" +
                (isDownloadAllMetricEnabled ? "" : " disable-download")
              }
              onClick={() => {
                if (isDownloadAllMetricEnabled) {
                  downloadConsolidatedMetricsExcel({
                    ...filterCriteria,
                  }).then((res: any) => {
                    const blob = new Blob([res?.data?.data], {
                      type:
                        res?.data?.headers?.get("Content-Type") ||
                        "application/octet-stream",
                    });
                    const link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download =
                      Utility.getFileNameFromResponseHeaders(
                        res?.data?.headers
                      ) || "Metric.xlsx";
                    link.click();
                    URL.revokeObjectURL(link.href);
                  });
                }
              }}
            >
              <Button
                variant="contained"
                onClick={() => {}}
                startIcon={
                  <Download width="24" height="24" fillColor="white" />
                }
              >
                Consolidated Report
              </Button>
            </div>
          </div>
          {dashboardData && dashboardData.length > 0 && (
            <>
              <div className="d-flex dashboard-cards-wrapper">
                <div className="d-flex flex-wrap dashboard-cards-container w-80">
                  {dashboardData.map((item: any, index: number) => {
                    return (
                      <div className="card-wrapper d-flex flex-column">
                        <div className="d-flex gap-3 pb-3">
                          <div className="d-flex">
                            <div className="icon-wrapper d-flex ">
                              <RenderDashboardCardIcon
                                metricType={item?.dashboardMetricType}
                              />
                            </div>
                          </div>
                          <div
                            className="card-title ellipse-on-second-line align-self-center"
                            title={item?.dashboardMetricName}
                          >
                            {item?.dashboardMetricName}
                          </div>
                          <span className="help-icon align-self-center">
                            <Info width="24" height="24" fillColor="#777777" />
                            <div className="help-info">
                              <RenderDashboardCardInfoText
                                metricType={item?.dashboardMetricType}
                                metricName={item?.dashboardMetricName}
                              />
                            </div>
                          </span>
                          {/* </div> */}
                        </div>
                        <div className="d-flex justify-content-between flex-grow-1 align-items-end">
                          <div className="metric-count align-items-center flex-grow-1">
                            {item?.metricCount}
                          </div>
                          <div
                            className="d-flex mr-1 download-icon-wrapper align-items-end align-self-center mt-4"
                            onClick={() => {
                              downloadExcel({
                                ...filterCriteria,
                                dashboardMetricType: item?.dashboardMetricType,
                              }).then((res: any) => {
                                const blob = new Blob([res?.data?.data], {
                                  type:
                                    res?.data?.headers?.get("Content-Type") ||
                                    "application/octet-stream",
                                });
                                const link = document.createElement("a");
                                link.href = window.URL.createObjectURL(blob);
                                link.download =
                                  Utility.getFileNameFromResponseHeaders(
                                    res?.data?.headers
                                  ) || "Metric.xlsx";
                                link.click();
                                URL.revokeObjectURL(link.href);
                              });
                            }}
                          >
                            <div className="pb-2">
                              <Download
                                width="24"
                                height="24"
                                fillColor="#13ADA4"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default Dashboard;
