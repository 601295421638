import Button from "@mui/material/Button";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { regex } from "../../constants/regex";
import { useSelector } from "react-redux";
import { ReactComponent as TimelineLogo } from "../../../src/assets/svg/timelineLogo.svg";
import { Formik, Form, Field } from "formik";
import Input from "../shared/Input/Input";
import { ReactComponent as Email } from "../../assets/svg/mail.svg";

import * as Yup from "yup";
import {
  useLazyResendOtpQuery,
  useLazyValidateOtpQuery,
} from "../../services/rtkQueryServices/authenticationService";
import { UserService } from "../../services/UserService";
import { routePaths } from "../../constants/routePaths";
import { useAuth } from "../../context/AuthProvider";
import { validationMessages } from "../../constants/forms/validationMessages";
import { LOGIN_LITERALS } from "../../constants/literals";
import { Utility } from "../../utils/Utility";
import { dataStore } from "../../constants/dataStore";
export default function OtpValidation() {
  const navigate = useNavigate();
  const auth = useAuth();
  const otpReSendTime = 30;
  const [resendOtpRequest] = useLazyResendOtpQuery();
  const [validateOtp] = useLazyValidateOtpQuery();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [resendOtpTimer, setResendOtpTimer] = useState<any>(otpReSendTime);
  const loginDetails = useSelector((state: any) => state.email.value);
  const initialFormValue = useMemo(() => {
    if (!loginDetails) navigate(routePaths.login);
    return {
      otp: "",
      userEmail: loginDetails?.email,
      expiryTime: addHours(new Date(), 1),
    };
  }, [loginDetails, navigate]);

  useEffect(() => {
    if (!loginDetails) {
      navigate("/login");
    }
  }, []);
  function addHours(date: Date, hours: number) {
    date.setTime(date.getTime() + hours * 60 * 60 * 1000);
    return date;
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (resendOtpTimer > 0) {
        setResendOtpTimer(resendOtpTimer - 1);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [resendOtpTimer]);

  const resendOtp = () => {
    setResendOtpTimer(otpReSendTime);
    resendOtpRequest({ email: loginDetails?.email })
      .then((res) => {})
      .catch((e) => {
        setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
      });
  };
  const validationSchema = () =>
    Yup.object({
      otp: Yup.string()
        .required(validationMessages.otpRequired)
        .min(6, validationMessages.otpLength)
        .max(6, validationMessages.otpLength),
    });

  const handleChange = () => {
    setErrorMessage("");
  };

  return (
    <Formik
      initialValues={initialFormValue}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        validateOtp(values)
          .then((res) => {
            if (res?.data?.isSuccess) {
              UserService.accessToken = res.data.data.token;
              UserService.refreshToken = res.data.data.refreshToken;
              auth.logIn();
              localStorage.removeItem(dataStore.login_code);
              navigate(routePaths.userManagement);
              setErrorMessage("");
            } else {
              setErrorMessage((res.error as any).data.message);
            }
          })
          .catch((e) => {
            setErrorMessage(LOGIN_LITERALS.somethingWentWrong);
          });
      }}
    >
      {(formik) => (
        <Form
          onChange={handleChange}
          onSubmit={formik.handleSubmit}
          className="login-form-container"
        >
          <div className="login-form">
            <div className="login-header mb-4">{LOGIN_LITERALS.enterOtp}</div>
            <div className="login-mail mb-2">
              <Email className="me-2" />
              {loginDetails?.email}
            </div>
            <Input
              name="otp"
              placeholder={LOGIN_LITERALS.enterOtp}
              onKeyDown={Utility.allowOnlyNumerics}
              errorMsg={
                formik.touched.otp &&
                formik.errors.otp &&
                (formik.errors as any).otp
              }
              required
              value={formik.values.otp}
              onInput={formik.handleChange}
            />
          </div>
          <div className="d-flex flex-wrap justify-content-end mb-2">
            <Button
              variant="text"
              className="resend-otp"
              type="button"
              onClick={() => {
                formik.resetForm();
                resendOtp();
              }}
              disabled={resendOtpTimer > 0}
            >
              <div>
                {resendOtpTimer > 0 ? (
                  `00:${resendOtpTimer.toLocaleString("en-us", {
                    minimumIntegerDigits: 2,
                  })}`
                ) : (
                  <span>{LOGIN_LITERALS.resendOtp}</span>
                )}
              </div>
              <div>
                <TimelineLogo className="ms-2" />
              </div>
            </Button>
          </div>
          {errorMessage && (
            <span className="error-message">{errorMessage}</span>
          )}
          <div className="login-actions">
            <Button
              className={
                !formik.errors.otp ? "form-button" : "form-button disabled"
              }
              type="submit"
              disabled={!!formik.errors.otp}
            >
              {LOGIN_LITERALS.validate}
            </Button>
          </div>
          <div className="mt-3 p-4 otp-info-text">{LOGIN_LITERALS.optInfo}</div>
        </Form>
      )}
    </Formik>
  );
}
