import React, { useEffect, useMemo, useState } from "react";
import { routePaths } from "../../../constants/routePaths";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logs } from "../../../assets/svg/insights.svg";
import "./ApplicationMetrics.scss";
import MetricsCards from "../MetricsCards/MetricsCards";
import { useLazyApplicationMetricsQuery } from "../../../services/rtkQueryServices/monitoringService";
import PaginationContent from "./PaginationContent";
import { ReactComponent as NoData } from "../../../assets/svg/no_data.svg";
import HospitalFilter from "../HospitalFilter/HospitalFilter";

interface metricsData {
  tenantName: string;
  applicationMonitoringActivity: any[];
}
function ApplicationMetrics() {
  const navigate = useNavigate();
  const [getMetrics, fetchedMetricsData] = useLazyApplicationMetricsQuery();
  const metricsData = useMemo(
    () => fetchedMetricsData?.data?.data,
    [fetchedMetricsData]
  );
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 10,
    tenantList: "",
  });

  useEffect(() => {
    getMetrics(filterCriteria);
  }, [filterCriteria, getMetrics]);

  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };

  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  const addHospitalFilter = (filteredList: string) => {
    setFilterCriteria({
      ...filterCriteria,
      tenantList: filteredList,
    });
  };

  return (
    <div className="d-flex flex-column h-100 application-metrics-container">
      <div className="d-flex justify-content-between">
        <div>Application Metrics</div>
        <HospitalFilter
          appliedList={filterCriteria?.tenantList}
          setAppliedList={addHospitalFilter}
          placeHolder={"Select Hospital"}
          displaySelectedValues
          numberOfChipsToBeDisplayed={3}
        />
        <div
          onClick={() => {
            navigate(`${routePaths.monitoring}/${routePaths.applicationLogs}`);
          }}
          className="cursor-pointer"
        >
          <span className="navigate-link pe-1">Switch to Logs</span>
          <span>
            <Logs />
          </span>
        </div>
      </div>
      <div className="h-100 cards-container overflow-auto mt-3">
        <div className="metrics-cards-container d-flex flex-wrap justify-content-around h-100">
          {metricsData?.data && metricsData?.data?.length > 0 ? (
            metricsData?.data?.map((item: metricsData) => {
              return <MetricsCards item={item} />;
            })
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <NoData className="mb-5" />
              <span className="mt-3 empty-grid">No data found</span>
            </div>
          )}
        </div>
      </div>
      <PaginationContent
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        showTotalRowCount
        rowCount={metricsData?.totalCount}
      />
    </div>
  );
}

export default ApplicationMetrics;
