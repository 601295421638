import jwtDecode from "jwt-decode";
import { dataStore } from "../constants/dataStore";
import { authClaims } from "../models/authentication/authClaims";

export class UserService {
  static get isLoggedIn(): boolean {
    return localStorage.getItem(dataStore.access_token) ? true : false;
  }

  static get accessToken(): string | null {
    return localStorage.getItem(dataStore.access_token);
  }

  static set accessToken(token: string | null) {
    token && localStorage.setItem(dataStore.access_token, token);
  }

  static get refreshToken(): string | null {
    return localStorage.getItem(dataStore.refresh_token);
  }

  static set refreshToken(token: string | null) {
    token && localStorage.setItem(dataStore.refresh_token, token);
  }

  static get loginCode(): string | null {
    return localStorage.getItem(dataStore.login_code);
  }

  static set loginCode(token: string | null) {
    token && localStorage.setItem(dataStore.login_code, token);
  }

  static get getClaims(): authClaims | null {
    let token = localStorage.getItem(dataStore.access_token);
    return token ? jwtDecode<authClaims>(token) : null;
  }

  static logOut() {
    localStorage.removeItem(dataStore.access_token);
    localStorage.removeItem(dataStore.login_code);
    localStorage.removeItem(dataStore.refresh_token);
    window.location.replace("/login");
  }

  static logIn(tokenResponse: any) {
    this.accessToken = tokenResponse?.token;
    this.refreshToken = tokenResponse?.refreshToken;
  }
}
