import { Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import Input from "../shared/Input/Input";
import { useEffect, useMemo, useRef, useState } from "react";
import Dropdown from "../HospitalForm/DropDown/Dropdown";
import "./DeletePatient.scss";
import Datepicker from "../shared/Datepicker/Datepicker";
import {
  useLazyVerifyPatientDetailsQuery,
  useLazyDeletePatientQuery,
} from "../../services/rtkQueryServices/deletePatientService";
import { useEhrTypeQuery } from "../../services/rtkQueryServices/masterDataApi";
import { Utility } from "../../utils/Utility";
import { hospitalForms_LABELS_LITERALS } from "../../constants/labels/Hospital-Integration/HospitalUserForm";
import { validationMessages } from "../../constants/forms/validationMessages";

function DeletePatient(props: any) {
  const [formInitialValues, setForminitialValues] = useState<any>({
    firstName: "",
    lastName: "",
    gender: "",
    birthDate: null,
    ehrTypeId: "",
  });
  const EHRs = useEhrTypeQuery(null);
  const [patientMatchCount, setPatientMatchCount] = useState<number>(null);
  const [ehrId, setEhrId] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [isPatientDeleted, setIsPatientDeleted] = useState<boolean>(false);
  const [isEhrSelected, setIsEhrSelected] = useState<boolean>(false);
  const [setVerifyPatientDetails, verifyPatientDetails] =
    useLazyVerifyPatientDetailsQuery();
  const [setDeletePatient, deletePatient] = useLazyDeletePatientQuery();
  const menuItemLevels = useMemo(() => {
    return [
      { value: "male", displayValue: "Male" },
      { value: "female", displayValue: "Female" },
      { value: "other", displayValue: "Other" },
      { value: "unknown", displayValue: "Unknown" },
    ];
  }, null);
  const formRef = useRef<FormikProps<any>>(null);
  const menuItemEHRs = useMemo(() => {
    let menuItems = EHRs.data?.data.map((item: any) => {
      return {
        value: item.id,
        displayValue:
          Utility.convertToPascalCase(item.name) ===
          hospitalForms_LABELS_LITERALS.openemr
            ? hospitalForms_LABELS_LITERALS.openEmr
            : Utility.convertToPascalCase(item.name),
      };
    });
    return menuItems;
  }, [EHRs]);

  const validationSchema = () =>
    Yup.object({
      firstName: Yup.string().required(validationMessages.firstNameRequired),
      lastName: Yup.string().required(validationMessages.lastNameRequired),
      gender: Yup.string().required(validationMessages.genderRequired),
      birthDate: Yup.string().required(validationMessages.birthDateRequired),
    });
  useEffect(() => {
    if (verifyPatientDetails.isSuccess && !verifyPatientDetails.isFetching) {
      setPatientMatchCount(verifyPatientDetails.data.data.patientMatchCount);
      setEhrId(verifyPatientDetails.data.data.ehrIdentifier);
      if (
        verifyPatientDetails.data.data.patientMatchCount === 1 &&
        verifyPatientDetails.data.data.ehrTypeId
      ) {
        formRef.current.setFieldValue(
          "ehrTypeId",
          verifyPatientDetails.data.data.ehrTypeId
        );
      }
    } else if (verifyPatientDetails.isError) {
      setErrorMessage("Something went wrong");
    }
  }, [verifyPatientDetails]);
  useEffect(() => {
    if (deletePatient.isSuccess && !deletePatient.isFetching) {
      setIsPatientDeleted(true);
    } else if (deletePatient.isError) {
      setErrorMessage("Something went wrong");
    }
  }, [deletePatient]);

  return (
    <div className="delete-patient-wrapper">
      <div className="delete-patient-container">
        {isPatientDeleted ? (
          <div className="d-flex flex-column justify-content-end align-items-center h-100">
            <div className="patient-deleted">Patient Record Deleted</div>
            <div className="align-self-end">
              <button
                className="patient-deleted-button"
                type="button"
                onClick={() => {
                  setPatientMatchCount(null);
                  setIsPatientDeleted(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        ) : (
          <>
            {patientMatchCount === 1 ? (
              <>
                <div className="delete-patient-header">
                  {validationMessages.confirmDelete}
                </div>
                <div className="delete-patient-confirmation">
                  {validationMessages.deleteConfirmation}
                </div>
              </>
            ) : (
              <div className="delete-patient-header">
                {validationMessages.searchPatientRecord}
              </div>
            )}

            <Formik
              enableReinitialize
              initialValues={formInitialValues}
              validationSchema={validationSchema}
              innerRef={formRef}
              onSubmit={(values: any) => {
                setVerifyPatientDetails({
                  ...values,
                  birthDate: new Date(values.birthDate).toLocaleDateString(
                    "fr-CA"
                  ),
                  ehrTypeId: values.ehrTypeId ? values.ehrTypeId : null,
                });
                setErrorMessage("");
                setIsEhrSelected(values.ehrTypeId ? true : false);
              }}
            >
              {(formik) => (
                <Form
                  onSubmit={formik.handleSubmit}
                  onKeyDown={Utility.preventSubmitOnEnter}
                  className="delete-patient-form"
                >
                  <div>
                    <div className="d-flex justify-content-between delete-input-container">
                      <Input
                        name="firstName"
                        maxLength={50}
                        label="First Name"
                        placeholder="First Name"
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        errorMsg={
                          (formik.touched.firstName ||
                            formik.submitCount > 0) &&
                          formik.errors.firstName &&
                          (formik.errors as any).firstName
                        }
                        disabled={patientMatchCount >= 1}
                        required
                      />
                      <Input
                        name="lastName"
                        maxLength={50}
                        label="Last Name"
                        placeholder="Last Name"
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        errorMsg={
                          (formik.touched.lastName || formik.submitCount > 0) &&
                          formik.errors.lastName &&
                          (formik.errors as any).lastName
                        }
                        disabled={patientMatchCount >= 1}
                        required
                      />
                    </div>
                    <div className="d-flex justify-content-between delete-input-container">
                      <Dropdown
                        menuItems={menuItemLevels}
                        required
                        name={"gender"}
                        placeholder="Gender"
                        label="Gender"
                        formik={formik}
                        disabled={patientMatchCount >= 1}
                      />
                      <Datepicker
                        name="birthDate"
                        placeholder="Date of Birth"
                        oneTap={true}
                        formik={formik}
                        onChange={(val) => {
                          formik.setFieldValue("birthDate", val);
                        }}
                        disabled={patientMatchCount >= 1}
                        forceShowError={true}
                        required={true}
                        label="Date of Birth"
                      />
                    </div>
                    <div className="d-flex justify-content-between delete-input-container ehr-type-container">
                      <Dropdown
                        menuItems={menuItemEHRs}
                        label="EHR Type"
                        placeholder="EHR Type"
                        name="ehrTypeId"
                        formik={formik}
                        required={patientMatchCount > 1}
                        disabled={patientMatchCount === 1}
                      />
                    </div>
                    {patientMatchCount === 0 ? (
                      <div className="error-message">
                        {validationMessages.noMatchFound}
                      </div>
                    ) : patientMatchCount > 1 ? (
                      <div className="error-message">
                        {`${validationMessages.moreThanOneMatchFound} ${
                          isEhrSelected ? "" : validationMessages.selectEhrType
                        }`}
                      </div>
                    ) : (
                      errorMessage && (
                        <div className="error-message">{errorMessage}</div>
                      )
                    )}
                    <div className="d-flex justify-content-between delete-button-wrapper">
                      {patientMatchCount === 1 ? (
                        <div className="d-flex delete-patient-buttons-container">
                          <button
                            className="delete-patient-form-button cancel-reset-button"
                            type="button"
                            onClick={() => {
                              setPatientMatchCount(null);
                              setErrorMessage("");
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="delete-patient-form-button"
                            type="button"
                            onClick={() => {
                              setDeletePatient({ ehrId: ehrId.toString() });
                            }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex delete-patient-buttons-container">
                          <button
                            className="delete-patient-form-button cancel-reset-button"
                            type="button"
                            onClick={() => {
                              formik.resetForm();
                              setPatientMatchCount(null);
                            }}
                            disabled={!formik.dirty}
                          >
                            Reset
                          </button>
                          <button
                            className="delete-patient-form-button"
                            disabled={!(formik.dirty && formik.isValid)}
                          >
                            Search
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </div>
    </div>
  );
}
export default DeletePatient;
