export const userManagement_LABELS = {
  confirmation: "Confirmation",
  disableUserMessage: "Are you sure to Disable user?",
  activateUserMessage: "Are you sure to Activate user?",
  userManagement: "User Management",
  addUser: "Add User",
  firstName: "First Name",
  lastName: "Last Name",
  emailAddress: "Email Address",
  contactNumber: "Contact Number",
  userRole: "User Role",
  status: "Status",
  actions: "Actions",
  superAdmin: "Super User",
  audit: "Audit",
};
