import { Navigate, Route, Routes } from "react-router-dom";
import EmailLogin from "../components/Authentication/EmailLogin";
import OtpValidation from "../components/Authentication/OtpValidation";
import SetPassword from "../components/Authentication/Setpassword";
import Login from "../pages/Login/Login";
import UserManagement from "../components/User-Management/UserManagement";
import HospitalIntegration from "../components/Hospital-Integration/Hospital-IntegrationGird/HospitalIntegration";
import LandingLayout from "../pages/LandingLayout/LandingLayout";
import { routePaths } from "../constants/routePaths";
import HospitalUserForm from "../components/Hospital-Integration/HospitalUserForm/HospitalUserForm";
import { useAuth } from "../context/AuthProvider";
import PageNotFoound from "../pages/ErroPages/PageNotFound";
import RegistrationInformation from "../pages/RegistrationInformation/RegistrationInformation";
import ApplicationLogs from "../components/Monitoring/ApplicationLogs/ApplicationLogs";
import ApplicationMetrics from "../components/Monitoring/ApplicationMetrics/ApplicationMetrics";
import MonitoringLanding from "../components/Monitoring/MonitoringLanding/MonitoringLanding";
import Notifications from "../components/NotificationsScreen/Notifications/Notifications";
import DeletePatient from "../components/DeletePatient/DeletePatient";
import UpdatePassword from "../components/Authentication/UpdatePassword";
import Email from "../components/Setting/EmailTemplates/Email/Email";
import SettingLanding from "../components/Setting/SettingLanding/SettingLanding";
import EmailTemplateLanding from "../components/Setting/EmailTemplates/EmailTemplateLanding/EmailTemplateLanding";
import Dashboard from "../components/Dashboard/Dashboard";

const RoutesConfig = () => {
  const auth = useAuth();
  return (
    <Routes>
      {auth.isLoggedIn ? (
        <Route element={<LandingLayout />}>
          <Route
            path={routePaths.hospitalManagement}
            element={<HospitalIntegration />}
          />
          <Route
            path={routePaths.userManagement}
            element={<UserManagement />}
          />
          <Route path={routePaths.deletePatient} element={<DeletePatient />} />
          <Route path={routePaths.notifications} element={<Notifications />} />
          <Route path={routePaths.settings} element={<SettingLanding />}>
            <Route
              path={`${routePaths.emailTemplate}`}
              element={<EmailTemplateLanding />}
            />
            <Route path={`${routePaths.email}`} element={<Email />} />
          </Route>
          <Route
            path={routePaths.updatePassword}
            element={<UpdatePassword />}
          />
          <Route path={routePaths.monitoring} element={<MonitoringLanding />}>
            <Route
              path={routePaths.applicationLogsWithParams}
              element={<ApplicationLogs />}
            />
            <Route
              path={routePaths.applicationMetrics}
              element={<ApplicationMetrics />}
            />
          </Route>
          <Route path={routePaths.dashboard} element={<Dashboard />} />
          <Route path={routePaths.base} element={<UserManagement />} />
        </Route>
      ) : (
        <Route element={<Login />}>
          <Route path={routePaths.otp} element={<OtpValidation />} />
          <Route path={routePaths.login} element={<EmailLogin />} />
          <Route path={routePaths.base} element={<EmailLogin />} />
          <Route path={routePaths.wild} element={<Navigate to="/login" />} />
        </Route>
      )}
      <Route
        path={routePaths.hospitalUserForm}
        element={<HospitalUserForm />}
      />
      <Route element={<Login />}>
        <Route
          path={`${routePaths.setPassword}`}
          element={<SetPassword isReset={false} />}
        />
        <Route
          path={`${routePaths.resetPassword}`}
          element={<SetPassword isReset={true} />}
        />
      </Route>
      <Route
        path={`${routePaths.registrationInstruction}`}
        element={<RegistrationInformation />}
      />
      <Route path={routePaths.error} element={<PageNotFoound />} />
      <Route
        path={`${routePaths.base}${routePaths.wild}`}
        element={<PageNotFoound />}
      />
    </Routes>
  );
};

export default RoutesConfig;
