import { Key, useEffect, useMemo, useState } from "react";
import "./Notifications.scss";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import { ReactComponent as Notification } from "../../../assets/svg/level-notifications.svg";
import Modal from "../../shared/Modal/Modal";
import NotificationLevelsData from "../../../constants/data/NotificationLevelsData";
import Checkbox from "@mui/material/Checkbox";
import Input from "../../shared/Input/Input";
import { ReactComponent as Search } from "../../../assets/svg/search.svg";
import { DateRangePicker } from "rsuite";
import Dropdown from "../../HospitalForm/DropDown/Dropdown";
import PaginationContent from "../../shared/Pagination/Pagination";
import { useNotificationLevelsQuery } from "../../../services/rtkQueryServices/masterDataApi";
import {
  useLazyGetAllNotificationsQuery,
  useLazyGetAllPendingActionsQuery,
  useLazyGetSubscribedNotificationLevelByUserEmailQuery,
  useLazyToggleUserEmailNotificationStatusQuery,
} from "../../../services/rtkQueryServices/notificationApi";
import * as dateFns from "date-fns";
import ConfirmationDialog from "../../shared/ConfirmationDialog/ConfirmationDialog";
import { UserService } from "../../../services/UserService";
import { LEVELS } from "../../../enums/levels";
import { Utility } from "../../../utils/Utility";
import { NOTIFICATION_TABS } from "../../../enums/notificationTabs";
import DateRangeDefaultData from "../../../constants/data/DateRangeDefaultData";
import { ReactComponent as NoData } from "../../../assets/svg/no_data.svg";
import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import { routePaths } from "../../../constants/routePaths";
import { createSearchParams, useNavigate } from "react-router-dom";

function Notifications(props: any) {
  const [openNotificationLevel, setOpenNotificationLevel] = useState<boolean>();
  const [level1Selected, setLevel1Selected] = useState<boolean>(false);
  const [level2Selected, setLevel2Selected] = useState<boolean>(false);
  const [openConfirmationPopUp, setOpenConfirmationPopUp] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const userEmailRequest = {
    Email : UserService.getClaims?.email
  }
  const [searchString, setSearchString] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [level, setLevel] = useState<any>();
  const [notifications, setNotifications] = useState();
  const [listOfNotifications, setListOfNotifications] =
    useState<any[]>(notifications);
  const [rowCount, setRowCount] = useState(0);
  const [levelData, setLevelData] = useState(null);
  const [isReceiveEmailNotification, setIsReceiveEmailNotification] =
    useState<boolean>(false);
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
  });
  const [getSubscribedNotificationLevelByUserEmail, subscribedData] =
    useLazyGetSubscribedNotificationLevelByUserEmailQuery();
  const [getAllNotifications, notificationsData] =
    useLazyGetAllNotificationsQuery();
  const [getAllPendingActions, pendingActionList] =
    useLazyGetAllPendingActionsQuery(filterCriteria);
  const notificationLevels = useNotificationLevelsQuery(null);
  const [toggleUserEmailNotificationStatus, emailNotificationStatus] =
    useLazyToggleUserEmailNotificationStatusQuery();
  const [clearDropDowns, setClearDropDowns] = useState<boolean>(false);
  const [dateRangeValue, setDateRangeValue] = useState<any>(null);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [activeTab, setActiveTab] = useState(NOTIFICATION_TABS.GENERAL);
  const { allowedRange } = DateRangePicker;

  const navTabMenuItems = [
    {
      name: "General",
      displayName: "General",
    },
    {
      name: "Actions",
      displayName: "Actions",
    },
  ];

  useEffect(() => {
    getData(activeTab);
  }, [filterCriteria]);

  useEffect(() => {
    if (!openConfirmationPopUp || !openNotificationLevel) {
      setLevel1Selected(false);
      setLevel2Selected(false);
    }
  }, [openConfirmationPopUp, openNotificationLevel]);

  useEffect(() => {
    if (notificationsData.isSuccess) {
      setListOfNotifications(notificationsData?.data?.data?.notifications);
      setRowCount(
        notificationsData?.data?.data?.totalCount
          ? notificationsData?.data?.data?.totalCount
          : 1
      );
      getSubscribedNotificationLevelByUserEmail(userEmailRequest);
      setIsReceiveEmailNotification(levelData);
    } else {
      setListOfNotifications([]);
    }
  }, [notificationsData]);

  useEffect(() => {
    if (subscribedData.isSuccess) {
      setLevelData(subscribedData?.data?.data);
      setIsReceiveEmailNotification(subscribedData?.data?.data);
    }
  }, [subscribedData]);

  useEffect(() => {
    if (emailNotificationStatus.isSuccess) {
      setOpenConfirmationPopUp(false);
      setOpenNotificationLevel(false);
      setLevel1Selected(false);
      setLevel2Selected(false);
      getSubscribedNotificationLevelByUserEmail(userEmailRequest);
      getData(activeTab);
    }
  }, [emailNotificationStatus]);

  useEffect(() => {
    switch (levelData) {
      case LEVELS.LEVEL_1: {
        setLevel1Selected(true);
        setLevel2Selected(false);
        break;
      }
      case LEVELS.LEVEL_2: {
        setLevel1Selected(false);
        setLevel2Selected(true);
        break;
      }
      case LEVELS.LEVEL_ANY: {
        setLevel1Selected(true);
        setLevel2Selected(true);
        break;
      }
      default: {
        break;
      }
    }
  }, [levelData]);

  useEffect(() => {
    if (level1Selected && level2Selected) {
      setLevelData(LEVELS.LEVEL_ANY);
    } else if (level1Selected) {
      setLevelData(LEVELS.LEVEL_1);
    } else if (level2Selected) {
      setLevelData(LEVELS.LEVEL_2);
    } else {
      setLevelData(null);
    }
  }, [level1Selected, level2Selected]);

  useEffect(() => {
    if (activeTab === NOTIFICATION_TABS.ACTIONS) {
      getAllPendingActions(filterCriteria);
    }
  }, [activeTab]);

  useEffect(() => {
    if (pendingActionList.isSuccess) {
      setListOfNotifications(pendingActionList?.data?.data?.pendingActions);
      setRowCount(
        pendingActionList?.data?.data?.totalCount
          ? pendingActionList?.data?.data?.totalCount
          : 0
      );
    } else {
      setListOfNotifications([]);
      setRowCount(0);
    }
  }, [pendingActionList]);

  const menuItemLevels = useMemo(() => {
    return notificationLevels?.data?.data.map((item: any) => {
      if (item.id === 3) return { value: item.id, displayValue: "All" };
      return { value: item.id, displayValue: item.name };
    });
  }, [notificationLevels]);

  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };

  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };

  const handleToggle = () => {
    if (isReceiveEmailNotification) {
      setOpenConfirmationPopUp(true);
    } else {
      if (!openNotificationLevel) {
        setOpenNotificationLevel(!openNotificationLevel);
        getSubscribedNotificationLevelByUserEmail(userEmailRequest);
      }
    }
  };

  function handleEnter(e: any) {
    if (e.key === "Enter") {
      e.target.blur();
      searchResults(inputValue);
    }
  }

  const searchResults = (value: string) => {
    setSearchString(value);
    setFilterCriteria({
      ...filterCriteria,
      searchString: value.trim()?.length > 0 ? value.trim() : null,
    });
  };

  const levelValue = (value: any) => {
    setLevel(value);
    setFilterCriteria({
      ...filterCriteria,
      notificationLevelId: value ? value : null,
    });
    setClearDropDowns(false);
  };

  const getData = (tabIndex: number) => {
    if (tabIndex === NOTIFICATION_TABS.GENERAL) {
      getAllNotifications(filterCriteria);
    } else {
      getAllPendingActions(filterCriteria);
    }
  };

  const notificationsContent = () => {
    return (
      <>
        {listOfNotifications?.length > 0 ? (
          <div className="notifications-wrapper">
            {listOfNotifications?.map((item: any, index: Key) => (
              <div
                key={index}
                className={
                  "notification-card mb-4 p-3 " +
                  (item.isActive ? "notification-card-un-read d-flex" : "") +
                  (activeTab === NOTIFICATION_TABS.ACTIONS
                    ? "notification-action"
                    : "")
                }
                onClick={
                  activeTab === NOTIFICATION_TABS.ACTIONS &&
                  (() => {
                    navigate({
                      pathname: routePaths.hospitalManagement,
                      search: createSearchParams({
                        tenantId: Utility.getEncodedValue(
                          item.tenantApplicationInformationId
                        ),
                      })?.toString(),
                    });
                  })
                }
              >
                <div className="notification-indicator"></div>
                <div className="mt-2">
                  <div
                    className="notification-text mb-2"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></div>
                  <div className="d-flex justify-content-end notification-date-time">
                    <span>
                      <span className="pe-3">
                        {Utility.getDateInParticularFormat(item?.updatedDate)}
                      </span>
                      <span className="pe-2">
                        {Utility.getTime(item?.updatedDate)}
                      </span>
                    </span>
                    UTC
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="d-flex flex-column align-items-center justify-content-center">
            <NoData /> No notifications
          </div>
        )}
      </>
    );
  };

  const LevelInfoContent = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "var(--primary-color)",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "var(--primary-color)",
      color: "#fffff",
      maxWidth: 250,
      boxShadow: theme.shadows[1],
    },
  }));

  return (
    <div className="d-flex notifications-container flex-wrap flex-column">
      <div className="mb-2">Notifications</div>
      <div className="flex-grow-1 d-flex justify-content-center notification-right-content-wrapper flex-grow-1">
        <div className="notifications-right-wrapper w-100">
          <div className="d-flex justify-content-between flex-wrap filter-wrapper">
            <div className="search-wrapper me-3 flex-grow-1 ">
              <Input
                isFormElement={false}
                placeholder="Search Hospitals"
                suffix={
                  <Search
                    className="cursor-pointer"
                    onClick={() => {
                      searchResults(inputValue);
                    }}
                  />
                }
                value={inputValue}
                onInput={(e) => {
                  setInputValue((e.target as HTMLInputElement).value);
                }}
                onKeyDown={handleEnter}
              ></Input>
            </div>
            <div className="d-flex flex-grow-1 justify-content-end align-items-center">
              {activeTab === NOTIFICATION_TABS.GENERAL ? (
                <>
                  <div className="me-3">
                    <DateRangePicker
                      ranges={DateRangeDefaultData}
                      onChange={(value) => {
                        setDateRangeValue(value);
                        setFilterCriteria({
                          ...filterCriteria,
                          fromDate: value ? value[0] : null,
                          toDate: value ? value[1] : null,
                        });
                      }}
                      value={dateRangeValue}
                      placeholder="FROM DATE ~ TO DATE"
                      disabledDate={allowedRange(
                        dateFns.startOfDay(dateFns.subDays(new Date(), 89)),
                        new Date()
                      )}
                    />
                  </div>
                  <div className="me-3 levels-dropdown-wrapper">
                    <Dropdown
                      menuItems={menuItemLevels}
                      name={"levels"}
                      placeholder="Level"
                      onChange={levelValue}
                      clearValue={clearDropDowns}
                    />
                  </div>
                  <div className="clear-wrapper input-group">
                    <div
                      className="btn clear-button"
                      onClick={() => {
                        setFilterCriteria({
                          pageNumber: 1,
                          pageSize: 20,
                        });
                        setLevelData(null);
                        setClearDropDowns(true);
                        setInputValue(null);
                        setDateRangeValue(null);
                      }}
                    >
                      Clear Filters
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="d-flex flex-column justify-content-center notification-body-wrapper">
            <div className="notification-content px-5 py-3 h-100 w-100 mt-5">
              <div className="d-flex justify-content-between mb-3">
                <div className="notification-tabs-wrapper px-2">
                  {navTabMenuItems.map((item, index) => (
                    <span
                      key={index}
                      className={`${
                        (item?.name).toString().toUpperCase() ===
                          NOTIFICATION_TABS[activeTab] && "active-tab"
                      } tab p-3 rounded-2 d-inline-block cursor-pointer`}
                      onClick={() => {
                        setActiveTab(index);
                        getData(index);
                      }}
                    >
                      {item.displayName}
                    </span>
                  ))}
                </div>
                <div className="email-subscribe-toggle">
                  Receive email notifications
                  <Switch
                    checked={isReceiveEmailNotification}
                    onChange={() => handleToggle()}
                  />
                </div>
              </div>
              <div>{notificationsContent()}</div>
              <ConfirmationDialog
                open={openConfirmationPopUp}
                message={"STOP receiving email notifications?"}
                onConfirm={() => {
                  toggleUserEmailNotificationStatus({
                    email: UserService?.getClaims?.email,
                    subscribeEmailNotification: false,
                  });
                }}
                onCancel={() => {
                  setOpenConfirmationPopUp(false);
                  setLevel1Selected(false);
                  setLevel2Selected(false);
                }}
              />
              <Modal
                isOpen={openNotificationLevel}
                onClose={() => {
                  setOpenNotificationLevel(false);
                  setLevel1Selected(false);
                  setLevel2Selected(false);
                }}
                title={"Select notification level"}
                headerBg="#FFFFFF"
                headerColor="#13ADA4"
                modalHeaderBottomStyle={{
                  borderBottom: "1px solid var(--primary-color)",
                }}
                headerLogo={<Notification />}
                modalWidth="724px"
              >
                <div className="px-5 notification-level-wrapper mb-4">
                  <div className="level-info mb-2">
                    Choose the notification level you wish to receive.
                  </div>
                  <div>
                    <div
                      className={
                        "level-wrapper p-4 mb-4 " +
                        (level1Selected ? "level-selected" : "")
                      }
                    >
                      <div className="d-flex level1-header level-header mb-3 align-items-center">
                        <Checkbox
                          {...label}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          onChange={() => {
                            setLevel1Selected(!level1Selected);
                          }}
                          checked={level1Selected}
                          name="level"
                        />
                        <div className={level1Selected ? "level-checked" : ""}>
                          Level - 1 (Somewhat Important)
                          <LevelInfoContent
                            arrow
                            placement="top-start"
                            title="This keeps you updated on the modifications made against Eagle Users or Hospital/Providers."
                          >
                            <span className="px-3 info-icon-wrapper">
                              <Info />
                            </span>
                          </LevelInfoContent>
                        </div>
                      </div>
                      <ul className={level1Selected ? "notification-list" : ""}>
                        {NotificationLevelsData.level1.map(
                          (item: any, index: Key) => (
                            <li key={index}>{item.data}</li>
                          )
                        )}
                      </ul>
                    </div>
                    <div
                      className={
                        "level-wrapper p-4 mb-4 " +
                        (level2Selected ? "level-selected" : "")
                      }
                    >
                      <div className="d-flex level2-header level-header mb-3">
                        <Checkbox
                          {...label}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 18 } }}
                          onChange={() => {
                            setLevel2Selected(!level2Selected);
                            // updateLevelValue()
                          }}
                          checked={level2Selected}
                          name="level"
                        />
                        <div className={level2Selected ? "level-checked" : ""}>
                          Level - 2 (Highly Important)
                          <LevelInfoContent
                            arrow
                            placement="top-start"
                            title="This intimates you about the failures of SOF App launches and Resource Fetch Calls."
                          >
                            <span className="px-3 info-icon-wrapper">
                              <Info />
                            </span>
                          </LevelInfoContent>
                        </div>
                      </div>
                      <ul className={level2Selected ? "notification-list" : ""}>
                        {NotificationLevelsData.level2.map(
                          (item: any, index: Key) => (
                            <li key={index}>{item.data}</li>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                  <div className="actions d-flex justify-content-between">
                    <Button
                      type="button"
                      variant="outlined"
                      onClick={() => {
                        setOpenNotificationLevel(false);
                        setLevel1Selected(false);
                        setLevel2Selected(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      variant="contained"
                      disabled={!(level1Selected || level2Selected)}
                      onClick={() => {
                        toggleUserEmailNotificationStatus({
                          email: UserService?.getClaims?.email,
                          subscribeEmailNotification: true,
                          notificationLevelId: levelData,
                        });
                      }}
                    >
                      Apply
                    </Button>
                  </div>
                </div>
              </Modal>
            </div>
          </div>
        </div>
      </div>
      <PaginationContent
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        showTotalRowCount
        rowCount={rowCount}
      />
    </div>
  );
}

export default Notifications;
