import { API_CONSTANTS } from "../../constants/apiConstants";
import baseApi from "./baseApiService";

export const dashboardApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getDashboardMetrics: builder.query({
      query: (body: any) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_DASHBAORD_METRICS}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    downloadExcelForDashboardMetric: builder.query({
      query: (body) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.DOWNLOAD_METRIC_EXCEL_FILE}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
        responseHandler: async (response) => {
          const blobData = await response.blob();
          return blobData;
        },
        cache: "no-cache",
      }),
      transformResponse: (data, meta: any, args) => {
        return { data, headers: meta?.response?.headers };
      },
    }),
    downloadConsolidatedMetricsExcelForDashboardMetric: builder.query({
      query: (body) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.DOWNLOAD_CONSOLIDATED_METRICS_EXCEL_FILE}`,
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
        responseHandler: async (response) => {
          const blobData = await response.blob();
          return blobData;
        },
        cache: "no-cache",
      }),
      transformResponse: (data, meta: any, args) => {
        return { data, headers: meta?.response?.headers };
      },
    }),
    getDateFilterRange: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_DASHBOARD_DATE_FILTER_RANGE}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),
  }),
});
export const {
  useLazyGetDashboardMetricsQuery,
  useLazyDownloadExcelForDashboardMetricQuery,
  useLazyDownloadConsolidatedMetricsExcelForDashboardMetricQuery,
  useGetDateFilterRangeQuery,
} = dashboardApi;
