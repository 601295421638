export const LOGIN_LITERALS = {
  accessDenied: "Access Denied",
  passwordAlreadySet:
    "Password has been changed. Please contact admin, if you have any concerns",
  somethingWentWrong: "Something went wrong",
  support:
    "For support, please reach out to our support team at email@email.com",
  enterOtp: "Enter OTP",
  resendOtp: "Resend OTP",
  validate: "Validate",
  welcome: "Welcome to Eagle",
  getOtp: "Get OTP",
  optInfo:
    " OTP has been sent to your registered email address which is valid for 15 minutes.",
};

export const ErrorMessage = {
  ErrorAccountLocked:
    "Account locked due to invalid login attempts. Please try again after",
  ErrorInvalidUser: "Invalid username or password. Number of attempts left: ",
};
