import React from "react";
import { DateRangePicker } from "rsuite";
import * as dateFns from "date-fns";

function DaterangePicker(props: any) {
  const DateRangeDefaultData: any = [
    {
      label: "Last 7 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 6)),
        dateFns.endOfDay(new Date()),
      ],
    },
    {
      label: "Last 30 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 29)),
        dateFns.endOfDay(new Date()),
      ],
    },
    {
      label: "Last 90 Days",
      value: [
        dateFns.startOfDay(dateFns.subDays(new Date(), 89)),
        dateFns.endOfDay(new Date()),
      ],
    },
  ];
  const { allowedRange } = DateRangePicker;
  const { onChange, dateRange, allowedDateRange } = props;

  return (
    <div className="daterangepicker-wrapper">
      <DateRangePicker
        onChange={(value) => {
          onChange && onChange(value);
        }}
        cleanable={false}
        ranges={DateRangeDefaultData}
        value={dateRange}
        placeholder="FROM DATE – TO DATE"
        character="–"
        showOneCalendar
        format="MM-dd-yyyy"
        shouldDisableDate={
          allowedRange &&
          allowedRange(
            (allowedDateRange &&
              allowedDateRange.fromDate &&
              new Date(allowedDateRange.fromDate)) ||
              dateFns.startOfDay(dateFns.subDays(new Date(), 90)),
            (allowedDateRange &&
              allowedDateRange.toDate &&
              new Date(allowedDateRange.toDate)) ||
              dateFns.startOfDay(dateFns.subDays(new Date(), 1))
          )
        }
        limitEndYear={new Date().getUTCFullYear()}
      />
    </div>
  );
}

export default DaterangePicker;
