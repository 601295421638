import { Button, Switch } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import "./EmailTemplateEditor.scss";
import RichTextEditor from "../../../shared/RichTextEditor/RichTextEditor";
import {
  useLazyCreateOrUpdateEmailTemplateQuery,
  useLazyGetEmailTemplateVersionByTemplateIdQuery,
  useLazyUpdateEmailTemplateStatusQuery,
} from "../../../../services/rtkQueryServices/emailTemplateApi";
import { Utility } from "../../../../utils/Utility";
import ConfirmationDialog from "../../../shared/ConfirmationDialog/ConfirmationDialog";
import { snackbarWrapper } from "../../../shared/SnackbarWrapper/SnackbarWrapper";
class EmailTemplate {
  templateName?: string = "";
  subject?: string = "";
  body?: string = "";
  isActive?: boolean = false;
}

function EmailTemplateEditor(props: any) {
  const {
    selectedEmailTemplateId,
    emailTypeId,
    getEmailTemplateVersionsByEmailTemplateId,
    templatePlaceHolders,
    setSelectEmailTemplateId,
    clickedCard,
  } = props;
  const [emailContent, setEmailContent] = useState<EmailTemplate>(null);
  const [getEmailTemplateVersionByTemplateId, templateVersion] =
    useLazyGetEmailTemplateVersionByTemplateIdQuery();

  const [createOrUpdateEmailTemplate, createOrUpdateEmailTemplateResponse] =
    useLazyCreateOrUpdateEmailTemplateQuery();

  useEffect(() => {
    confirmationOnEmailTemplateVersionChange();
  }, [clickedCard]);

  const confirmationOnEmailTemplateVersionChange = () => {
    if (isEmailTemplateModified && clickedCard) {
      setOpenConfirmation({
        isOpen: true,
        message: "The changes you made will not be saved.",
        onConfirm() {
          setSelectEmailTemplateId(clickedCard?.emailTemplateId);
        },
        onCancel() {},
      });
    } else {
      setSelectEmailTemplateId(clickedCard?.emailTemplateId);
    }
  };

  const isEmailTemplateModified = useMemo(
    () =>
      selectedEmailTemplateId
        ? JSON.stringify(emailContent) !==
          JSON.stringify(templateVersion?.data?.data)
        : JSON.stringify(emailContent) !== JSON.stringify(new EmailTemplate()),
    [emailContent, selectedEmailTemplateId, templateVersion?.data?.data]
  );
  useEffect(() => {
    window.onbeforeunload = function (event) {
      return isEmailTemplateModified ? window.confirm("Confirm refresh") : null;
    };
    return () => (window.onbeforeunload = null);
  }, [isEmailTemplateModified]);

  useEffect(() => {
    selectedEmailTemplateId
      ? getEmailTemplateVersionByTemplateId(selectedEmailTemplateId)
      : setEmailContent(new EmailTemplate());
  }, [getEmailTemplateVersionByTemplateId, selectedEmailTemplateId]);

  useEffect(() => {
    setEmailContent(templateVersion?.data?.data);
  }, [templateVersion]);

  const handleChange = (key: string, value: string | boolean) => {
    setEmailContent({ ...emailContent, [key]: value });
  };

  const isEmailBodyValid = useMemo(() => {
    const sizeInBytes = new Blob([emailContent?.body]).size;
    return (
      Utility.getKbFromBytes(sizeInBytes) <=
      Number(process.env.REACT_APP_EMAIL_SUBJECT_SIZE_LIMIT)
    );
  }, [emailContent]);

  const handleSave = () => {
    if (!isEmailBodyValid) {
      setOpenConfirmation({
        isOpen: true,
        message: "Email body content should be less than 10 MB.",
        onOk() {},
      });
    } else {
      setOpenConfirmation({
        isOpen: true,
        message: "Are you sure to save the changes?",
        onConfirm() {
          const { templateName, body, subject, isActive } = emailContent;
          if (selectedEmailTemplateId) {
            createOrUpdateEmailTemplate({
              templateName: templateName,
              htmlBody: body,
              isActive: isActive,
              subject: subject,
              emailTemplateTypeId: emailTypeId,
              emailTemplateId: selectedEmailTemplateId,
            }).then((res) => {
              res?.isSuccess &&
                getEmailTemplateVersionByTemplateId(selectedEmailTemplateId);
            });
          } else {
            setOpenConfirmation({
              isOpen: true,
              message: "Do you want to make the template active?",
              cancelButtonName: "No",
              confirmButtonName: "Yes",
              onConfirm() {
                createOrUpdateEmailTemplate({
                  templateName: templateName,
                  htmlBody: body,
                  isActive: true,
                  subject: subject,
                  emailTemplateTypeId: emailTypeId,
                  emailTemplateId: selectedEmailTemplateId,
                }).then((res) => {
                  res?.isSuccess &&
                    getEmailTemplateVersionsByEmailTemplateId(emailTypeId);
                });
              },
              onCancel() {
                createOrUpdateEmailTemplate({
                  templateName: templateName,
                  htmlBody: body,
                  isActive: isActive,
                  subject: subject,
                  emailTemplateTypeId: emailTypeId,
                  emailTemplateId: selectedEmailTemplateId,
                }).then((res) => {
                  res?.isSuccess &&
                    getEmailTemplateVersionsByEmailTemplateId(emailTypeId);
                });
              },
            });
          }
        },
        onCancel() {},
      });
    }
  };

  useEffect(() => {
    if (createOrUpdateEmailTemplateResponse?.isError) {
      snackbarWrapper(
        (createOrUpdateEmailTemplateResponse.error as any).data?.message,
        "error"
      );
    }
  }, [createOrUpdateEmailTemplateResponse]);

  const [updateEmailTemplateStatus] = useLazyUpdateEmailTemplateStatusQuery();
  const onToggleChange = (isActive: boolean) => {
    if (isActive) {
      if (isEmailTemplateModified) {
        setOpenConfirmation({
          isOpen: true,
          message:
            "Please save your changes before making the template active.",
          onOk() {},
        });
      } else {
        setOpenConfirmation({
          isOpen: true,
          message: "Are you sure to change the status to active?",
          onConfirm() {
            updateEmailTemplateStatus({
              emailTemplateId: selectedEmailTemplateId,
              isActive: isActive,
            }).then((res) => {
              res?.isSuccess &&
                getEmailTemplateVersionsByEmailTemplateId(emailTypeId)?.then(
                  (res: any) => {
                    res?.isSuccess &&
                      getEmailTemplateVersionByTemplateId(
                        selectedEmailTemplateId
                      );
                  }
                );
            });
          },
          onCancel() {},
        });
      }
    } else {
      setOpenConfirmation({
        isOpen: true,
        message: (
          <div className="d-flex flex-column">
            <div>One email template has to be active.</div>
            <div>
              Note: Make any other email template active to make the current
              template inactive
            </div>
          </div>
        ),
        onOk() {},
      });
    }
  };
  const handleCancel = () => {
    if (isEmailTemplateModified) {
      setOpenConfirmation({
        isOpen: true,
        message: "Are you sure to cancel the changes?",
        onConfirm: () => {
          setEmailContent(templateVersion?.data?.data);
        },
        onCancel() {},
      });
    }
  };
  const [openConfirmation, setOpenConfirmation] = useState<{
    isOpen: boolean;
    message?: any;
    onConfirm?: () => void;
    onCancel?: () => void;
    onOk?: () => void;
    cancelButtonName?: string;
    confirmButtonName?: string;
  }>({ isOpen: false });
  return (
    <div className="flex-grow-1 h-100 ms-5 rich-text-editor-container position-relative">
      <div className="px-5 py-3">
        <div className="input-container template-info align-items-center d-flex py-2">
          <input
            placeholder="Template name"
            type="text"
            className="flex-grow-1 template-name"
            value={emailContent?.templateName}
            onChange={(e) => {
              handleChange("templateName", e.target.value);
            }}
          />
          {selectedEmailTemplateId && emailContent && (
            <span className="d-flex align-items-center">
              <span>{emailContent?.isActive ? "Active" : "Inactive"}</span>
              <Switch
                className="email-status-toggle"
                onChange={(e) => {
                  onToggleChange(e.target.checked);
                }}
                checked={emailContent?.isActive}
              />
            </span>
          )}
        </div>
        <div className="input-container py-2">
          <input
            placeholder="Subject"
            className="w-100 subject"
            type="text"
            value={emailContent?.subject}
            onChange={(e) => {
              handleChange("subject", e.target.value);
            }}
          />
        </div>
      </div>
      <div className="m-3">
        {templatePlaceHolders && (
          <RichTextEditor
            placeHolderDropdown={templatePlaceHolders}
            value={emailContent?.body}
            onChange={(content: string) => {
              handleChange("body", content);
            }}
          />
        )}
      </div>
      <div className="d-flex justify-content-end p-3 rich-text-editor-footer">
        <Button
          type="button"
          variant="outlined"
          onClick={handleCancel}
          className="px-5 me-5"
          disabled={!isEmailTemplateModified}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !isEmailTemplateModified ||
            !emailContent?.templateName ||
            !emailContent?.body ||
            !emailContent?.subject
          }
          onClick={handleSave}
          variant="contained"
        >
          Save
        </Button>
        <ConfirmationDialog
          open={openConfirmation?.isOpen}
          message={openConfirmation?.message}
          cancelButtonName={openConfirmation?.cancelButtonName || "Cancel"}
          confirmButtonName={openConfirmation?.confirmButtonName || "Confirm"}
          onOk={
            openConfirmation?.onOk
              ? () => {
                  openConfirmation?.onOk();
                  setOpenConfirmation({ isOpen: false });
                }
              : null
          }
          onCancel={
            openConfirmation?.onCancel
              ? () => {
                  setOpenConfirmation({ isOpen: false });
                  openConfirmation?.onCancel();
                }
              : null
          }
          onConfirm={
            openConfirmation?.onConfirm
              ? () => {
                  setOpenConfirmation({ isOpen: false });
                  openConfirmation?.onConfirm();
                }
              : null
          }
        />
      </div>
    </div>
  );
}

export default EmailTemplateEditor;
