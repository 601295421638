import React, { useMemo, useState } from "react";
import "./MetricsCards.scss";
import { ReactComponent as Expand } from "../../../assets/svg/expand_content.svg";
import { ReactComponent as LeftArrow } from "../../../assets/svg/coolicon_left.svg";
import { ReactComponent as RightArrow } from "../../../assets/svg/coolicon_right.svg";
import { ReactComponent as CloseModal } from "../../../assets/svg/close_fullscreen.svg";
import { IconButton } from "@mui/material";
import { MONITORING_ACTIVITY } from "../../../enums/monitoringActivity";
import Modal from "../../shared/Modal/Modal";
import MetricsSubCards from "../MetricsSubCards.tsx/MetricsSubCards";
import MetricsModal from "../MetricsModal/MetricsModal";
import { Utility } from "../../../utils/Utility";

function MetricsCards(props: any) {
  const [activeTab, setActiveTab] = useState<any>(MONITORING_ACTIVITY.PROVIDER);
  const activeTabContent: any = useMemo(
    () =>
      props?.item?.applicationMonitoringActivity?.find(
        (item: any) => item?.activityType === activeTab
      ),
    [activeTab, props]
  );
  const keys = Object.keys(MONITORING_ACTIVITY)
    .filter((key) => !isNaN(Number(key)))
    .map((key) => Number(key))
    .reverse();
  const [open, setOpen] = useState<boolean>();

  return (
    <div className="metrics-card m-3">
      <div className="card-header d-flex justify-content-between align-items-center py-2 mx-4">
        <span className="metrics-hospital-name">{props?.item?.tenantName}</span>
        <IconButton
          onClick={() => {
            setOpen(true);
          }}
        >
          <Expand />
        </IconButton>
      </div>
      <div className="align-items-center card-nav-bar d-flex justify-content-between mx-4 py-3">
        <IconButton
          className="metrics-button"
          onClick={() => {
            activeTab === 1
              ? setActiveTab(keys.length)
              : setActiveTab(activeTab - 1);
          }}
        >
          <LeftArrow />
        </IconButton>
        <span>
          <span>
            {Utility.convertToPascalCase(MONITORING_ACTIVITY[activeTab])}{" "}
            Activity
          </span>
          <span className="d-flex justify-content-center">
            {keys?.map((item: number) => (
              <span
                key={item}
                onClick={() => {
                  setActiveTab(item);
                }}
                className={`metrics-nav-bar-circle mx-1 ${
                  activeTab === item ? "active" : ""
                }`}
              ></span>
            ))}
          </span>
        </span>
        <IconButton
          className="metrics-button"
          onClick={() => {
            activeTab === keys.length
              ? setActiveTab(1)
              : setActiveTab(activeTab + 1);
          }}
        >
          <RightArrow />
        </IconButton>
      </div>
      <div className="card-content me-5">
        <MetricsSubCards
          activeTabContent={activeTabContent}
          activeTab={activeTab}
        />
      </div>
      <Modal
        isOpen={open}
        onClose={() => {
          setOpen(false);
        }}
        closeIcon={<CloseModal />}
        modalHeaderStyle={{
          backgroundColor: "var(--content-bg)",
        }}
        modalHeaderBottomStyle={{
          borderBottom: "1px solid var(--primary-color)",
        }}
        modalContentStyle={{ backgroundColor: "var(--content-bg)" }}
        title={props?.item?.tenantName}
        modalWidth="800px"
        headerBg="#FFFFFF"
        headerColor="#13ada4"
      >
        <MetricsModal
          tenantName={props?.item?.tenantName}
          displayData={props?.item}
        />
      </Modal>
    </div>
  );
}

export default MetricsCards;
