import React, { useState } from "react";
import Grid from "../../shared/Grid/Grid";
import { ReactComponent as View } from "../../../assets/svg/View.svg";
import { ReactComponent as Audit } from "../../../assets/svg/audit.svg";
import { ReactComponent as Hide } from "../../../assets/svg/hide.svg";
import { hospitalUrlsViewAuditTracker_LABELS } from "../../../constants/labels/Hospital-Integration/HospitalUrlsViewAuditTracker";
import "./HospitalUrlsAuditGrid.scss";
import { HOSPITAL_URL } from "../../../enums/hospitalUrl";
import {
  useGetUrlAuditsQuery,
  useLazyGetTenantApplicationUrlsQuery,
} from "../../../services/rtkQueryServices/hospitalService";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";

function HospitalUrlsAuditGrid(props: any) {
  const { tenantApplicationInformationId } = props;
  const [getTenantApplicationUrls] = useLazyGetTenantApplicationUrlsQuery();
  const [redirectUrl, setRedirectURL] = useState<string>();
  const [launchUrl, setLaunchURL] = useState<string>();
  const [viewRedirectUrl, setViewRedirectURL] = useState<boolean>(false);
  const [viewLaunchUrl, setViewLaunchURL] = useState<boolean>(false);
  const maskedString = "***************";
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    tenantApplicationInformationId: tenantApplicationInformationId,
  });
  const { data: urlAudits, refetch: refetchUrlsAudits } =
    useGetUrlAuditsQuery(filterCriteria);

  const columnDefs = [
    {
      headerName: hospitalUrlsViewAuditTracker_LABELS.userName,
      field: "activityBy",
    },
    {
      headerName: hospitalUrlsViewAuditTracker_LABELS.viewedOn,
      field: "activityDate",
      sortable: true,
      cellRenderer: UtcDateTime,
    },
    {
      headerName: hospitalUrlsViewAuditTracker_LABELS.UrlType,
      field: "urlType",
    },
  ];

  const toggleView = (urlType: number) => {
    if (
      (urlType === HOSPITAL_URL.LAUNCH && !launchUrl) ||
      (urlType === HOSPITAL_URL.REDIRECT && !redirectUrl)
    ) {
      getTenantApplicationUrls({
        tenantApplicationInformationId: tenantApplicationInformationId,
        urlType: urlType,
      }).then((res) => {
        let data = res?.data?.data;
        urlType === HOSPITAL_URL.LAUNCH
          ? setLaunchURL(data)
          : setRedirectURL(data);
        refetchUrlsAudits();
      });
    }
    urlType === HOSPITAL_URL.LAUNCH
      ? setViewLaunchURL(!viewLaunchUrl)
      : setViewRedirectURL(!viewRedirectUrl);
  };
  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  const sort = (columnName: string, order: string) => {
    if (order === "unsorted") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    }
  };
  return (
    <div className="hospital-urls-view-modal">
      <div className="d-flex">
        <div className="d-flex flex-column align-items-center ps-5 justify-content-between">
          <div className="url-label">Redirect URL - </div>
          <div className="url-label">Launch URL - </div>
        </div>
        <div className="d-flex flex-column">
          <div className="hospital-url-fields d-flex align-items-center px-3 ms-3">
            <span className="hospital-url">
              {viewRedirectUrl ? redirectUrl : maskedString}{" "}
            </span>
            <span
              className="ps-4 view-url-toggle"
              onClick={() => {
                toggleView(HOSPITAL_URL.REDIRECT);
              }}
            >
              {viewRedirectUrl ? <Hide /> : <View />}
            </span>
          </div>
          <div className="hospital-url-fields d-flex align-items-center px-3 ms-3">
            <span className="hospital-url">
              {viewLaunchUrl ? launchUrl : maskedString}
            </span>
            <span
              className="ps-4 view-url-toggle"
              onClick={() => {
                toggleView(HOSPITAL_URL.LAUNCH);
              }}
            >
              {viewLaunchUrl ? <Hide /> : <View />}
            </span>
          </div>
        </div>
      </div>
      <div className="m-3 px-5 py-4 hospital-url-view-audit-tracker">
        <div className="pb-3">
          <span className="audit-icon">
            <Audit />
          </span>
          <span className="hospital-url-view-audit-tracker-heading ps-3">
            Audit Tracker
          </span>
        </div>
        <Grid
          rowData={urlAudits?.data?.data}
          showPagination
          gridType="audit"
          columnDefs={columnDefs}
          pageSize={20}
          pageSizeList={[10, 20, 50, 100]}
          rowCount={urlAudits?.data?.totalCount}
          serverSideSorting
          serverSidePagination
          sortBy={sort}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
        />
      </div>
    </div>
  );
}

export default HospitalUrlsAuditGrid;
