import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
import { UserService } from "../UserService";
export const masterDataApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    ehrType: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_EHR_TYPES}`,
        method: "GET",
      }),
    }),
    appType: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_APPLICATION_TYPES}`,
        method: "GET",
      }),
    }),
    tenantStatus: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_TENANT_STATUS}`,
        method: "GET",
      }),
    }),
    notificationLevels: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_NOTIFICATION_LEVELS}`,
        method: "GET",
      }),
    }),
    emailTemplateTypes: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_EMAIL_TEMPLATE_TYPES}`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useEhrTypeQuery,
  useAppTypeQuery,
  useTenantStatusQuery,
  useNotificationLevelsQuery,
  useEmailTemplateTypesQuery,
} = masterDataApi;
