import { KEYCODE } from "../enums/keyCodes";
import { v4 as uuidv4 } from "uuid";
import { Buffer } from "buffer";
export class Utility {
  /**
   * Validates if the input value is only numbers and required characters(ex:Tab)
   * @returns boolean based on whether the input value has to be added to the input
   */
  static allowOnlyNumerics = (e: any) => {
    if (
      !(
        (e.key.charCodeAt(0) >= KEYCODE.ZERO &&
          e.key.charCodeAt(0) <= KEYCODE.NINE) ||
        e.keyCode === KEYCODE.BACKSPACE ||
        e.keyCode === KEYCODE.TAB ||
        e.keyCode === KEYCODE.LEFTARROW ||
        e.keyCode === KEYCODE.RIGHTARROW ||
        e.keyCode === KEYCODE.DELETE ||
        e.keyCode === KEYCODE.ENTER
      )
    ) {
      e.preventDefault();
      return false;
    }
    return true;
  };

  /**
   * Convert any date time to given timeZone if provided else system timeZone.
   * @returns Converted any date time to given timeZone if provided else system timeZone.
   */
  static getSpecifiedDateTimeByAnyDateTime(date: any, timeZone?: string): any {
    if (!date) {
      return null;
    }
    if (!timeZone) {
      timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; //If timeZone not provided it will convert into default system timeZone.
    }
    const givenDateTime = new Date(date);

    const currentDate = new Date(
      givenDateTime.toLocaleString("en-US", { timeZone })
    );
    return currentDate;
  }

  /**
   * Converts seconds into Minutes 'Min' Seconds 'Sec' format
   * @returns converted minutes and seconds in Minutes 'Min' Seconds 'Sec' format
   */
  static convertSecondsToMinutesAndSeconds(seconds: number) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds - minutes * 60;
    return `${minutes} Min ${remainingSeconds} Sec`;
  }

  static generateGuidWithLength = (maxLength: number): string => {
    const guid = uuidv4().replace(/-/g, ""); // Generate a standard GUID and remove the hyphens
    const length = Math.min(guid.length, maxLength); // Determine the length based on the specified maximum length and the actual length of the generated GUID
    return guid.substr(0, length); // Return the substring of the GUID with the determined length
  };

  static convertToPascalCase = (str: string) => {
    if (str) {
      if (str.length === 1) {
        return str.toUpperCase();
      } else if (str.length > 1) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      }
    }
    return "";
  };

  /**
   * Convert any date time to Date/Month/Year.
   * @returns Converted any date time to Date/Month/Year.
   */
  static getDateInParticularFormat(dateValue: string) {
    const dateObj = new Date(dateValue);
    // Extract the date, time, and month using various Date methods
    const date = dateObj.getDate().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const month = (dateObj.getMonth() + 1).toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const year = dateObj.getFullYear();
    // Construct the final date, time, and month strings in the desired format
    let formattedDate = `${year}-${month}-${date}`;
    return formattedDate;
  }

  static getTime(dateValue: string, getIn24HoursFormat: boolean = false) {
    const dateObj = new Date(dateValue);
    const hoursIn24Format = dateObj.getHours().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const hoursIn12Format = (Number(hoursIn24Format) % 12 || 12).toLocaleString(
      "en-us",
      {
        minimumIntegerDigits: 2,
      }
    );
    const minutes = dateObj.getMinutes().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const seconds = dateObj.getSeconds().toLocaleString("en-us", {
      minimumIntegerDigits: 2,
    });
    const timeIn12HoursFormat = `${hoursIn12Format}:${minutes}:${seconds} ${
      Number(hoursIn24Format) >= 12 ? "PM" : "AM"
    }`;
    return getIn24HoursFormat
      ? `${hoursIn24Format}:${minutes}`
      : timeIn12HoursFormat;
  }

  /**
   * Convert seconds.
   * @returns Converted seconds to minutes.
   */
  static getMinutesFromSeconds(secs: number): number {
    return Math.ceil(((secs % 86400) % 3600) / 60);
  }

  /**
   * Convert seconds.
   * @returns Converted seconds to hours,minutes,seconds.
   */
  static getTimeRemaining(totalSecs: number) {
    var hours = Math.floor(totalSecs / (60 * 60));

    var divisor_for_minutes = totalSecs % (60 * 60);
    var minutes = Math.floor(divisor_for_minutes / 60);

    var divisor_for_seconds = divisor_for_minutes % 60;
    var seconds = Math.ceil(divisor_for_seconds);
    return {
      totalSecs,
      hours,
      minutes,
      seconds,
    };
  }
  /**
   * Converts given Bytes to MB.
   * @returns Converted given Bytes to MB.
   */
  static getKbFromBytes(totalBytes: number) {
    // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
    const fileSizeInKB = totalBytes / 1024;
    // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
    // const fileSizeInMB = fileSizeInKB / 1024; // MB
    return fileSizeInKB;
  }
  static getDecodedValueFromBase64String(base64String: string) {
    return Buffer.from(base64String, "base64")?.toString("utf-8");
  }
  static getEncodedValue(decodedString: string) {
    return Buffer.from(decodedString, "utf8").toString("base64");
  }
  static preventSubmitOnEnter(keyEvent: any) {
    if (keyEvent.keyCode === 13) {
      keyEvent.preventDefault();
    }
  }

  /**
   * Gets filename from Response headers
   * @param headers
   * @returns
   */
  static getFileNameFromResponseHeaders(headers: any) {
    let contentDisposition = headers?.get("Content-Disposition");
    var filename = "";
    if (contentDisposition && contentDisposition.indexOf("attachment") !== -1) {
      var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, "");
      }
    }
    return filename;
  }

  /**
   * Get UTC Date
   */
  static getCurrentUTCDate() {
    var date = new Date();
    var now_utc = Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    );
    return new Date(now_utc);
  }

  /**
   * Get Formatted Date
   */

  static getFormattedDate(inputDate: any) {
    const date = new Date(inputDate);
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    return `${month}-${day}-${date.getFullYear()}`;
  }
}
