export enum KEYCODE {
    BACKSPACE = 8,
    ENTER = 13,
    TAB = 9,
    LEFTARROW = 37,
    RIGHTARROW = 39,
    DELETE = 46,
    ZERO = 48,
    NINE = 57,
  }
  