import React from "react";
import "./MetricsSubCards.scss";
import { MetricsSubCardsData } from "../../../constants/data/MetricsSubCardsData";
import { MONITORING_ACTIVITY } from "../../../enums/monitoringActivity";

function MetricsSubCards(props: any) {
  const { activeTabContent, activeTab } = props;
  return (
    <>
      {MetricsSubCardsData[MONITORING_ACTIVITY[activeTab]?.toLowerCase()] &&
        MetricsSubCardsData[MONITORING_ACTIVITY[activeTab]?.toLowerCase()]?.map(
          (subCard: any) => {
            return subCard?.displayTitle ? (
              <div className="align-items-center d-flex mb-3 mx-4 py-4 w-100 sub-card">
                <span
                  className={`sub-card-value px-5 ${
                    subCard?.isError ? "error" : ""
                  }`}
                >
                  {activeTabContent[subCard?.fieldName.toString()]}
                </span>
                <span className="sub-card-title pe-5">
                  {subCard?.displayTitle}
                </span>
              </div>
            ) : (
              <></>
            );
          }
        )}
    </>
  );
}

export default MetricsSubCards;
