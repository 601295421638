import { FocusEvent, useEffect, useState } from "react";
import "./Datepicker.scss";
import { DatePicker } from "rsuite";
import { isAfter } from "date-fns";

interface DatepickerProps {
  label?: string;
  onFocus?: (e: FocusEvent) => void;
  onChange?: (e: any) => void;
  showClear?: boolean;
  value?: string;
  disabled?: boolean;
  name?: string;
  required?: boolean;
  placeholder?: string;
  oneTap?: boolean;
  formik?: any;
  forceShowError?: boolean;
}

const Datepicker = (props: DatepickerProps) => {
  const today = new Date();
  const [value, setValue] = useState<string>(
    props.formik?.values[props.name] || ""
  );
  useEffect(() => {
    setValue(props.formik?.values[props.name]);
  }, [props.formik]);
  return (
    <div className="w-100 date-picker-wrapper">
      <div className="date-of-birth-label">
        {props?.label}
        {props.required && <span className="required">*</span>}
      </div>
      <div className="datepicker">
        <DatePicker
          format="MM-dd-yyyy"
          shouldDisableDate={(date) => isAfter(date, today)}
          value={props.formik.values.birthDate}
          name={props.name}
          placeholder={props.placeholder}
          oneTap
          onChange={(val) => {
            props?.onChange(val);
          }}
          cleanable={false}
          disabled={props?.disabled}
          onFocus={(e: any) => (e.target.autocomplete = "off")}
          onBlur={() => props.formik.setFieldTouched("birthDate", true)}
        />
        {props.required && props.formik.touched.birthDate && !value && (
          <div className="error-message">
            <span className="custom-error-message">
              {props?.label} is required
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default Datepicker;
