import "./HospitalIntegration.scss";
import Grid from "../../shared/Grid/Grid";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { ReactComponent as Health } from "../../../assets/svg/home_health.svg";
import { ReactComponent as EditUser } from "../../../assets/svg/edit.svg";
import { ReactComponent as CircleCheck } from "../../../assets/svg/check_circle.svg";
import { ReactComponent as Error } from "../../../assets/svg/error.svg";
import { ReactComponent as Audit } from "../../../assets/svg/audit.svg";
import Button from "@mui/material/Button";
import Modal from "../../shared/Modal/Modal";
import React from "react";
import HospitalAuditTracker from "../HospitalAuditTracker/HospitalAuditTracker";
import Slider from "../../shared/Slider/Slider";
import HospitalForm from "../../HospitalForm/HospitalForm";
import { HospitalIntegrationGird_LABELS } from "../../../constants/labels/Hospital-Integration/HospitalIntegrationGird";
import { TENANT_STATUS } from "../../../enums/tenantStatus";
import {
  useLazyGetAllHospitalsQuery,
  useLazyGetTenantApplicationInformationQuery,
} from "../../../services/rtkQueryServices/hospitalService";
import { useTenantStatusQuery } from "../../../services/rtkQueryServices/masterDataApi";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import { useSearchParams } from "react-router-dom";
import { searchParam } from "../../../constants/searchParam";
interface rowDetails {
  rowData: any[];
  rowCount: number;
}
function HospitalIntegration() {
  const [openAudit, setOpenAudit] = useState<string>();
  const [open, setOpen] = React.useState(false);
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("");
  const [initialFormValue, setInitialFormValue] = useState<any>({});
  const [editHospitalName, setEditHospitalName] = useState<string>();
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    searchString: null,
    sortByColumnName: null,
    sortOrder: null,
  });
  const [liveEdit, setLiveEdit] = useState<boolean>(false);
  const [setAllHospitalsData, allHospitalsData] = useLazyGetAllHospitalsQuery();
  const [setHospitalData, hospitalData] =
    useLazyGetTenantApplicationInformationQuery();
  const [rowDetails, setrowDetails] = useState<rowDetails>();
  const [auditSearchString, setAuditSearchString] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const tenantName = useMemo(
    () => searchParams.get(searchParam.tenantName),
    [searchParams]
  );
  const tenantId = useMemo(
    () => searchParams.get(searchParam.tenantId),
    [searchParams]
  );
  useEffect(() => {
    if (tenantId) {
      setHospitalData(tenantId);
    } else {
      setAllHospitalsData(filterCriteria);
    }
  }, [tenantId]);

  useEffect(() => {
    if (hospitalData.isSuccess && tenantId && !openSlider) {
      const data = hospitalData.data?.data;
      setrowDetails({
        ...rowDetails,
        rowData: [{ ...data }],
        rowCount: data.length,
      });
      editHospital(data);
    } else if (allHospitalsData.isSuccess) {
      const data = allHospitalsData.data?.data;
      setrowDetails({
        ...rowDetails,
        rowData: data.listOfHospitals,
        rowCount: data.totalCount,
      });
    }
  }, [hospitalData, allHospitalsData, tenantId]);
  useEffect(() => {
    if (!tenantId) {
      setAllHospitalsData(filterCriteria);
    }
  }, [filterCriteria]);
  const editHospital = (editHospitalData: any) => {
    setFormType(HospitalIntegrationGird_LABELS.edit);
    let data = { ...editHospitalData };
    data.npis = data.npis ? data.npis.split(",") : [""];
    setInitialFormValue(data);
    setOpenSlider(true);
    setEditHospitalName(data.hospitalName);
  };

  const HospitalActions = (props: any) => {
    return (
      <>
        <EditUser
          className="me-3 cursor-pointer"
          title={HospitalIntegrationGird_LABELS.edit}
          onClick={() => {
            // props.data.liveEdit = liveEdit;
            const content = {
              ...props.data,
              liveEdit: liveEdit,
            };
            editHospital(content);
          }}
        />
        <Audit
          className="cursor-pointer"
          onClick={() => {
            setOpenAudit(props.data.tenantApplicationInformationId);
            setOpen(true);
          }}
          title={HospitalIntegrationGird_LABELS.audit}
        />
        <Modal
          isOpen={
            open && props.data.tenantApplicationInformationId === openAudit
          }
          onClose={() => {
            setOpen(false);
            setAuditSearchString(null);
          }}
          title={"Audit Tracker | " + props.data.hospitalName}
          modalWidth="1820px"
          headerBg="#FFFFFF"
          headerColor="#13ada4"
          headerLogo={<Audit />}
          modalHeaderBottomStyle={{
            borderBottom: "1px solid var(--primary-color)",
          }}
          canSearch
          searchFieldName="Enter more than 3 characters"
          setSearchQuery={(value: string) => {
            setAuditSearchString(
              value?.trim().length > 0 ? value.trim() : null
            );
          }}
        >
          {open && props.data.tenantApplicationInformationId === openAudit && (
            <HospitalAuditTracker
              data={props?.data}
              searchString={auditSearchString ? auditSearchString : ""}
            />
          )}
        </Modal>
      </>
    );
  };

  const ProviderStatus = (props: any) => {
    return (
      <>
        <div
          className={
            props.data?.statusId === TENANT_STATUS.SAVED
              ? "saved"
              : props.data?.statusId === TENANT_STATUS.INITIATED
              ? "initiated"
              : props.data?.statusId === TENANT_STATUS.EXPIRED
              ? "expired"
              : props.data?.statusId === TENANT_STATUS.SUBMITTED
              ? "submitted"
              : props.data?.statusId === TENANT_STATUS.LIVE
              ? "live"
              : props.data?.statusId === TENANT_STATUS.DEACTIVATED
              ? "deactivated"
              : props.data?.statusId === TENANT_STATUS.RE_INITIATED
              ? "reinitiated"
              : ""
          }
          title={props.value}
        >
          {props.value}
        </div>
      </>
    );
  };

  const handleHospitalView = (viewContent: any) => {
    setFormType(HospitalIntegrationGird_LABELS.view);
    let data = { ...viewContent };
    data.npis = data.npis ? data.npis.split(",") : [""];
    setInitialFormValue(data);
    setOpenSlider(true);
    setEditHospitalName(data.hospitalName);
  };

  const StatusCheck = (props: any) => {
    return (
      <>
        <div
          className="d-flex align-items-center"
          title={props.data.hospitalName}
        >
          <div
            className="me-3 hospital-name"
            onClick={() => {
              handleHospitalView(props.data);
            }}
          >
            {props.data.hospitalName}
          </div>
          {props.data.statusId === TENANT_STATUS.SUBMITTED ? (
            <CircleCheck />
          ) : (
            <></>
          )}
        </div>
      </>
    );
  };

  const columnDefs: GridColumnDefs[] = [
    {
      headerName: HospitalIntegrationGird_LABELS.hospital,
      field: "hospitalName",
      cellRenderer: StatusCheck,
      sortable: true,
    },
    {
      headerName: HospitalIntegrationGird_LABELS.npis,
      field: "npis",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.contactFirstName,
      field: "contactFirstName",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.contactLastName,
      field: "contactLastName",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.emailAddress,
      field: "emailAddress",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.status,
      field: "status",
      sortable: true,
      cellRenderer: ProviderStatus,
    },
    {
      headerName: HospitalIntegrationGird_LABELS.EHRType,
      field: "ehrType",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.appType,
      field: "applicationType",
    },
    {
      headerName: HospitalIntegrationGird_LABELS.actions,
      field: "actions",
      cellRenderer: HospitalActions,
    },
  ];

  const [pageSize, setPageSize] = useState<any>(20);
  const [page, setPage] = useState<any>(1);
  const [gridRowData, setGridRowData] = useState<any[]>(
    rowDetails?.rowData || []
  );
  const { data: tenant } = useTenantStatusQuery({});
  useEffect(() => {
    setGridRowData(rowDetails?.rowData);
  }, [rowDetails]);
  const applyPagination = () => {
    rowDetails &&
      setGridRowData([
        ...rowDetails?.rowData?.slice((page - 1) * pageSize, page * pageSize),
      ]);
  };
  useEffect(() => {
    applyPagination();
  }, [page, pageSize]);
  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  useEffect(() => {
    tenantName &&
      setFilterCriteria({
        ...filterCriteria,
        searchString: tenantName,
      });
  }, [tenantName]);

  const searchResults = (value: any) => {
    if (searchParams.has(searchParam.tenantName) && value !== tenantName) {
      searchParams.delete(searchParam.tenantName);
      setSearchParams(searchParams);
    }
    setFilterCriteria({
      ...filterCriteria,
      searchString: value?.trim().length > 0 ? value.trim() : null,
      pageNumber: 1,
    });
  };
  const sordOrder = (columnName: string, order: string) => {
    if (order === "asc" || order === "desc") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    }
  };
  const searchFilterDropdownOnChange = (filter: any[]) => {
    setFilterCriteria({
      ...filterCriteria,
      filters: [
        {
          fieldName: "status",
          values: filter.at(0) === "" ? [] : filter,
        },
      ],
    });
  };
  return (
    <div className="user-management-wrapper">
      <Grid
        // isLoading
        columnDefs={columnDefs}
        rowData={gridRowData}
        serverSidePagination
        rowCount={rowDetails?.rowCount || 0}
        serverSideSorting
        showPagination
        showFilterIcon
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        masterDetail
        canSearch
        searchInputValue={tenantName}
        setSearchQuery={(value: string) => {
          searchResults(value);
        }}
        sortBy={(columnName, order) => {
          sordOrder(columnName, order);
        }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        showSearchFilterDropdown
        searchFilterDropdownOnChange={(filter) =>
          searchFilterDropdownOnChange(filter)
        }
        searchFilterDropdownPlaceholder="Status"
        searchFilterDropdownMenuList={
          tenant?.data?.map((item: any) => item?.name) || [""]
        }
        headerContent={HospitalIntegrationGird_LABELS.hospitalIntegrationList}
        actionButtons={[
          <>
            <Button
              variant="contained"
              endIcon={<Health />}
              onClick={() => {
                setFormType(HospitalIntegrationGird_LABELS.add);
                setInitialFormValue({
                  emailAddress: "",
                  npis: [""],
                  appTypeId: "",
                });
                setOpenSlider(true);
              }}
            >
              {HospitalIntegrationGird_LABELS.addHospital}
            </Button>
          </>,
        ]}
        showTotalRowCount
        uniqueIdentifierFieldName={"id"}
        searchFieldName={HospitalIntegrationGird_LABELS.hospitals}
      ></Grid>
      <Slider
        isOpen={openSlider}
        onClose={() => {
          setOpenSlider(!openSlider);
          searchParams.delete(searchParam.tenantId);
          setSearchParams(searchParams);
          setAllHospitalsData(filterCriteria);
        }}
        title={
          formType === HospitalIntegrationGird_LABELS.edit ||
          formType === HospitalIntegrationGird_LABELS.view
            ? editHospitalName
            : HospitalIntegrationGird_LABELS.addHospital
        }
      >
        <HospitalForm
          formType={formType}
          setFormType={setFormType}
          formInitialValues={initialFormValue}
          // liveEdit={liveEdit}
          // setLiveEdit={setLiveEdit}
          onClose={() => {
            searchParams.delete(searchParam.tenantId);
            setSearchParams(searchParams);
            setAllHospitalsData(filterCriteria);
            setOpenSlider(!openSlider);
          }}
        />
      </Slider>
    </div>
  );
}
export default HospitalIntegration;
