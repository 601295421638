import { Checkbox, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";

const StatusFilter = (props: any) => {
  const [value, setValue] = useState<string[]>([""]);
  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (e.target.value?.length > 0) {
      if (value.indexOf("") === -1) setValue(updatedValue);
      else updatedValue.shift();
    } else {
      updatedValue = [""];
    }
    setValue(updatedValue);
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <span title={value.toString()}>
      <Select
        labelId="grid-search"
        multiple
        value={value}
        onClick={() => {
          setIsOpen(true);
        }}
        renderValue={(p) => (p.toString() ? p.toString() : "Status")}
        open={isOpen}
        onChange={handleChange}
        IconComponent={() => <DropdownIcon />}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          maxWidth: "70px",
        }}
        title={value.join(", ")}
      >
        <MenuItem hidden value="">
          {props.searchFilterDropdownPlaceholder}
        </MenuItem>
        {props?.searchFilterDropdownMenuList?.map((val: any, index: number) => (
          <MenuItem key={index} value={val}>
            <Checkbox checked={value.indexOf(val) > -1} size="small" />
            <span className={val?.toLowerCase()}>{val}</span>
          </MenuItem>
        ))}
        <div className="d-flex justify-content-between px-3">
          <button
            className="m-1 filter-button"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              props.searchFilterDropdownOnChange &&
                props.searchFilterDropdownOnChange(value);
              setIsOpen(false);
            }}
          >
            Apply
          </button>
          <button
            className="m-1 filter-button"
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              props.searchFilterDropdownOnChange &&
                props.searchFilterDropdownOnChange([""]);
              setValue([""]);
              setIsOpen(false);
            }}
          >
            Clear
          </button>
        </div>
      </Select>
    </span>
  );
};

export default StatusFilter;
