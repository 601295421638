import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { regex } from "../../constants/regex";
import { ReactComponent as Email } from "../../assets/svg/mail.svg";
import { Formik, Form } from "formik";
import Input from "../shared/Input/Input";
import * as Yup from "yup";
import {
  useGetUserEmailByUserIdQuery,
  useLazyUpdatePasswordQuery,
} from "../../services/rtkQueryServices/authenticationService";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../constants/routePaths";
import { useParams } from "react-router-dom";
import { UserService } from "../../services/UserService";
import { validationMessages } from "../../constants/forms/validationMessages";
import LoginError from "../Error/LoginError";
import EmailLogin from "./EmailLogin";
import { LOGIN_LITERALS } from "../../constants/literals";
export default function SetPassword(props: any) {
  const { userId } = useParams();
  const {
    data: user,
    isLoading,
    isError,
    error,
    refetch,
  } = useGetUserEmailByUserIdQuery<any>(
    encodeURIComponent(encodeURIComponent(userId))
  );
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>("");
  useEffect(() => {
    refetch();
  }, [userId]);

  const initialFormValue = useMemo<any>(() => {
    return {
      password: "",
      confirmPassword: "",
    };
  }, []);
  const formInitialValues = initialFormValue;
  const [setPassword] = useLazyUpdatePasswordQuery();

  const validationSchema = () =>
    Yup.object({
      password: Yup.string()
        .required(validationMessages.passwordRequired)
        .min(8, validationMessages.passwordLength)
        .matches(
          regex.passwordValidation,
          validationMessages.passwordRegexMatch
        )
        .max(64, validationMessages.passwordMaxLength),
      confirmPassword: Yup.string()
        .required(validationMessages.passwordRequired)
        .oneOf([Yup.ref("password"), null], validationMessages.confirmPassword),
    });
  return (
    <>
      {userId &&
      user?.data?.userEmail &&
      ((user?.data?.isAccountVerified && props?.isReset) ||
        (!user?.data?.isAccountVerified && !props?.isReset)) ? (
        <Formik
          initialValues={{
            ...formInitialValues,
            userEmail: user?.data?.userEmail,
          }}
          validationSchema={validationSchema}
          onSubmit={(values: any) => {
            setPassword(values).then((res: any) => {
              if (res?.data?.isSuccess) {
                UserService.logOut();
              } else {
                setErrorMessage(res?.error?.data?.message);
              }
            });
          }}
        >
          {(formik) => (
            <Form
              onSubmit={formik.handleSubmit}
              className="login-form-container"
            >
              <div className="login-form">
                <div className="login-header mb-4">
                  {props.isReset ? "Reset Password" : "Set Password"}
                </div>
                {props.isReset && (
                  <div className="login-mail mb-2">
                    <Email />
                    {user?.data?.userEmail}
                  </div>
                )}
                <Input
                  name="password"
                  placeholder="Enter Password"
                  type="password"
                  errorMsg={
                    (formik.touched.password || formik.submitCount > 0) &&
                    formik.errors.password &&
                    (formik.errors as any).password
                  }
                  value={formik.values.password}
                  onInput={formik.handleChange}
                  required
                />
                <Input
                  name="confirmPassword"
                  placeholder="Re Enter Password"
                  type="password"
                  errorMsg={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword &&
                    (formik.errors as any).confirmPassword
                  }
                  required
                  value={formik.values.confirmPassword}
                  onInput={formik.handleChange}
                />
              </div>
              {errorMessage && (
                <span className="error-message">{errorMessage}</span>
              )}
              <div className="login-actions">
                <Button
                  className={
                    !formik.isValid ? "form-button disabled" : "form-button"
                  }
                  type="submit"
                  disabled={!formik.isValid}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        !isLoading &&
        !user?.data?.userEmail &&
        !error?.data && <>{LOGIN_LITERALS.somethingWentWrong}</>
      )}
      {!isLoading && !isError && (
        <>
          {!user?.data?.isAccountVerified && props?.isReset && (
            <LoginError errorMessage={LOGIN_LITERALS.accessDenied}></LoginError>
          )}
          {user?.data?.isAccountVerified && !props?.isReset && (
            <EmailLogin
              informationMessage={LOGIN_LITERALS.passwordAlreadySet}
            />
          )}
        </>
      )}
      {!isLoading && isError && error?.data && (
        <LoginError errorMessage={error?.data?.message}></LoginError>
      )}
    </>
  );
}
