export const MetricsSubCardsData: any = {
  resource: [
    {
      displayTitle: "Total Resources Fetched",
      fieldName: "totalResourceFetched",
      isError: false,
    },
    {
      displayTitle: "Total Calls",
      fieldName: "totalCallsPerformed",
      isError: false,
    },
    {
      displayTitle: "Unsuccessful Calls",
      fieldName: "unsuccessfulCalls",
      isError: true,
    },
  ],
  provider: [
    {
      displayTitle: "App Launches",
      fieldName: "totalCallsPerformed",
      isError: false,
    },
    {
      displayTitle: "Patients Launched",
      fieldName: "patientsLaunched",
      isError: false,
    },
    {
      displayTitle: "Launch Failure",
      fieldName: "unsuccessfulCalls",
      isError: true,
    },
  ],
  patient: [
    {
      displayTitle: "App Launches",
      fieldName: "totalCallsPerformed",
      isError: false,
    },
    {
      displayTitle: "Launch Failure",
      fieldName: "unsuccessfulCalls",
      isError: true,
    },
  ],
};
