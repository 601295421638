export const hospitalForms_LABELS_LITERALS = {
  hospitalRegistration: "Hospital Registration",
  hospitalName: "Hospital name ",
  contactName: "Contact name",
  firstName: "First name",
  lastName: "Last name",
  contactFirstName: "Contact first name",
  contactLastName: "Contact last name",
  emailAddress: "Email address",
  EHRType: "EHR",
  appType: "App type",
  clientID: "Client ID",
  clientSecret: "Client Secret",
  r4Endpoint: "R4 Endpoint",
  save: "Save",
  submit: "Submit",
  invite: "Invite",
  approve: "Approve",
  reInitiate: "Re-initiate",
  testAgain: "Test Again",
  activate: "Activate",
  deactivate: "Deactivate",
  hospitalDataSuccess: `Details submitted successfully,\nOur team will get back to you.`,
  clientIdHelpText:
    "The Client Id is only generated after registering the application on your EHR system",
  baseURLHelpText:
    "Please find your R4 endpoint on https://open.epic.com/MyApps/Endpoints. Here is a sample (https://abc.xyz.net/FHIR/api/FHIR/R4/)",
  linkExpired: "This Link has expired.",
  contactSupport: "Please contact our support team at",
  supportEmailId:"eagleadmin@precedencehealthcare.com",
  hospitalDetailsSubmitted: "Details already submitted successfully.",
  clientId: "Client ID",
  edit: "Edit",
  openemr: "Openemr",
  openEmr: "Open EMR",
  given: "Given",
  npis: "NPI(s) - ",
  match: "matches with",
  linkHospital: "Do you want to link them?",
  differentHospitalName: "is different from the existing one.",
  updateHospitalName: "Do you want to update it?",
  clientInfoHelpText:
    "It must be provided by hospital user. However, Eagle user can also provide.",
};
