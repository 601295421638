import React, { useEffect, useState } from "react";
import ActivityGrid from "../ActivityGrid/ActivityGrid";
import { ReactComponent as Metrics } from "../../../assets/svg/equalizer.svg";
import { MONITORING_ACTIVITY } from "../../../enums/monitoringActivity";
import { routePaths } from "../../../constants/routePaths";
import { useNavigate, useParams } from "react-router-dom";
import "./ApplicationLogs.scss";
import { MonitoringNavTabData } from "../../../constants/data/MonitoringNavTabsData";
import { DateRangePicker } from "rsuite";
import * as dateFns from "date-fns";
import DateRangeDefaultData from "../../../constants/data/DateRangeDefaultData";

function ApplicationLogs() {
  const navigate = useNavigate();
  const { activityType } = useParams();
  const [dateFilter, setDateFilter] = useState<any>(null);
  const { allowedRange } = DateRangePicker;

  useEffect(() => {
    if (!activityType) {
      navigate(
        `${routePaths.monitoring}/${routePaths.applicationLogs}/${"provider"}`
      );
    }
  }, [activityType, navigate]);

  return (
    <div className="application-logs-container d-flex flex-column justify-content-around justify-content-between">
      <div className="d-flex justify-content-between ">
        <div>Application Logs</div>
        <div
          onClick={() => {
            navigate(
              `${routePaths.monitoring}/${routePaths.applicationMetrics}`
            );
          }}
        >
          <span className="navigate-link pe-1">Switch to metrics</span>
          <span>
            <Metrics />
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-between bg-white my-4 rounded-3 tabs-container align-items-center">
        <div>
          {MonitoringNavTabData.map((item, index) => (
            <span
              key={index}
              className={`${
                MONITORING_ACTIVITY[item?.name]?.toLowerCase() ===
                  activityType?.toLowerCase() && "active-tab"
              } tab p-4 rounded-2`}
              onClick={() => {
                navigate(
                  `${routePaths.monitoring}/${
                    routePaths.applicationLogs
                  }/${MONITORING_ACTIVITY[item?.name]?.toLowerCase()}`
                );
              }}
            >
              {item.displayName}
            </span>
          ))}
        </div>
        <div className="me-3">
          <DateRangePicker
            ranges={DateRangeDefaultData}
            onChange={(value) => {
              setDateFilter({
                ...dateFilter,
                fromDate: value ? value[0] : null,
                toDate: value ? value[1] : null,
              });
            }}
            placeholder="FROM DATE ~ TO DATE"
            disabledDate={allowedRange(
              dateFns.startOfDay(dateFns.subDays(new Date(), 89)),
              new Date()
            )}
            placement="bottomEnd"
          />
        </div>
      </div>
      <div className="p-2 h-75">
        <ActivityGrid
          dateFilter={dateFilter}
          activity={activityType?.toUpperCase()}
        />
      </div>
    </div>
  );
}

export default ApplicationLogs;
