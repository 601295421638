import { FieldArray, Form, Formik } from "formik";
import React, { useEffect, useMemo, useRef, useState } from "react";
import Input from "../shared/Input/Input";
import * as Yup from "yup";
import "./HospitalForm.scss";
import { ReactComponent as CheckCircle } from "../../assets/svg/check_circle.svg";
import { ReactComponent as Ellipse } from "../../assets/svg/ellipse.svg";
import { ReactComponent as Error } from "../../assets/svg/error.svg";
import { ReactComponent as Add } from "../../assets/svg/add.svg";
import Dropdown from "./DropDown/Dropdown";
import { hospitalForms_LABELS_LITERALS } from "../../constants/labels/Hospital-Integration/HospitalUserForm";
import { hospitalStatus } from "../../constants/toasts/hospitalStatus";
import {
  useLazyRegisterHospitalQuery,
  useLazyUpdateHospitalQuery,
} from "../../services/rtkQueryServices/hospitalService";
import { Utility } from "../../utils/Utility";
import { TENANT_STATUS } from "../../enums/tenantStatus";
import {
  useAppTypeQuery,
  useEhrTypeQuery,
} from "../../services/rtkQueryServices/masterDataApi";
import { hospitalToasts } from "../../constants/toasts/hospitalToasts";
import ConfirmationDialog from "../shared/ConfirmationDialog/ConfirmationDialog";
import { regex } from "../../constants/regex";
import { validationMessages } from "../../constants/forms/validationMessages";
import { snackbarWrapper } from "../shared/SnackbarWrapper/SnackbarWrapper";
import Modal from "../shared/Modal/Modal";
import HospitalUrlsAuditGrid from "../Hospital-Integration/HospitalUrlsAuditGrid/HospitalUrlsAuditGrid";

const HospitalForm = (props: any) => {
  const { formInitialValues, formType, setFormType } = props;
  const [setAddHospital, addHospital] = useLazyRegisterHospitalQuery();
  const [setUpdateHospital, updateHospital] = useLazyUpdateHospitalQuery();
  const [npisLength, setNpisLength] = useState<number>(
    formInitialValues.npis.length
  );
  const [liveEdit, setLiveEdit] = useState<boolean>(false);
  const [liveLabel, setLiveLabel] = useState<string>(
    hospitalForms_LABELS_LITERALS.edit
  );
  const [isUrlModalOpen, setIsUrlModalOpen] = useState<boolean>();
  const [invalidNpis, setInvalidNpis] = useState<any>([]);
  const EHRs = useEhrTypeQuery(null);
  const appTypes = useAppTypeQuery(null);
  const [confirmHospitalName, setConfirmHospitalName] =
    useState<boolean>(false);
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
  const [hospitalName, setHospitalName] = useState<string>("");
  const validationSchema = () =>
    Yup.object({
      hospitalName: Yup.string()
        .required("Hospital name is required")
        .max(256, "Hospital name should not exceed 256 characters")
        .matches(
          regex.nameValidation,
          validationMessages.alphabetsAndSpaceValidation(
            hospitalForms_LABELS_LITERALS.hospitalName
          )
        ),
      contactFirstName: Yup.string()
        .required("Contact first name is required")
        .max(64, "Contact First name should not exceed 64 characters")
        .matches(
          regex.nameValidation,
          validationMessages.alphabetsAndSpaceValidation(
            hospitalForms_LABELS_LITERALS.contactFirstName
          )
        ),
      contactLastName: Yup.string()
        .required("Contact last name is required")
        .max(64, "Contact Last name should not exceed 64 characters")
        .matches(
          regex.nameValidation,
          validationMessages.alphabetsAndSpaceValidation(
            hospitalForms_LABELS_LITERALS.contactLastName
          )
        ),
      emailAddress: Yup.string()
        .required("Email address name is required")
        .matches(regex.emailValidation, validationMessages.validEmail)
        .max(128, "Email address should not exceed 128 characters"),
      ehrTypeId: Yup.string().required("EHR type is required"),
      applicationTypeId: Yup.string().required("App type is required"),
    });

  const updateStatus = (formik: any, status: any, statusType: string) => {
    const statusId = status ? status : formInitialValues.statusId;
    let values = {
      ...formik.values,
      tenantApplicationStatusId: statusId,
      appTypeId: formik.values.applicationTypeId,
    };
    if (formType === "Add") {
      setAddHospital(values).then((res) => {
        if (res?.data?.isSuccess) {
          snackbarWrapper(hospitalToasts[statusType], "success");
        }
      });
    } else {
      setUpdateHospital(values).then((res) => {
        if (res?.data?.isSuccess) {
          if (res?.data?.data?.matchedNpis === null)
            snackbarWrapper(hospitalToasts[statusType], "success");
        }
      });
    }
  };

  const menuItemEHRs = useMemo(() => {
    let menuItems = EHRs.data?.data.map((item: any) => {
      return {
        value: item.id,
        displayValue:
          Utility.convertToPascalCase(item.name) ===
          hospitalForms_LABELS_LITERALS.openemr
            ? hospitalForms_LABELS_LITERALS.openEmr
            : Utility.convertToPascalCase(item.name),
      };
    });
    return menuItems;
  }, [EHRs]);

  const menuItemAppTypes = useMemo(() => {
    let menuItems = appTypes.data?.data.map((item: any) => {
      return {
        value: item.id,
        displayValue: Utility.convertToPascalCase(item.name),
      };
    });
    return menuItems;
  }, [appTypes]);
  const handleCancelToggle = () => {
    setOpenConfirmation(false);
  };
  const handleHospitalName = (hospitalName: string) => {
    if (updateHospital.originalArgs.hospitalName === hospitalName) {
      setUpdateHospital({
        ...updateHospital.originalArgs,
        matchedTenantInformationId:
          updateHospital?.data?.data?.matchedTenantInformationId,
      }).then((res) => {
        if (res?.data?.isSuccess) {
          if (res?.data?.data?.matchedNpis === null)
            snackbarWrapper(hospitalToasts.Approve, "success");
        }
      });
    } else {
      setHospitalName(hospitalName);
      setConfirmHospitalName(true);
    }
  };

  const handleLink = (updateHospital: any) => {
    setOpenConfirmation(true);
  };
  useEffect(() => {
    if (addHospital.isSuccess || addHospital.isError) {
      if(addHospital.isSuccess) {
        props.onClose();
      }
      else {
        setInvalidNpis(
          (addHospital.error as any).data?.data?.invalidNpis && [
            ...(addHospital.error as any).data?.data?.invalidNpis,
          ]
        );
        if (invalidNpis !== null) {
          snackbarWrapper(
            `${(addHospital.error as any).data?.message} ${
              (addHospital.error as any).data?.data?.invalidNpis
            } `,
            "error"
          );
        }
        var validationErrors = (addHospital.error as any).data?.errors;
        if (validationErrors) {
          (Array.prototype.concat.apply([], Object.values(validationErrors))).forEach(element => {
            snackbarWrapper(element, "error");
          });
        }
        else {
          snackbarWrapper((addHospital.error as any).data?.message, "error");
        }
      }
    }
    else if (updateHospital.isSuccess || updateHospital.isError){
      if (updateHospital.isSuccess) {
        if (
          updateHospital.data?.data?.isSuccess === true &&
          updateHospital.data?.data?.matchedNpis === null
          ) {
            props.onClose();
          } else if (updateHospital.isFetching === false) {
            handleLink(updateHospital);
        }
      }
      else {
        var validationErrors = (updateHospital.error as any).data?.errors;
        if (validationErrors) {
          (Array.prototype.concat.apply([], Object.values(validationErrors))).forEach(element => {
            snackbarWrapper(element, "error");
          });
        }
        else {
          snackbarWrapper((updateHospital.error as any).data?.message, "error");
        }
      }
    }
  }, [addHospital, updateHospital]);

  const clearClientInformation = (formik: any) => {
    formik?.setValues({
      ...formInitialValues,
      baseURL: undefined,
    });
  };

  return (
    <div className="hospital-form-container mb-5 mx-2 p-2">
      <div className="status-container">
        {formInitialValues.statusId === TENANT_STATUS.SUBMITTED ? (
          <CheckCircle />
        ) : (
          <></>
        )}
        {formInitialValues.statusId === TENANT_STATUS.LIVE && <Ellipse />}
        <span>{formInitialValues.status}</span>
      </div>
      <Formik
        enableReinitialize
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          values.appTypeId = values.applicationTypeId;
          if (formType === "Add") {
            values.statusId = TENANT_STATUS.SAVED;
            values.tenantId = Utility.generateGuidWithLength(30);
            setAddHospital(values);
          } else {
            setUpdateHospital(values);
          }
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit} className="user-form-container">
            <div className="add-user-form">
              <Input
                name="hospitalName"
                maxLength={256}
                errorMsg={
                  (formik.touched.hospitalName || formik.submitCount > 0) &&
                  formik.errors.hospitalName &&
                  (formik.errors as any).hospitalName
                }
                required
                onChange={formik.handleChange}
                value={formik.values.hospitalName}
                label={hospitalForms_LABELS_LITERALS.hospitalName}
                placeholder={hospitalForms_LABELS_LITERALS.hospitalName}
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                    !liveEdit) ||
                  formType === "View"
                }
              />
              <FieldArray
                name="npis"
                render={(arrayHelpers) =>
                  formik.values.npis &&
                  formik.values.npis.length > 0 &&
                  formik.values.npis?.map((value: any, index: any) => (
                    <div key={index} className="npi-input-container">
                      <Input
                        minLength={10}
                        maxLength={10}
                        onKeyDown={Utility.allowOnlyNumerics}
                        errorMsg={
                          (index + 1 !== formik.values.npis.length &&
                            value.length === 0 &&
                            `NPI${index + 1} is required`) ||
                          (invalidNpis &&
                            invalidNpis.includes(value) &&
                            "error ")
                        }
                        onClear={() => arrayHelpers.remove(index)}
                        placeholder={`NPI${index + 1}`}
                        required={
                          index + 1 !== formik.values.npis.length || index === 0
                        }
                        label={`NPI${index + 1}`}
                        name={`npis.${index}`}
                        value={value}
                        onChange={formik.handleChange}
                        showClear={
                          !(index === 0 && formik.values.npis?.length === 1) &&
                          !(
                            formInitialValues.statusId === TENANT_STATUS.LIVE &&
                            index <= npisLength - 1
                          )
                        }
                        disabled={
                          formInitialValues.statusId ===
                            TENANT_STATUS.DEACTIVATED ||
                          (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                            index <= npisLength - 1) ||
                          formType === "View"
                        }
                      />
                      {index + 1 === formik.values.npis?.length && (
                        <button
                          className="add-button"
                          type="button"
                          disabled={
                            value.length < 10 ||
                            formInitialValues.statusId ===
                              TENANT_STATUS.DEACTIVATED ||
                            formType === "View"
                          }
                          onClick={() => arrayHelpers.insert(index + 1, "")}
                        >
                          <Add />
                        </button>
                      )}
                    </div>
                  ))
                }
              ></FieldArray>
              <Input
                name="contactFirstName"
                placeholder={hospitalForms_LABELS_LITERALS.contactFirstName}
                maxLength={64}
                errorMsg={
                  (formik.touched.contactFirstName || formik.submitCount > 0) &&
                  formik.errors.contactFirstName &&
                  (formik.errors as any).contactFirstName
                }
                onChange={formik.handleChange}
                value={formik.values.contactFirstName}
                required
                label={hospitalForms_LABELS_LITERALS.contactFirstName}
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                    !liveEdit) ||
                  formType === "View"
                }
              />
              <Input
                name="contactLastName"
                placeholder="Contact Last name"
                maxLength={64}
                errorMsg={
                  (formik.touched.contactLastName || formik.submitCount > 0) &&
                  formik.errors.contactLastName &&
                  (formik.errors as any).contactLastName
                }
                onChange={formik.handleChange}
                value={formik.values.contactLastName}
                required
                label={hospitalForms_LABELS_LITERALS.contactLastName}
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                    !liveEdit) ||
                  formType === "View"
                }
              />
              <Input
                name="emailAddress"
                maxLength={128}
                onChange={formik.handleChange}
                value={formik.values.emailAddress}
                errorMsg={
                  (formik.touched.emailAddress || formik.submitCount > 0) &&
                  formik.errors.emailAddress &&
                  (formik.errors as any).emailAddress
                }
                required
                label={hospitalForms_LABELS_LITERALS.emailAddress}
                placeholder={hospitalForms_LABELS_LITERALS.emailAddress}
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                    !liveEdit) ||
                  formType === "View"
                }
              />
              <Dropdown
                label={hospitalForms_LABELS_LITERALS.EHRType}
                placeholder={hospitalForms_LABELS_LITERALS.EHRType}
                name="ehrTypeId"
                menuItems={menuItemEHRs}
                formik={formik}
                onChange={() => {
                  if (formInitialValues.statusId === TENANT_STATUS.SUBMITTED)
                    clearClientInformation(formik);
                    formik.values.baseURL = undefined
                }}
                forceShowError={formik.submitCount > 0}
                required
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.LIVE ||
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  formType === "View"
                }
              />
              <Dropdown
                label="App"
                name="applicationTypeId"
                placeholder="App"
                menuItems={menuItemAppTypes}
                formik={formik}
                onChange={() => {
                  if (formInitialValues.statusId === TENANT_STATUS.SUBMITTED)
                    clearClientInformation(formik);
                  formik.values.baseURL = undefined
                }}
                forceShowError={formik.submitCount > 0}
                required
                disabled={
                  formInitialValues.statusId === TENANT_STATUS.LIVE ||
                  formInitialValues.statusId === TENANT_STATUS.DEACTIVATED ||
                  formType === "View"
                }
              />
              {(formType === "Edit" || formType === "View") &&
                formInitialValues.statusId !== TENANT_STATUS.SAVED && (
                  <>
                    <Input
                      name="baseURL"
                      label={hospitalForms_LABELS_LITERALS.r4Endpoint}
                      onChange={formik.handleChange}
                      maxLength={100}
                      value={formik.values.baseURL}
                      disabled={
                        (formInitialValues.statusId === TENANT_STATUS.LIVE &&
                          !liveEdit) ||
                        formInitialValues.statusId ===
                          TENANT_STATUS.DEACTIVATED ||
                        formType === "View"
                      }
                      labelHelpText={
                        hospitalForms_LABELS_LITERALS.baseURLHelpText
                      }
                    />
                  </>
                )}
              {formInitialValues.statusId !== TENANT_STATUS.SAVED &&
                formInitialValues.tenantApplicationInformationId && (
                  <div className="pb-5">
                    <span
                      className="navigate-link "
                      onClick={() => {
                        setIsUrlModalOpen(true);
                      }}
                    >
                      View Redirect & Launch URL
                    </span>
                  </div>
                )}
              <Modal
                headerBg="var(--content-bg)"
                headerColor="var(--primary-color)"
                modalHeaderStyle={{
                  borderBottom: "1px solid var(--primary-color)",
                }}
                isOpen={isUrlModalOpen}
                title={"Redirect and Launch URLs"}
                modalWidth="780px"
                modalContentStyle={{
                  background: "var(--content-bg)",
                }}
                onClose={() => {
                  setIsUrlModalOpen(false);
                }}
              >
                <HospitalUrlsAuditGrid
                  tenantApplicationInformationId={
                    formInitialValues?.tenantApplicationInformationId
                  }
                />
              </Modal>
            </div>
            {formType !== "View" ? (
              <div className="form-buttons">
                {formType === "Add" ? (
                  <>
                    <button
                      className="left-form-button"
                      type="button"
                      onClick={() => {
                        if (formik.dirty && formik.isValid) {
                          updateStatus(
                            formik,
                            TENANT_STATUS.SAVED,
                            hospitalStatus.create
                          );
                        } else {
                          formik.handleSubmit();
                        }
                      }}
                    >
                      {hospitalForms_LABELS_LITERALS.save}
                    </button>
                    <button
                      className="right-form-button"
                      type="button"
                      onClick={() => {
                        if (formik.dirty && formik.isValid) {
                          updateStatus(
                            formik,
                            TENANT_STATUS.INITIATED,
                            hospitalStatus.invite
                          );
                        } else {
                          formik.handleSubmit();
                        }
                      }}
                    >
                      {hospitalForms_LABELS_LITERALS.invite}
                    </button>
                  </>
                ) : (
                  <>
                    {formInitialValues.statusId === TENANT_STATUS.SUBMITTED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.RE_INITIATED,
                              hospitalStatus.reInitiate
                            );
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.reInitiate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.dirty}
                          onClick={() => {
                            updateStatus(formik, null, hospitalStatus.save);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.save}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.values?.baseURL}
                          onClick={() => {
                              updateStatus(
                              formik,
                              TENANT_STATUS.LIVE,
                              hospitalStatus.approve);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.approve}
                        </button>
                      </>
                    ) : formInitialValues.statusId === TENANT_STATUS.EXPIRED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.RE_INITIATED,
                              hospitalStatus.reInitiate);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.reInitiate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.dirty}
                          onClick={() => {
                            updateStatus(formik, null, hospitalStatus.save);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.save}
                        </button>
                      </>
                    ) : formInitialValues.statusId ===
                      TENANT_STATUS.RE_INITIATED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.RE_INITIATED,
                              hospitalStatus.reInitiate);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.reInitiate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.dirty}
                          onClick={() => {
                            updateStatus(formik, null, hospitalStatus.save);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.save}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"      
                          disabled={!formik.values.baseURL}                  
                          onClick={() => {
                              updateStatus(
                              formik,
                              TENANT_STATUS.LIVE,
                              hospitalStatus.approve);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.approve}
                        </button>
                      </>
                    ) : formInitialValues.statusId ===
                      TENANT_STATUS.INITIATED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.RE_INITIATED,
                              hospitalStatus.reInitiate);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.reInitiate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.dirty}
                          onClick={() => {
                            updateStatus(formik, null, hospitalStatus.save);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.save}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          disabled={!formik.values.baseURL}
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.LIVE,
                              hospitalStatus.approve);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.approve}
                        </button>
                      </>
                    ) : formInitialValues.statusId === TENANT_STATUS.SAVED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          disabled={!formik.dirty}
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.SAVED,
                              hospitalStatus.save);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.save}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.INITIATED,
                              hospitalStatus.invite);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.invite}
                        </button>
                      </>
                    ) : formInitialValues.statusId ===
                      TENANT_STATUS.DEACTIVATED ? (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.SUBMITTED,
                              hospitalStatus.activate
                            );
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.activate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.RE_INITIATED,
                              hospitalStatus.reInitiate);
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.reInitiate}
                        </button>
                      </>
                    ) : (
                      <></>
                    )}
                    {formInitialValues.statusId === TENANT_STATUS.LIVE && (
                      <>
                        <button
                          className="left-form-button"
                          type="button"
                          onClick={() => {
                            updateStatus(
                              formik,
                              TENANT_STATUS.DEACTIVATED,
                              hospitalStatus.deactivate
                            );
                          }}
                        >
                          {hospitalForms_LABELS_LITERALS.deactivate}
                        </button>
                        <button
                          className="right-form-button"
                          type="button"
                          onClick={() => {
                            setLiveEdit(true);
                            setLiveLabel(hospitalForms_LABELS_LITERALS.save);
                            if (
                              liveLabel !== hospitalForms_LABELS_LITERALS.edit
                            ) {
                              updateStatus(formik, null, liveLabel);
                            }
                          }}
                        >
                          {liveLabel}
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
            ) : (
              <div className="form-buttons d-flex justify-content-end">
                <button
                  className="right-form-button"
                  type="button"
                  onClick={() => {
                    setFormType("Edit");
                  }}
                >
                  {hospitalForms_LABELS_LITERALS.edit}
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
      <ConfirmationDialog
        open={openConfirmation}
        message={
          <div className="hospital-form-confirmation-modal">
            <div>
              {hospitalForms_LABELS_LITERALS.given}{" "}
              <span className="fw-bold">
                {hospitalForms_LABELS_LITERALS.npis}
                {updateHospital?.data?.data?.matchedNpis}{" "}
              </span>
              {hospitalForms_LABELS_LITERALS.match}{" "}
              <span className="fw-bold">
                {updateHospital?.data?.data?.matchedHospitalName}.
              </span>
            </div>
            <div>{hospitalForms_LABELS_LITERALS.linkHospital}</div>
          </div>
        }
        cancelOnClose={false}
        confirmButtonName="Yes"
        cancelButtonName="No"
        onConfirm={() => {
          handleHospitalName(updateHospital?.data?.data?.matchedHospitalName);
          setOpenConfirmation(false);
        }}
        onCancel={handleCancelToggle}
      />
      <ConfirmationDialog
        open={confirmHospitalName}
        cancelOnClose={false}
        message={
          <div>
            {`${hospitalForms_LABELS_LITERALS.given} ${hospitalForms_LABELS_LITERALS.hospitalName}`}
            <span className="fw-bold">
              {updateHospital?.originalArgs?.hospitalName}{" "}
            </span>
            {hospitalForms_LABELS_LITERALS.differentHospitalName}
            <div>{hospitalForms_LABELS_LITERALS.updateHospitalName}</div>
          </div>
        }
        confirmButtonName="Yes"
        cancelButtonName="No"
        onConfirm={() => {
          setUpdateHospital({
            ...updateHospital.originalArgs,
            matchedTenantInformationId:
              updateHospital?.data?.data?.matchedTenantInformationId,
          }).then((res) => {
            if (res?.data?.isSuccess) {
              if (res?.data?.data?.matchedNpis === null)
                snackbarWrapper(hospitalToasts.Approve, "success");
            }
          });
          setConfirmHospitalName(false);
        }}
        onCancel={() => {
          setUpdateHospital({
            ...updateHospital.originalArgs,
            matchedTenantInformationId:
              updateHospital?.data?.data?.matchedTenantInformationId,
            hospitalName: hospitalName,
          }).then((res) => {
            if (res?.data?.isSuccess) {
              if (res?.data?.data?.matchedNpis === null)
                snackbarWrapper(hospitalToasts.Approve, "success");
            }
          });
          setConfirmHospitalName(false);
        }}
      />
    </div>
  );
};

export default HospitalForm;
