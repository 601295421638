import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const monitoringApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    applicationLogs: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_MONITORING_APPLICATION_LOGS}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(requestBody),
      }),
    }),
    applicationMetrics: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_MONITORING_METRICS}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(requestBody),
      }),
    }),
    tenantNames: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_TENANT_NAMES}`,
        method: "GET",
      }),
    }),
    applicationMetricsLogs: builder.query({
      query: (requestBody) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_RESOURCE_METRICS_LOGS}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
        body: JSON.stringify(requestBody),
      }),
    }),
  }),
});

export const {
  useApplicationLogsQuery,
  useLazyApplicationLogsQuery,
  useLazyTenantNamesQuery,
  useTenantNamesQuery,
  useLazyApplicationMetricsQuery,
  useLazyApplicationMetricsLogsQuery,
} = monitoringApi;
