import { API_CONSTANTS } from "../../constants/apiConstants";
import { UserService } from "../UserService";
import baseApi from "./baseApiService";
export const authenticationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.query({
      query: (loginCredential) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.LOGIN}`,
        method: "POST",
        body: JSON.stringify(loginCredential),
        headers: {
          "Content-Type": "application/json",
        },
      }),
      keepUnusedDataFor: 0,
    }),
    validateOtp: builder.query({
      query: (otpPayload) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.VALIDATE_OTP}`,
        method: "POST",
        body: JSON.stringify(otpPayload),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.loginCode}`,
        },
      }),
    }),
    resendOtp: builder.query({
      query: (email) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.RESEND_OTP}`,
        method: "POST",
        body: JSON.stringify(email),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${UserService.loginCode}`,
        },
      }),
    }),
    updatePassword: builder.query({
      query: (updatedPassword) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.UPDATE_PASSWORD}`,
        method: "PUT",
        body: JSON.stringify(updatedPassword),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    userRoles: builder.query({
      query: () => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.GET_USER_ROLES}`,
        method: "GET",
      }),
    }),
    createUser: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.REGISTER_USER}`,
        method: "POST",
        body: user,
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    resetPassword: builder.query({
      query: (resetPasswordRequest) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.RESET_PASSWORD}`,
        method: "PUT",
        body: resetPasswordRequest,
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    updateUser: builder.query({
      query: (user) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.UPDATE_USER}`,
        method: "PUT",
        body: user,
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getUserEmailByUserId: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.GET_USER_EMAIL_BY_USER_ID}${userId}`,
        method: "GET",
        headers: {
          "Content-Type": "*/*",
          Accept: "*/*",
        },
      }),
    }),

    updateUserPassword: builder.query({
      query: (updateUserPassword) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.UPDATE_USER_PASSWORD}`,
        method: "PUT",
        body: updateUserPassword,
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    signOut: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.SIGN_OUT}`,
        method: "POST",
        body: userId,
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const {
  useLazyUpdatePasswordQuery,
  useLazyCreateUserQuery,
  useUserRolesQuery,
  useLazyLoginQuery,
  useLazyValidateOtpQuery,
  useLazyResendOtpQuery,
  useLazyUpdateUserQuery,
  useLazyResetPasswordQuery,
  useGetUserEmailByUserIdQuery,
  useUpdateUserPasswordQuery,
  useLazyUpdateUserPasswordQuery,
  useLazySignOutQuery,
} = authenticationApi;
