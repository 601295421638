import { useEffect, useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { regex } from "../../constants/regex";
import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Input from "../shared/Input/Input";
import { useLazyLoginQuery } from "../../services/rtkQueryServices/authenticationService";
import { routePaths } from "../../constants/routePaths";
import { useDispatch } from "react-redux";
import { loginCredentials } from "../../redux/slices/emailSlice";
import { UserService } from "../../services/UserService";
import { validationMessages } from "../../constants/forms/validationMessages";
import { ErrorMessage, LOGIN_LITERALS } from "../../constants/literals";
import { Utility } from "../../utils/Utility";
export default function EmailLogin(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const initialFormValue = useMemo<any>(() => {
    return {
      email: "",
      password: "",
    };
  }, []);
  const [requestLogin] = useLazyLoginQuery();
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [loginErrorMessage, setLoginErrorMessage] = useState<string>("");
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [attemptsLeft, setAttemptsLeft] = useState<number>();
  const [isAccountLocked, setIsAccountLocked] = useState<boolean>();
  const [timer, setTimer] = useState("00:00");
  const formInitialValues = initialFormValue;
  const validationSchema = () =>
    Yup.object({
      email: Yup.string()
        .required(validationMessages.emailRequired)
        .matches(regex.emailValidation, validationMessages.validEmail),
      password: Yup.string().required(validationMessages.passwordRequired),
    });
  const handleSubmit = (values: any) => {
    requestLogin(values)
      .then((res: any) => {
        setAttemptsLeft(res?.data?.data?.attemptsLeft);
        if (res?.data?.isSuccess) {
          UserService.loginCode = res?.data?.data?.tokenResponse?.token;
          navigate(routePaths.otp);
          setLoginErrorMessage("");
        } else if (
          res?.data?.data?.isAccountLocked === false &&
          res?.data?.data?.attemptsLeft !== null
        ) {
          setLoginErrorMessage(
            ErrorMessage.ErrorInvalidUser + res?.data?.data?.attemptsLeft
          );
        } else if (res?.data?.data?.isAccountLocked) {
          setIsAccountLocked(true);
          setSecondsLeft(res?.data?.data?.timeLeft);
          setLoginErrorMessage(ErrorMessage.ErrorAccountLocked);
        } else {
          setErrorMessage((res.error as any).data.message);
        }
      })
      .catch((e) => {
        setErrorMessage("Something went wrong");
      });
  };
  const handleChange = () => {
    setErrorMessage("");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (secondsLeft > 0) {
        setSecondsLeft(secondsLeft - 1);
        let { totalSecs, hours, minutes, seconds } = Utility.getTimeRemaining(
          secondsLeft - 1
        );
        if (totalSecs >= 0) {
          setTimer(
            (minutes > 9 ? minutes : "0" + minutes) +
              ":" +
              (seconds > 9 ? seconds : "0" + seconds)
          );
        }
      }
      if (secondsLeft <= 0 && isAccountLocked) {
        setIsAccountLocked(false);
        setLoginErrorMessage("");
        setTimer("00:00");
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [secondsLeft]);

  return (
    <>
      {props?.informationMessage && (
        <span className="error-information p-2 my-3">
          {props?.informationMessage}
        </span>
      )}
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any) => {
          handleSubmit(values);
          dispatch(loginCredentials(values));
        }}
      >
        {(formik) => (
          <Form
            onChange={handleChange}
            onSubmit={formik.handleSubmit}
            className="login-form-container"
          >
            <div className="login-form">
              <div className="login-header mb-4">{LOGIN_LITERALS.welcome}</div>
              <Input
                name="email"
                placeholder="Email Address"
                errorMsg={
                  formik.touched.email &&
                  formik.errors.email &&
                  (formik.errors as any).email
                }
                onInput={formik.handleChange}
                value={formik.values.email}
                required
              />
              <Input
                name="password"
                placeholder="Password"
                type="password"
                onInput={formik.handleChange}
                errorMsg={
                  formik.touched.password &&
                  formik.errors.password &&
                  (formik.errors as any).password
                }
                value={formik.values.password}
                required
              />
            </div>
            <div className="login-actions">
              <Button
                className={
                  !formik.isValid || secondsLeft > 0
                    ? "form-button disabled"
                    : "form-button"
                }
                type="submit"
                disabled={!formik.isValid || secondsLeft > 0}
              >
                {LOGIN_LITERALS.getOtp}
              </Button>
            </div>
            {errorMessage && (
              <>
                <div className="login-error-wrapper p-4 mt-4">
                  <span className="error-message">{errorMessage} </span>
                </div>
              </>
            )}
            {loginErrorMessage && (
              <>
                <div className="login-error-wrapper p-4 mt-4">
                  <span className="error-message">{loginErrorMessage} </span>
                  <span className="error-message">
                    {isAccountLocked && secondsLeft > 0 && timer}
                  </span>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
}
