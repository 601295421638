import React, {
  FormEvent,
  MouseEvent,
  KeyboardEvent,
  FocusEvent,
  ReactNode,
  useState,
  useEffect,
  useRef,
} from "react";
import "./input.scss";
import { Field } from "formik";
import Info from "../SVG/Info";
interface InputProps {
  showClear?: boolean;
  prefix?: ReactNode;
  suffix?: ReactNode;
  value?: string;
  disabled?: boolean;
  type?: "text" | "email" | "password" | "url" | "number";
  min?: number;
  max?: number;
  minLength?: number;
  maxLength?: number;
  label?: string;
  name?: string;
  showHelpTextIcon?: boolean;
  isFormElement?: boolean;
  labelHelpText?: string;
  required?: boolean;
  placeholder?: string;
  errorMsg?: string;
  matchRegex?: {
    regExp: string | RegExp | "";
    errorMsg: string;
  };
  readonly?: boolean;
  onClick?: (e: MouseEvent) => void;
  onBlur?: (e: FocusEvent) => void;
  onFocus?: (e: FocusEvent) => void;
  onKeyUp?: (e: KeyboardEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
  onMouseDown?: (e: MouseEvent) => void;
  onMouseEnter?: (e: MouseEvent) => void;
  onMouseLeave?: (e: MouseEvent) => void;
  onMouseMove?: (e: MouseEvent) => void;
  onMouseOver?: (e: MouseEvent) => void;
  onMouseOut?: (e: MouseEvent) => void;
  onInput?: (e: FormEvent) => void;
  onClear?: () => void;
  onChange?: (e: any) => void;
  mask?: string;
}

const Input: React.FC<InputProps> = ({
  onClear,
  showClear,
  onChange,
  prefix,
  suffix,
  label,
  required,
  disabled,
  readonly,
  errorMsg,
  isFormElement = true,
  labelHelpText,
  ...props
}) => {
  const [isPassword, setIsPassword] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [passwordType, setPasswordType] = useState(isPassword && "password");
  const [type, setType] = useState(props.type || "text");
  const inputRef = useRef(null);
  useEffect(() => {
    if (type === "password") {
      setIsPassword(true);
    }
  });
  function showHidePassword(type: "text" | "password"): void {
    setType(type);
    setIsShowPassword(!isShowPassword);
  }
  return (
    <div className="input-group">
      {label && (
        <label className="align-items-center d-flex">
          {label}
          {required && <span className="required">*</span>}
          {labelHelpText && (
            <span className="help-icon ">
              <Info />
              <div className="help-info">{labelHelpText}</div>
            </span>
          )}
        </label>
      )}
      <div
        className={`input-control ${disabled && "input-disabled"} ${
          readonly && "input-readonly "
        } ${errorMsg && "error"}`}
      >
        {prefix && <span className="affix prefix">{prefix}</span>}
        {isFormElement ? (
          <Field
            {...props}
            onChange={onChange}
            type={type}
            disabled={disabled}
            value={props?.value ? props?.value : ""}
          ></Field>
        ) : (
          <input
            {...props}
            type={type}
            ref={inputRef}
            onChange={onChange}
            disabled={disabled}
            value={props?.value ? props?.value : ""}
          ></input>
        )}

        {showClear && (
          <button
            disabled={disabled}
            onClick={(e) => {
              onClear();
            }}
            className={`affix affix-clear ${disabled && "disabled"}`}
            title="clear"
            type="button"
          >
            <svg
              height="329pt"
              viewBox="0 0 329.26933 329"
              width="329pt"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="m194.800781 164.769531 128.210938-128.214843c8.34375-8.339844 8.34375-21.824219 0-30.164063-8.339844-8.339844-21.824219-8.339844-30.164063 0l-128.214844 128.214844-128.210937-128.214844c-8.34375-8.339844-21.824219-8.339844-30.164063 0-8.34375 8.339844-8.34375 21.824219 0 30.164063l128.210938 128.214843-128.210938 128.214844c-8.34375 8.339844-8.34375 21.824219 0 30.164063 4.15625 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921875-2.089844 15.082031-6.25l128.210937-128.214844 128.214844 128.214844c4.160156 4.160156 9.621094 6.25 15.082032 6.25 5.460937 0 10.921874-2.089844 15.082031-6.25 8.34375-8.339844 8.34375-21.824219 0-30.164063zm0 0" />
            </svg>
          </button>
        )}
        {isPassword && !isShowPassword && (
          <div
            onClick={() => {
              showHidePassword("text");
            }}
            className="affix"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 469.333 469.333"
              xmlSpace="preserve"
            >
              <path d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z" />
              <path
                d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160
            c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333
            c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667
            S293.547,341.333,234.667,341.333z"
              />
            </svg>
          </div>
        )}
        {isPassword && isShowPassword && (
          <div onClick={() => showHidePassword("password")} className="affix">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 469.333 469.333"
              xmlSpace="preserve"
            >
              <path
                d="M234.667,170.667c-35.307,0-64,28.693-64,64s28.693,64,64,64s64-28.693,64-64S269.973,170.667,234.667,170.667z"
                fill="#13ada4"
              />
              <path
                d="M234.667,74.667C128,74.667,36.907,141.013,0,234.667c36.907,93.653,128,160,234.667,160
            c106.773,0,197.76-66.347,234.667-160C432.427,141.013,341.44,74.667,234.667,74.667z M234.667,341.333
            c-58.88,0-106.667-47.787-106.667-106.667S175.787,128,234.667,128s106.667,47.787,106.667,106.667
            S293.547,341.333,234.667,341.333z"
                fill="#13ada4"
              />
            </svg>
          </div>
        )}
        {suffix && <span className="affix suffix">{suffix}</span>}
      </div>
      {errorMsg && (
        <div className="error-message">
          <span className="custom-error-message">{errorMsg}</span>
        </div>
      )}
    </div>
  );
};
export default Input;
