export const HospitalIntegrationGird_LABELS = {
  edit: "Edit",
  view: "View",
  audit: "Audit",
  addHospital: "Add Hospital",
  add: "Add",
  hospitalIntegrationList: "Hospital Integration List",
  hospital: "Hospital",
  npis: "NPI’s",
  emailAddress: "Email Address",
  contactFirstName: "Contact First Name",
  contactLastName: "Contact Last Name",
  userRole: "User Role",
  status: "Status",
  EHRType: "EHR Type",
  appType: "App Type",
  actions: "Actions",
  hospitals: "Hospitals",
};
