import { useEffect, useState } from "react";
import "./HospitalAuditTracker";
import Grid from "../../shared/Grid/Grid";
import { hospitalAuditTracker_LABELS } from "../../../constants/labels/Hospital-Integration/HospitalAuditTracker";
import { useGetAuditsByTenantIdQuery } from "../../../services/rtkQueryServices/HospitalApi";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";

function HospitalAuditTracker(Props: any) {
  const { data, searchString } = Props;
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    searchString: "",
    tenantApplicationInformationId: data?.tenantApplicationInformationId,
  });

  const columnDefs: GridColumnDefs[] = [
    {
      headerName: hospitalAuditTracker_LABELS.modifiedBy,
      field: "modifiedBy",
    },
    {
      headerName: hospitalAuditTracker_LABELS.modifiedOn,
      field: "modifiedOn",
      cellRenderer: UtcDateTime,
    },
    {
      headerName: hospitalAuditTracker_LABELS.fieldName,
      field: "fieldName",
    },
    {
      headerName: hospitalAuditTracker_LABELS.action,
      field: "auditType",
    },
    {
      headerName: hospitalAuditTracker_LABELS.newValue,
      field: "newValue",
    },
    {
      headerName: hospitalAuditTracker_LABELS.oldValue,
      field: "oldValue",
    },
  ];

  const {
    data: hospitalAuditRowData,
    refetch,
    isError,
  } = useGetAuditsByTenantIdQuery(filterCriteria);
  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
      tenantApplicationInformationId:
        Props.data?.tenantApplicationInformationId,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      pageNumber: 1,
      pageSize: value,
      tenantApplicationInformationId:
        Props.data?.tenantApplicationInformationId,
    });
  };

  useEffect(() => {
    refetch();
  }, [filterCriteria, data?.tenantApplicationInformationId]);

  useEffect(() => {
    setFilterCriteria({
      ...filterCriteria,
      searchString: searchString,
    });
  }, [searchString]);

  const [rowData, setRowData] = useState([]);
  const [rowcount, setRowCount] = useState(0);

  useEffect(() => {
    if (isError) {
      setRowData([]);
      setRowCount(0);
    } else {
      setRowData(
        hospitalAuditRowData?.data?.listOfAudits
          ? hospitalAuditRowData?.data?.listOfAudits
          : []
      );
      hospitalAuditRowData?.data?.listOfAudits &&
        setRowCount(hospitalAuditRowData?.data?.totalCount);
    }
  }, [hospitalAuditRowData, isError]);

  return (
    <div className="hospital-audit-wrapper pt-3">
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        serverSidePagination
        rowCount={rowcount}
        serverSideSorting
        showPagination
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        masterDetail
        gridType="audit"
        showTotalRowCount
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        uniqueIdentifierFieldName={"id"}
      ></Grid>
    </div>
  );
}

export default HospitalAuditTracker;
