import React, { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useEhrTypeQuery } from "../../services/rtkQueryServices/masterDataApi";
import RegistrationData from "../../constants/data/RegistrationData";
import { Utility } from "../../utils/Utility";

function RegistrationInformation() {
  const { ehrTypeId } = useParams();
  const { data: ehrTypeData } = useEhrTypeQuery({});
  const navigate = useNavigate();
  const ehrType = useMemo(() => {
    const ehr = ehrTypeData?.data?.find((value: any) => {
      return value.id == ehrTypeId;
    });
    if (!ehr) {
      navigate("/error");
    }
    return Utility.convertToPascalCase(ehr?.name);
  }, [ehrTypeData, ehrTypeId]);
  const registrationSteps = RegistrationData[`${ehrType}Provider`];
  return (
    <div>
      <h1 className="d-flex justify-content-center fw-bold p-5">
        Instructions for registering the SOF on {ehrType}
      </h1>
      <div>
        {registrationSteps?.map((registrationStep: any, index: number) => (
          <div
            className="align-items-center d-flex flex-column justify-content-center m-5"
            key={index}
          >
            <div className="mb-2">
              <span className="fw-bold">Step {index + 1} -</span>
              <span>{registrationStep.content}</span>
            </div>
            {registrationStep.image && (
              <img
                style={{ maxHeight: "800px", maxWidth: "800px" }}
                src={registrationStep.image}
              ></img>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default RegistrationInformation;
