import "./SideNav.scss";
import { ReactComponent as Dashboard } from "../../../.././assets/svg/dashboard.svg";
import { ReactComponent as UserManagement } from "../../../.././assets/svg/user_management.svg";
import { ReactComponent as HospitalIntegration } from "../../../.././assets/svg/hospital_integration.svg";
import { ReactComponent as Monitoring } from "../../../.././assets/svg/Laptop.svg";
import { ReactComponent as Logs } from "../../../.././assets/svg/equalizer.svg";
import { ReactComponent as Metrics } from "../../../.././assets/svg/insights.svg";
import { ReactComponent as Settings } from "../../../.././assets/svg/settings.svg";
import { ReactComponent as Mail } from "../../../.././assets/svg/mail_rounded.svg";
import { ReactComponent as Notifications } from "../../../.././assets/svg/level-notifications.svg";
import { ReactComponent as Delete } from "../../../.././assets/svg/delete.svg";
import Navigation from "../Navigation/Navigation";
import { routePaths } from "../../../../constants/routePaths";
export interface sideNavProps {
  setIsSideNavOpen: any;
  isSideNavOpen: boolean;
}
function SideNav(props: sideNavProps) {
  let routes = [
    {
      text: "Dashboard",
      icon: Dashboard,
      link: routePaths.dashboard,
    },
    {
      text: "User Management",
      icon: UserManagement,
      link: routePaths.userManagement,
    },
    {
      text: "Hospital Integration",
      icon: HospitalIntegration,
      link: routePaths.hospitalManagement,
    },
    {
      text: "Application Monitoring",
      icon: Monitoring,
      link: routePaths.monitoring,
      subMenu: [
        {
          text: "Logs",
          Icon: Logs,
          link: `${routePaths.monitoring}/${routePaths.applicationLogs}`,
        },
        {
          text: "Metrics",
          Icon: Metrics,
          link: `${routePaths.monitoring}/${routePaths.applicationMetrics}`,
        },
      ],
    },
    {
      text: "Delete Patient",
      icon: Delete,
      link: routePaths.deletePatient,
    },
    {
      text: "Notifications",
      icon: Notifications,
      link: routePaths.notifications,
    },
    {
      text: "Settings",
      icon: Settings,
      link: routePaths.settings,
      subMenu: [
        {
          text: "Email Template Configuration",
          Icon: Mail,
          link: `${routePaths.settings}/${routePaths.emailTemplate}`,
        },
      ],
    },
  ];
  const navigation = routes.map((item, index) => (
    <li className="mb-4" key={item.text}>
      <Navigation {...props} navItem={item} />
    </li>
  ));
  return (
    <>
      <div className="side-nav-wrapper">
        <ul className="list-unstyled mb-0">{navigation}</ul>
      </div>
      <div className="d-flex flex-column side-nav-footer-wrapper align-items-center"></div>
    </>
  );
}

export default SideNav;
