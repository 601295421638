import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import FormControl from "@mui/material/FormControl";
import { ReactComponent as LeftCoolicon } from "../../../assets/svg/coolicon_left.svg";
import { ReactComponent as RightCoolicon } from "../../../assets/svg/coolicon_right.svg";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";

import { ChangeEvent, useEffect, useMemo, useState } from "react";

function PaginationContent(props: any) {
  const [searchData, setSearchData] = useState<any[]>([]);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(20);
  let rowCount = useMemo(
    () => props?.rowCount || props?.rowData?.length,
    [props]
  );

  useEffect(() => {
    if (props.onPageChange) props?.onPageChange(page);
  }, [page]);

  useEffect(() => {
    if (props.onPageSizeChange) props?.onPageSizeChange(pageSize);
  }, [pageSize]);

  return (
    <div className="MUI-grid-pagination">
      <div className="grid-pagination-page-sizes align-items-center d-flex">
        <label>Page Size</label>
        <FormControl sx={{ m: 1, minWidth: 120 }}>
          <Select
            value={pageSize?.toString()}
            onChange={(event: SelectChangeEvent) => {
              setPageSize(parseInt(event.target.value));
              setPage(1);
            }}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            IconComponent={() => <DropdownIcon />}
          >
            {props?.pageSizeList?.map((item: any, index: any) => (
              <MenuItem key={index} value={item?.toString()}>
                {item?.toString()}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {props?.showTotalRowCount && (
          <span className="grid-row-count">
            <span>Total Records : </span>
            <span className="grid-total-row-count">
              {searchData.length > 0 && props.enableDefaultSearch
                ? searchData.length
                : rowCount}
            </span>
          </span>
        )}
      </div>
      <div className="grid-pagination-section justify-content-end">
        <button
          onClick={() => {
            if (page > 1) setPage(page - 1);
          }}
          className={
            " prev grid-pagination-prev-button " +
            (page === 1 ? "disabled" : "")
          }
        >
          {/* <SVG src={Arrow}></SVG> */}
          <LeftCoolicon />
          <span>Prev</span>
        </button>
        <Pagination
          count={
            searchData.length > 0 && props.enableDefaultSearch
              ? Math.ceil(searchData.length / pageSize)
              : Math.ceil(rowCount / pageSize) || 0
          }
          onChange={(event: ChangeEvent<unknown>, page: number) => {
            setPage(page);
          }}
          size="medium"
          className="grid-pagination-number"
          page={page}
        />
        <button
          onClick={() => {
            if (
              page <
              (searchData.length > 0 && props.enableDefaultSearch
                ? Math.ceil(searchData.length / pageSize)
                : Math.ceil(rowCount / pageSize))
            )
              setPage(page + 1);
          }}
          className={
            "next grid-pagination-next-button " +
            (page >=
            (searchData.length > 0 && props.enableDefaultSearch
              ? Math.ceil(searchData.length / pageSize)
              : Math.ceil(rowCount / pageSize))
              ? "disabled"
              : "")
          }
        >
          <span>Next</span>
          <RightCoolicon />
        </button>
      </div>
    </div>
  );
}
export default PaginationContent;
