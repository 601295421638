import { useEffect, useMemo, useState } from "react";
import { Formik, Form } from "formik";
import Input from "../shared/Input/Input";
import "./UserForm.scss";
import * as Yup from "yup";
import Dropdown from "../HospitalForm/DropDown/Dropdown";
import {
  useLazyCreateUserQuery,
  useLazyUpdateUserQuery,
  useUserRolesQuery,
} from "../../services/rtkQueryServices/authenticationService";
import { UserService } from "../../services/UserService";
import { Roles } from "../../constants/data/Roles";
import { validationMessages } from "../../constants/forms/validationMessages";
import { useLazyToggleUserStatusQuery } from "../../services/rtkQueryServices/UserApi";
import { userManagement_LABELS } from "../../constants/labels/UserManagement/UserManagement";
import ConfirmationDialog from "../shared/ConfirmationDialog/ConfirmationDialog";
import { regex } from "../../constants/regex";
import { userToasts } from "../../constants/toasts/userToasts";
import { snackbarWrapper } from "../shared/SnackbarWrapper/SnackbarWrapper";

const UserForm = (props: any) => {
  const [openConfirmation, setOpenConfirmation] = useState<number | null>(null);
  const [singleSuperAdmin, setSingleSuperAdmin] = useState<number | null>(null);
  const [singleSuperAdminMessage, setSingleSuperAdminMessage] =
    useState<string>();

  const [toggleUserStatus] = useLazyToggleUserStatusQuery();

  const handleToggle = (id: number) => {
    setOpenConfirmation(id);
  };

  const handleConfirmToggle = (data: any) => {
    const requestContent: any = {
      applicationUserId: data.applicationUserId,
    };
    if (data.role === userManagement_LABELS.superAdmin) {
      requestContent.roleName = userManagement_LABELS.superAdmin;
    }
    toggleUserStatus(requestContent).then((res: any) => {
      if (res.data?.isSuccess) {
        if (data.isActive === true) {
          snackbarWrapper(userToasts.DeactivateUser, "success");
        } else {
          snackbarWrapper(userToasts.ActivateUser, "success");
        }
        props.onClose();
      } else {
        setSingleSuperAdmin(requestContent.applicationUserId);
        setSingleSuperAdminMessage(res?.error?.data?.message);
      }
    });
    setOpenConfirmation(null);
  };

  const handleCancelToggle = () => {
    setOpenConfirmation(null);
  };
  const { formInitialValues, formType } = props;
  const roles = useUserRolesQuery(null);
  const [setRegisterUser, registerUser] = useLazyCreateUserQuery();
  const [setUpdateUser, updateUser] = useLazyUpdateUserQuery();
  useEffect(() => {
    if (registerUser.isSuccess) {
      props.onClose();
      snackbarWrapper(userToasts.CreateUser, "success");
    } else if (updateUser.isSuccess) {
      props.onClose();
      snackbarWrapper(userToasts.UpdateUser, "success");
    } else if (registerUser.isError) {
      snackbarWrapper((registerUser.error as any)?.data?.message, "error");
    } else if (updateUser.isError) {
      snackbarWrapper((updateUser.error as any)?.data?.message, "error");
    }
  }, [registerUser, updateUser]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required(validationMessages.firstNameRequired)
      .matches(regex.nameValidation, validationMessages.firstNameRegexMatch)
      .max(64, validationMessages.firstNameCharacterLimit),
    lastName: Yup.string()
      .required(validationMessages.lastNameRequired)
      .matches(regex.nameValidation, validationMessages.lastNameRegexMatch)
      .max(64, validationMessages.lastNameCharacterLimit),
    emailAddress: Yup.string()
      .required(validationMessages.emailRequired)
      .matches(regex.emailValidation, validationMessages.validEmail),
    contactNumber: Yup.string().required(
      validationMessages.contactNumberRequired
    ),
  });

  const allRolesMenutItems = useMemo(() => {
    return roles?.data?.data.map((item: any) => {
      return { value: item?.roleId, displayValue: item?.roleName };
    });
  }, [roles]);

  const supportUserRoleMenuItems = useMemo(() => {
    return (
      allRolesMenutItems && [
        allRolesMenutItems?.find(
          (item: any) => item?.displayValue?.toLowerCase() === Roles.supportUser
        ),
      ]
    );
  }, [allRolesMenutItems]);
  return (
    <Formik
      initialValues={formInitialValues}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        const formValue = { ...values };
        if (formType === "Add") {
          setRegisterUser(formValue);
        } else {
          setUpdateUser(formValue);
        }
      }}
    >
      {(formik) => (
        <Form onSubmit={formik.handleSubmit} className="user-form-container">
          <div className="add-user-form">
            <Input
              name="firstName"
              placeholder="First Name"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              errorMsg={
                (formik.touched.firstName || formik.submitCount > 0) &&
                formik.errors.firstName &&
                (formik.errors as any).firstName
              }
              required
              disabled={formType === "View"}
              maxLength={64}
              label="First Name"
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              errorMsg={
                (formik.touched.lastName || formik.submitCount > 0) &&
                formik.errors.lastName &&
                (formik.errors as any).lastName
              }
              required
              disabled={formType === "View"}
              maxLength={64}
              label="Last Name"
            />
            <Input
              name={"emailAddress"}
              placeholder="Email Address"
              onChange={formik.handleChange}
              value={formik.values.emailAddress}
              maxLength={128}
              errorMsg={
                (formik.touched.emailAddress || formik.submitCount > 0) &&
                formik.errors.emailAddress &&
                (formik.errors as any).emailAddress
              }
              required
              disabled={formType === "Edit" || formType === "View"}
              label="Email address"
            />
            <Input
              placeholder="Contact Number"
              name={"contactNumber"}
              onChange={formik.handleChange}
              value={formik.values.contactNumber}
              maxLength={28}
              required
              errorMsg={
                (formik.touched.contactNumber || formik.submitCount > 0) &&
                formik.errors.contactNumber &&
                (formik.errors as any).contactNumber
              }
              disabled={formType === "View"}
              label="Contact Number"
            />
            <Dropdown
              menuItems={
                formType === "Add" &&
                UserService.getClaims?.role?.toLowerCase() === Roles.supportUser
                  ? supportUserRoleMenuItems
                  : allRolesMenutItems
              }
              name={"roleId"}
              formik={formik}
              label="User Role"
              required
              disabled={
                (formType === "Edit" &&
                  (UserService.getClaims?.role?.toLowerCase() ===
                    Roles.supportUser ||
                    UserService?.getClaims?.email ===
                      formik?.values?.emailAddress)) ||
                formType === "View"
              }
              placeholder="User Role"
              forceShowError={formik.submitCount > 0}
            />
          </div>
          <div
            className={`form-buttons d-flex ${
              formType === "Add"
                ? "justify-content-end"
                : "justify-content-between"
            }`}
          >
            {formType === "Edit" && (
              <>
                <ConfirmationDialog
                  open={
                    openConfirmation ===
                    props.formInitialValues.applicationUserId
                  }
                  message={
                    props.formInitialValues.isActive
                      ? userManagement_LABELS.disableUserMessage
                      : userManagement_LABELS.activateUserMessage
                  }
                  onConfirm={() => handleConfirmToggle(props.formInitialValues)}
                  onCancel={handleCancelToggle}
                />
                <ConfirmationDialog
                  open={
                    singleSuperAdmin ===
                    props.formInitialValues.applicationUserId
                  }
                  message={singleSuperAdminMessage}
                  onOk={() => {
                    setSingleSuperAdmin(null);
                  }}
                />

                <button onClick={props.onClose} className="left-form-button">
                  Cancel
                </button>

                <button
                  className={`left-form-button ${
                    UserService?.getClaims?.email ===
                    formik?.values?.emailAddress
                      ? "invisible"
                      : "visible"
                  }`}
                  type="button"
                  onClick={() =>
                    handleToggle(props.formInitialValues.applicationUserId)
                  }
                >
                  {formInitialValues?.isActive ? "Deactivate" : "Activate"}
                </button>

                <button disabled={!formik.dirty} className="right-form-button">
                  Save
                </button>
              </>
            )}
            {formType === "Add" && (
              <>
                <button className="right-form-button">Create</button>
              </>
            )}
            {formType === "View" && (
              <div className="d-flex justify-content-end w-100">
                <button
                  className="right-form-button"
                  type="button"
                  onClick={() => {
                    props.setFormType("Edit");
                  }}
                >
                  Edit
                </button>
              </div>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UserForm;
