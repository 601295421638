export const validationMessages = {
  emailRequired: "Email is required",
  passwordRequired: "Password is required",
  otpRequired: "OTP is required",
  otpLength: "Must be 6 characters",
  passwordMaxLength: "Password cannot exceed more than 64 characters",
  passwordLength:
    "Password is too short. Must contains minimum of 8 characters",
  passwordRegexMatch:
    "Password must contain an uppercase letter, special character and number",
  confirmPassword: "Passwords must match",
  firstNameRequired: "First name is required",
  lastNameRequired: "Last Name is required",
  genderRequired: "Gender is required",
  birthDateRequired: "Date Of Birth is required",
  validEmail: "Enter valid email address",
  contactNumberRequired: "Contact number is required",
  firstNameRegexMatch:
    "First name must only contain alphabets and white spaces",
  lastNameRegexMatch: "Last name must only contain alphabets and white spaces",
  firstNameCharacterLimit: "First name should not exceed 64 characters",
  lastNameCharacterLimit: "Last name should not exceed 64 characters",
  confirmDelete: "Confirm Patient Deletion",
  deleteConfirmation: "Are you sure you want to delete this patient's record?",
  noMatchFound: "No Patient Records found",
  moreThanOneMatchFound: "More than 1 Patient Record found.",
  selectEhrType: "Select EHR Type.",
  searchPatientRecord: "Identify Patient Record for Deletion",
  alphabetsAndSpaceValidation(fieldName: string) {
    return `${fieldName} must only contain alphabets and white spaces`;
  },
};
