import { Checkbox, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";
import "./MultiSelectDropdown.scss";

const MultiSelectDropdown = (props: any) => {
  const {
    placeholder,
    onChange,
    displayPropertyName,
    valuePropertyName,
    value,
    title,
  } = props;
  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (e.target.value?.length > 0) {
      if (value.indexOf("") !== -1) updatedValue.shift();
    } else {
      updatedValue = [""];
    }
    onChange && onChange(updatedValue);
  };
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const renderValue = (selectedValue: string[]) => {
    const displayValue = props?.searchFilterDropdownMenuList?.find(
      (obj: any) => obj[valuePropertyName] === selectedValue[0]
    );
    return selectedValue?.toString() && displayValue ? (
      `${displayValue[displayPropertyName]}${
        selectedValue.length > 1 ? ` +${selectedValue?.length - 1}` : ""
      }`
    ) : (
      <p className="placeholder">{placeholder}</p>
    );
  };

  return (
    <span
      title={title?.toString()}
      className="filter-container multi-select-dropdown"
    >
      <Select
        labelId="grid-search"
        multiple
        MenuProps={{
          style: { maxWidth: "170px", maxHeight: "250px" },
        }}
        value={value}
        onOpen={() => {
          setIsOpen(true);
        }}
        onClose={() => {
          setIsOpen(false);
        }}
        renderValue={(p) => renderValue(p)}
        open={isOpen}
        onChange={handleChange}
        IconComponent={DropdownIcon}
        style={{
          height: "35px",
          width: "175px",
          padding: "10px",
          background: "white",
        }}
      >
        <MenuItem className="multiselect" hidden value="">
          {props.searchFilterDropdownPlaceholder}
        </MenuItem>
        {props?.searchFilterDropdownMenuList?.map((val: any, index: number) => (
          <MenuItem
            className="multiselect"
            key={index}
            value={val[valuePropertyName]}
          >
            <Checkbox
              checked={
                value.findIndex((obj: any) => obj === val[valuePropertyName]) >
                -1
              }
              size="small"
            />
            <span title={val[displayPropertyName]} className={`select-value`}>
              {val[displayPropertyName]}
            </span>
          </MenuItem>
        ))}
      </Select>
    </span>
  );
};

export default MultiSelectDropdown;
