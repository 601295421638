import React, { useEffect } from "react";
import { useAuth } from "../../context/AuthProvider";
import { UserService } from "../../services/UserService";
import { useNavigate } from "react-router-dom";
import { routePaths } from "../../constants/routePaths";

function PageNotFoound() {
  return <h1 className="d-flex justify-content-center"> 404 Page Not Found</h1>;
}

export default PageNotFoound;
