import React from "react";
import { MONITORING_ACTIVITY } from "../../../enums/monitoringActivity";
import MetricsSubCards from "../MetricsSubCards.tsx/MetricsSubCards";
import "./MetricsModal.scss";
import { Utility } from "../../../utils/Utility";
import { Button } from "@mui/material";
import { Link, createSearchParams, useNavigate } from "react-router-dom";
import { ReactComponent as View } from "../../../assets/svg/View.svg";
import { routePaths } from "../../../constants/routePaths";

function MetricsModal(props: any) {
  const { tenantName, displayData } = props;
  const activityTypes = Object.keys(MONITORING_ACTIVITY)
    .filter((v) => !isNaN(Number(v)))
    ?.map((item) => Number(item))
    .reverse();

  const navigate = useNavigate();

  return (
    <div className="metrics-modal-container p-4">
      <div className="d-flex justify-content-around resource-activity-container row mx-0">
        {activityTypes?.map((activityType: any) => {
          let activity = Utility.convertToPascalCase(
            MONITORING_ACTIVITY[
              displayData?.applicationMonitoringActivity?.find((item: any) => {
                return item?.activityType === activityType;
              })?.activityType
            ]
          );
          return (
            <div className="align-items-center d-flex flex-column col-lg-6 px-5">
              <div className="activity-type d-flex justify-content-between py-3 w-100">
                <span>{activity} Activity</span>
              </div>
              <MetricsSubCards
                activeTab={activityType}
                activeTabContent={displayData?.applicationMonitoringActivity?.find(
                  (item: any) => {
                    return item?.activityType === activityType;
                  }
                )}
              />
              <Link
                className="navigate-link"
                to={`${routePaths.monitoring}/${
                  routePaths.applicationLogs
                }/${activity.toLowerCase()}?${"tenantId"}=${
                  displayData?.tenantInformationId
                }`}
              >
                Click here to view Logs
              </Link>
            </div>
          );
        })}
      </div>
      <div className="d-flex justify-content-end pb-3">
        <Button
          onClick={() => {
            navigate({
              pathname: routePaths.hospitalManagement,
              search: createSearchParams({
                tenantName: tenantName,
              })?.toString(),
            });
          }}
          className="button"
        >
          <span className="px-2">
            <View />
          </span>
          View Hospital Details
        </Button>
      </div>
    </div>
  );
}

export default MetricsModal;
