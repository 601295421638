import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    toggleUserStatus: builder.query({
      query: (toggleUserStatusDate) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.TOGGLE_USER_STATUS}`,
        method: "PATCH",
        body: JSON.stringify(toggleUserStatusDate),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getAuditsByUserId: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_AUDITS_BY_USER_ID}`,
        method: "POST",
        body: JSON.stringify(userId),
        headers: {
          "Content-Type": "application/json",
        },
      }),
      keepUnusedDataFor: 0,
    }),
    getUserByEmail: builder.query({
      query: (fetchByUserEmailRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.FETCH_USER_BY_EMAIL}`,
        method: "POST",
        body: JSON.stringify(fetchByUserEmailRequest),
        headers: {
          "Content-Type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const {
  useLazyToggleUserStatusQuery,
  useToggleUserStatusQuery,
  useGetAuditsByUserIdQuery,
  useLazyGetUserByEmailQuery,
} = userApi;
