export const hospitalStatus = {
  create: "Create",
  save: "Save",
  submit: "Submit",
  invite: "Invite",
  approve: "Approve",
  reInitiate: "ReInitiate",
  activate: "Activate",
  deactivate: "Deactivate",
};
