import { useEffect, useState } from "react";
import Grid from "../../shared/Grid/Grid";
import { hospitalAuditTracker_LABELS } from "../../../constants/labels/Hospital-Integration/HospitalAuditTracker";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import { Utility } from "../../../utils/Utility";
import { useGetAuditsByUserIdQuery } from "../../../services/rtkQueryServices/UserApi";

function UserAuditTracker(Props: any) {
  const DisplayDataInSystemDateFormat = (props: any) => {
    return (
      <>
        {Utility.getDateInParticularFormat(
          Utility.getSpecifiedDateTimeByAnyDateTime(
            props?.data?.modifiedOn
          )?.toString()
        )}
      </>
    );
  };

  const columnDefs: GridColumnDefs[] = [
    {
      headerName: hospitalAuditTracker_LABELS.modifiedBy,
      field: "modifiedBy",
    },
    {
      headerName: hospitalAuditTracker_LABELS.modifiedOn,
      field: "modifiedOn",
      cellRenderer: DisplayDataInSystemDateFormat,
    },
    {
      headerName: hospitalAuditTracker_LABELS.fieldName,
      field: "fieldName",
    },
    {
      headerName: hospitalAuditTracker_LABELS.newValue,
      field: "newValue",
    },
    {
      headerName: hospitalAuditTracker_LABELS.oldValue,
      field: "oldValue",
    },
    {
      headerName: hospitalAuditTracker_LABELS.action,
      field: "auditType",
    },
  ];

  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
    userId: Props.data?.userId,
  });

  const {
    data: userAuditRawData,
    refetch,
    isError,
  } = useGetAuditsByUserIdQuery(filterCriteria);

  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
      userId: Props.data?.userId,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      pageNumber: 1,
      pageSize: value,
      userId: Props.data?.userId,
    });
  };

  useEffect(() => {
    setFilterCriteria({
      ...filterCriteria,
      searchString: Props?.searchString,
    });
  }, [filterCriteria, Props]);

  const [rowData, setRowData] = useState([]);
  const [rowcount, setRowCount] = useState(0);

  useEffect(() => {
    if (isError) {
      setRowData([]);
      setRowCount(0);
    } else {
      setRowData(
        userAuditRawData?.data?.listOfAudits
          ? userAuditRawData?.data?.listOfAudits
          : []
      );
      userAuditRawData?.data?.listOfAudits &&
        setRowCount(userAuditRawData?.data?.totalCount);
    }
  }, [userAuditRawData, isError]);

  return (
    <div className="user-audit-wrapper pt-3">
      <Grid
        columnDefs={columnDefs}
        rowData={rowData}
        serverSidePagination
        rowCount={rowcount}
        serverSideSorting
        showPagination
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        masterDetail
        gridType="audit"
        showTotalRowCount
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        uniqueIdentifierFieldName={"id"}
      ></Grid>
    </div>
  );
}

export default UserAuditTracker;
