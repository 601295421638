import "./UserManagement.scss";
import { ReactNode, useEffect, useState } from "react";
import { ReactComponent as AddUser } from "../../assets/svg/add_user.svg";
import { ReactComponent as EditUser } from "../../assets/svg/edit.svg";
import { ReactComponent as Reset } from "../../assets/svg/reset.svg";
import { ReactComponent as Audit } from "../../assets/svg/audit.svg";
import Switch from "@mui/material/Switch";
import Grid from "../shared/Grid/Grid";
import Button from "@mui/material/Button";
import ConfirmationDialog from "../shared/ConfirmationDialog/ConfirmationDialog";
import Slider from "../shared/Slider/Slider";
import UserForm from "../UserForm/UserForm";
import { userManagement_LABELS } from "../../constants/labels/UserManagement/UserManagement";
import { useLazyToggleUserStatusQuery } from "../../services/rtkQueryServices/UserApi";
import { useGetAllUsersQuery } from "../../services/rtkQueryServices/userService";
import { useLazyResetPasswordQuery } from "../../services/rtkQueryServices/authenticationService";
import { UserService } from "../../services/UserService";
import { Roles } from "../../constants/data/Roles";
import { GridColumnDefs } from "../../models/Grid/gridColumnDefs";
import Modal from "../shared/Modal/Modal";
import { userToasts } from "../../constants/toasts/userToasts";
import { snackbarWrapper } from "../shared/SnackbarWrapper/SnackbarWrapper";
import UserAuditTracker from "./UserAuditTracker/UserAuditTracker";
function UserManagement() {
  const [openConfirmation, setOpenConfirmation] = useState<number | null>(null);
  const [singleSuperAdmin, setSingleSuperAdmin] = useState<number | null>(null);
  const [singleSuperAdminMessage, setSingleSuperAdminMessage] =
    useState<string>();
  const [filterCriteria, setFilterCriteria] = useState<any>({
    pageNumber: 1,
    pageSize: 20,
  });
  const { data: usersData, refetch } = useGetAllUsersQuery(filterCriteria);
  const [rowData, setRowData] = useState([]);
  const [rowcount, setRowCount] = useState(0);
  const [searchString, setSearchString] = useState<string>("");
  const [auditSearchString, setAuditSearchString] = useState<string>("");
  const [resetApplicationId, setResetApplicationId] = useState<number | null>(
    null
  );
  const [openAudit, setOpenAudit] = useState<string>();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    usersData && setRowData(usersData.data?.listOfUsers);
    usersData && setRowCount(usersData.data.totalCount);
  }, [usersData]);

  const [toggleUserStatus] = useLazyToggleUserStatusQuery();
  const [resetPassword] = useLazyResetPasswordQuery();

  const handleToggle = (id: number) => {
    setOpenConfirmation(id);
  };

  const handleConfirmToggle = (data: any) => {
    const requestContent: any = {
      applicationUserId: data.applicationUserId,
    };
    if (
      data.role.toUpperCase() === userManagement_LABELS.superAdmin.toUpperCase()
    ) {
      requestContent.roleName = userManagement_LABELS.superAdmin;
    }
    toggleUserStatus(requestContent).then((res: any) => {
      if (res.data?.isSuccess) {
        if (data.isActive === true) {
          snackbarWrapper(userToasts.DeactivateUser, "success");
        } else {
          snackbarWrapper(userToasts.ActivateUser, "success");
        }
        refetch();
        setRowData(usersData.data?.listOfUsers);
      } else {
        snackbarWrapper((res.error as any).data?.message, "error");
        setSingleSuperAdmin(requestContent.applicationUserId);
        setSingleSuperAdminMessage(res?.error?.data?.message);
      }
    });
    setOpenConfirmation(null);
  };

  const handleCancelToggle = () => {
    setOpenConfirmation(null);
  };
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [formType, setFormType] = useState<string>("");
  const [initialFormValue, setInitialFormValue] = useState<any>({});
  const toggleStatus = (value: any) => {
    return (
      <>
        <Switch
          disabled={
            value?.data?.role?.toLowerCase() === Roles.superUser &&
            UserService.getClaims?.role?.toLowerCase() === Roles.supportUser
          }
          className={`${
            value?.data?.role?.toLowerCase() === Roles.superUser &&
            UserService.getClaims?.role?.toLowerCase() === Roles.supportUser &&
            "action-disabled"
          }`}
          checked={value.data.isActive}
          onChange={() => handleToggle(value.data.applicationUserId)}
        />
        <ConfirmationDialog
          open={openConfirmation === value.data.applicationUserId}
          message={
            value.data.isActive
              ? userManagement_LABELS.disableUserMessage
              : userManagement_LABELS.activateUserMessage
          }
          onConfirm={() => handleConfirmToggle(value.data)}
          onCancel={handleCancelToggle}
        />
        <ConfirmationDialog
          open={singleSuperAdmin === value.data.applicationUserId}
          message={singleSuperAdminMessage}
          onOk={() => {
            setSingleSuperAdmin(null);
          }}
        />
      </>
    );
  };
  const UserActions = (props: any) => {
    return (
      <span
        className={`user-edit ${
          UserService.getClaims?.role?.toLowerCase() === Roles.supportUser &&
          "m-4"
        }`}
      >
        <EditUser
          onClick={() => {
            if (
              props?.data?.role?.toLowerCase() === Roles.superUser &&
              UserService.getClaims?.role?.toLowerCase() === Roles.supportUser
            )
              return;
            if (
              props?.data?.role?.isSuperUser?.toLowerCase() ===
                Roles.superUser &&
              UserService.getClaims?.role?.toLowerCase() === Roles.supportUser
            )
              return;
            setFormType("Edit");
            setInitialFormValue(props.data);
            setOpenSlider(true);
          }}
          title="Edit"
          className={`cursor-pointer ${
            ((props?.data?.role?.toLowerCase() === Roles.superUser &&
              UserService.getClaims?.role?.toLowerCase() ===
                Roles.supportUser) ||
              (props?.data?.role?.isSuperUser?.toLowerCase() ===
                Roles.superUser &&
                UserService.getClaims?.role?.toLowerCase() ===
                  Roles.supportUser)) &&
            "pe-none"
          } ${
            props?.data?.role?.toLowerCase() === Roles.superUser &&
            UserService.getClaims?.role?.toLowerCase() === Roles.supportUser
              ? "action-disabled "
              : " "
          }`}
        />
        {UserService.getClaims?.role?.toLowerCase() === Roles.superUser && (
          <>
            <Reset
              onClick={() => {
                if (props.data.isActive)
                  setResetApplicationId(props.data.applicationUserId);
              }}
              className={
                "ms-3 cursor-pointer " +
                (!props.data.isActive ? "disable-reset-Password" : "")
              }
              title={props.data.isActive ? "Reset Password" : ""}
            />
            <ConfirmationDialog
              open={resetApplicationId === props.data.applicationUserId}
              message={`Reset Password link will be sent to ${props.data.emailAddress}`}
              onConfirm={() => {
                let resetPasswordRequest = {
                  Email : props.data.emailAddress
                }
                resetPassword(
                  resetPasswordRequest
                ).then((res) => {
                  if (res?.data?.isSuccess) {
                    snackbarWrapper(userToasts.ResetPassword, "success");
                  } else if (res?.data?.isError) {
                    snackbarWrapper(userToasts.ResetPasswordFailed, "error");
                  }
                });
                setResetApplicationId(null);
              }}
              onCancel={() => setResetApplicationId(null)}
            />
          </>
        )}
        <Audit
          className="cursor-pointer ms-3"
          onClick={() => {
            setOpenAudit(props.data.userId);
            setOpen(true);
          }}
          title={userManagement_LABELS.audit}
        />
        <Modal
          isOpen={open && props.data.userId === openAudit}
          onClose={() => {
            setOpen(false);
            setAuditSearchString(null);
          }}
          title={"Audit Tracker | " + "User Management"}
          modalWidth="1820px"
          headerBg="#FFFFFF"
          headerColor="#13ada4"
          headerLogo={<Audit />}
          canSearch
          searchFieldName="Enter more than 3 characters"
          setSearchQuery={(value: string) => {
            setAuditSearchString(
              value?.trim().length > 0 ? value.trim() : null
            );
          }}
          modalHeaderBottomStyle={{
            borderBottom: "1px solid var(--primary-color)",
          }}
        >
          {open && props.data.userId === openAudit && (
            <UserAuditTracker
              data={props?.data}
              searchString={auditSearchString ? auditSearchString : ""}
            />
          )}
        </Modal>
      </span>
    );
  };

  const columnDefs: GridColumnDefs[] = [
    {
      headerName: userManagement_LABELS.firstName,
      field: "firstName",
    },
    {
      headerName: userManagement_LABELS.lastName,
      field: "lastName",
    },
    {
      headerName: userManagement_LABELS.emailAddress,
      field: "emailAddress",
    },
    {
      headerName: userManagement_LABELS.contactNumber,
      field: "contactNumber",
    },
    {
      headerName: userManagement_LABELS.userRole,
      field: "role",
      sortable: true,
    },
    {
      headerName: userManagement_LABELS.status,
      field: "isActive",
      sortable: true,
      cellRenderer: toggleStatus,
    },
    {
      field: userManagement_LABELS.actions,
      headerName: "Actions",
      cellRenderer: UserActions,
      sortable: false,
    },
  ];
  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  const searchResults = (value: any) => {
    setSearchString(value);
    setFilterCriteria({
      ...filterCriteria,
      searchString: value?.trim().length > 0 ? value.trim() : null,
    });
  };
  const sordOrder = (columnName: string, order: string) => {
    if (order === "asc" || order === "desc") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    }
  };

  return (
    <div className="user-management-wrapper">
      {
        <Grid
          columnDefs={columnDefs}
          rowData={rowData}
          serverSidePagination
          rowCount={rowcount}
          serverSideSorting
          showPagination
          showFilterIcon
          pageSizeList={[10, 20, 50, 100]}
          pageSize={20}
          masterDetail
          isSearch={searchString?.length > 0}
          canSearch
          setSearchQuery={(values: string) => {
            searchResults(values);
          }}
          sortBy={(columnName, order) => {
            sordOrder(columnName, order);
          }}
          onPageChange={onPageChange}
          onPageSizeChange={onPageSizeChange}
          searchFieldName="name,email,contact,role"
          headerContent={userManagement_LABELS.userManagement}
          actionButtons={[
            <>
              <Button
                variant="contained"
                endIcon={<AddUser />}
                onClick={() => {
                  setFormType("Add");
                  setInitialFormValue({
                    roleId: "",
                  });
                  setOpenSlider(true);
                }}
              >
                {userManagement_LABELS.addUser}
              </Button>
            </>,
          ]}
          showTotalRowCount
          uniqueIdentifierFieldName={"id"}
        ></Grid>
      }
      <Slider
        isOpen={openSlider}
        onClose={() => {
          setOpenSlider(!openSlider);
          refetch();
        }}
        title={`${formType} User`}
      >
        <UserForm
          formType={formType}
          formInitialValues={initialFormValue}
          onClose={() => {
            setOpenSlider(!openSlider);
            refetch();
          }}
        />
      </Slider>
    </div>
  );
}

export default UserManagement;
