const NotificationLevelsData: any = {
  level1: [
    { data: "New Hospital/Provider Added/Invited" },
    { data: "Provider Submitted the registration form" },
    { data: "Hospital is live" },
    { data: "If any details are modified" },
    { data: "Hospital/Provider deactivated" },
  ],
  level2: [
    { data: "SOF app launch failed at the provider’s end" },
    { data: "Resource fetch call failed" },
    { data: "SOF App launch failed for Patient" },
  ],
};
export default NotificationLevelsData;
