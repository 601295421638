import { API_CONSTANTS } from "../../constants/apiConstants";
import baseApi from "./baseApiService";
export const deletePatientApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    verifyPatientDetails: builder.query({
      query: (filterCriteria) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.VERIFY_PATIENT_DETAILS}`,
        method: "POST",
        body: JSON.stringify(filterCriteria),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    deletePatient: builder.query({
      query: (filterCriteria) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.DELETE_PATIENT}`,
        method: "POST",
        body: JSON.stringify(filterCriteria),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const { useLazyVerifyPatientDetailsQuery, useLazyDeletePatientQuery } =
  deletePatientApi;
