import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#13ADA4",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#FFFFFF",
    },
    text: {
      primary: "#4B4B4B",
    },
  },
  typography: {
    fontFamily: "Poppins",
    fontSize: 22.5,
  },
});
