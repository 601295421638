import epic_step_1 from "../../assets/img/epic_registration_step_1.png";
import epic_step_2 from "../../assets/img/epic_registration_step_2.png";
import epic_step_3 from "../../assets/img/epic_registration_step_3.png";
import epic_step_4 from "../../assets/img/epic_registration_step_4.png";
import epic_step_5 from "../../assets/img/epic_registration_step_5.png";
import open_emr_step_1 from "../../assets/img/OpenEMR_registration_step_1.png";
import open_emr_step_2 from "../../assets/img/OpenEMR_registration_step_2.png";
import open_emr_step_3 from "../../assets/img/OpenEMR_registration_step_3.png";
import open_emr_step_3b from "../../assets/img/OpenEMR_registration_step_3b.png";
import open_emr_step_4 from "../../assets/img/OpenEMR_registration_step_4.png";
import open_emr_step_5 from "../../assets/img/OpenEMR_registration_step_5.png";
import cerner_step_1 from "../../assets/img/cerner_provider_registration_step_1.png";
import cerner_step_2 from "../../assets/img/cerner_provider_registration_step_2.png";
import cerner_step_3 from "../../assets/img/cerner_provider_registration_step_3.png";
import cerner_step_4 from "../../assets/img/cerner_provider_registration_step_4.png";
import cerner_step_5 from "../../assets/img/cerner_provider_registration_step_5.png";
import cerner_step_6 from "../../assets/img/cerner_provider_registration_step_6.png";
import cerner_step_7 from "../../assets/img/cerner_provider_registration_step_7.png";
import cerner_step_8 from "../../assets/img/cerner_provider_registration_step_8.png";
import cerner_step_10 from "../../assets/img/cerner_provider_registration_step_10.png";
const RegistrationData: any = {
  CernerProvider: [
    {
      content: "Login to Cerner Code Console",
      image: cerner_step_1,
    },
    {
      content: "Click on Go to my applications",
      image: cerner_step_2,
    },
    {
      content: "Click on the New Application button on the Right Top",
      image: cerner_step_3,
    },
    {
      content: "Provide the App name and select the App type",
      image: cerner_step_4,
    },
    {
      content: "Select the Type of Access and Application Privacy.",
      image: cerner_step_5,
    },
    {
      content:
        "Provide the Redirect and Launch URLs, select FHIR version and click on the Next button.",
      image: cerner_step_6,
    },
    {
      content:
        "Select the Product Family & Product and click on the Next Button.",
      image: cerner_step_7,
    },
    {
      content:
        "Under API access, provide the required resource read and write access and click on the next button.",
      image: cerner_step_8,
    },
    {
      content:
        "Verify the given details, agree to the terms and conditions, and click on the submit button.",
      image: null,
    },
    {
      content: "Application registration is successful.",
      image: cerner_step_10,
    },
  ],
  EpicProvider: [
    {
      content:
        "Navigate to your EPIC System, Login, Select Build Apps, and Click on Create.",
      image: epic_step_1,
    },
    {
      content:
        "Provide the Application name and select the Audience, Incoming APIs.",
      image: epic_step_2,
    },
    {
      content: "Provide the Redirect URL",
      image: epic_step_3,
    },
    {
      content:
        "Select the FHIR Version, provide the summary, accept the terms & conditions and click on save and ready.",
      image: epic_step_4,
    },
    {
      content:
        "App is registered successfully and is ready for production. (Client ID generated)",
      image: epic_step_5,
    },
  ],
  OpenemrProvider: [
    {
      content:
        "Navigate to the Login screen of OpenEMR, and log in with admin credentials.",
      image: open_emr_step_1,
    },
    {
      content:
        "Navigate to the 'Client Registrations' screen and Click on 'Register New App' to register a client app",
      image: open_emr_step_2,
    },
    {
      content:
        "Fill out the registration form with the below information:\na. Application type - Confidential.\nb. Application Context - Multiple Patients Application.\nc. App Name - Initial_Test_Setup (Can be choice)\n",
      image: open_emr_step_3,
    },
    {
      content:
        "Provide the Redirect URL and launch URL and Scopes Requested: <Select All> (as needed).",
      image: open_emr_step_3b,
    },
    {
      content:
        "Click on Submit, the Client Id and Client Secret will be generated.",
      image: open_emr_step_4,
    },
    {
      content:
        "Navigate to the 'Client Registrations' screen and enable the earlier registered client app (by default the registered client will be disabled)",
      image: open_emr_step_5,
    },
  ],
};
export default RegistrationData;
