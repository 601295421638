import React, { useEffect, useMemo, useState } from "react";
import "./HospitalFilter.scss";
import { Box, Checkbox, Chip, MenuItem, Select } from "@mui/material";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";
import { useTenantNamesQuery } from "../../../services/rtkQueryServices/monitoringService";

function HospitalFilter(props: any) {
  const {
    setAppliedList,
    appliedList,
    disabled,
    showSelectAll,
    displaySelectedValues,
    placeHolder,
    numberOfChipsToBeDisplayed = 2,
  }: {
    setAppliedList: (val: string) => void;
    appliedList: string;
    disabled: boolean;
    showSelectAll?: boolean;
    displaySelectedValues?: boolean;
    placeHolder?: string;
    numberOfChipsToBeDisplayed: number;
  } = props;
  const { data: hospitalNamesList } = useTenantNamesQuery(null);
  const appliedListArray = useMemo(
    () => (appliedList ? appliedList?.split(",") : []),
    [appliedList]
  );
  const [value, setValue] = useState<string[]>(appliedListArray);
  const [showSelect, setShowSelect] = useState<boolean>(false);
  const isAllSelected =
    hospitalNamesList?.data &&
    value?.length === Object.keys(hospitalNamesList?.data)?.length;
  const [searchText, setSearchText] = useState("");
  const [displayedOptions, setDisplayOptions] = useState<string[]>([]);

  useEffect(() => {
    if (hospitalNamesList) {
      if (searchText) {
        setDisplayOptions(
          Object.keys(hospitalNamesList?.data)?.filter((val) =>
            hospitalNamesList?.data[val]
              ?.toLowerCase()
              ?.includes(searchText?.toLowerCase())
          )
        );
      } else {
        setDisplayOptions(Object.keys(hospitalNamesList?.data));
      }
    }
  }, [hospitalNamesList, searchText]);

  useEffect(() => {
    setValue(appliedListArray);
  }, [appliedListArray]);

  const handleChange = (e: any) => {
    let updatedValue = e.target.value;
    if (updatedValue[updatedValue.length - 1] === "all") {
      setValue(isAllSelected ? [] : Object.keys(hospitalNamesList?.data));
      return;
    }
    if (updatedValue?.toString() === "") {
      setValue([]);
      return;
    }
    setValue(updatedValue);
  };

  const handleDelete = (e: React.MouseEvent, deletedValue: string) => {
    e.preventDefault();
    let updatedValue = value.filter((val) => val !== deletedValue);
    setValue(updatedValue);
    setAppliedList(updatedValue.toString());
  };

  const onDropdownOpen = () => {
    let localDisplayedOptions = [...value];
    hospitalNamesList?.data &&
      Object.keys(hospitalNamesList?.data)?.forEach((item: string) => {
        value?.indexOf(item) === -1 && localDisplayedOptions.push(item);
      });
    setDisplayOptions(localDisplayedOptions);
    setShowSelect(true);
  };
  const onDropdownClose = () => {
    let localValue = appliedList ? appliedList?.split(",") : [];
    setValue(localValue);
    setShowSelect(false);
  };

  const apply = (e: any) => {
    setAppliedList(value.toString());
    setShowSelect(false);
    e.stopPropagation();
  };

  const clearAll = (e: any) => {
    setValue([]);
    setAppliedList("");
    e.stopPropagation();
  };
  return (
    <div
      className={`hospital-header-filter-container d-flex ${
        disabled ? "disabled" : ""
      }`}
    >
      <Select
        onChange={handleChange}
        value={value}
        multiple
        open={showSelect}
        onOpen={() => {
          onDropdownOpen();
        }}
        onClose={() => {
          onDropdownClose();
        }}
        displayEmpty
        renderValue={() => (
          <div className="px-3 hospital-header-filter">
            {displaySelectedValues && appliedListArray?.length > 0 ? (
              <Box sx={{ display: "flex", gap: 0.5 }}>
                {appliedListArray
                  ?.slice(0, numberOfChipsToBeDisplayed)
                  ?.map((value: string) => (
                    <Chip
                      title={hospitalNamesList?.data[value]}
                      key={value}
                      sx={{
                        borderRadius: "10px",
                        backgroundColor: "var(--content-bg)",
                        whiteSpace: "nowrap",
                        maxWidth: "100px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                      size="small"
                      clickable
                      deleteIcon={
                        <span
                          className="px-2"
                          onMouseDown={(event) => event.stopPropagation()}
                        >
                          &#x2716;
                        </span>
                      }
                      onDelete={(e) => handleDelete(e, value)}
                      label={
                        <>
                          {hospitalNamesList?.data[value]}
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          ></span>
                        </>
                      }
                    />
                  ))}
                {appliedListArray?.length > numberOfChipsToBeDisplayed && (
                  <Chip
                    sx={{
                      borderRadius: "10px",
                      backgroundColor: "var(--content-bg)",
                    }}
                    size="small"
                    label={`+${
                      appliedListArray?.length - numberOfChipsToBeDisplayed
                    }`}
                  />
                )}
              </Box>
            ) : (
              <>{placeHolder}</>
            )}
          </div>
        )}
        MenuProps={{
          style: { maxWidth: "180px", maxHeight: "250px" },
          autoFocus: false,
        }}
        IconComponent={(props) => {
          return (
            <span className={`pe-2 dropdown-icon`}>
              <DropdownIcon {...props} />
            </span>
          );
        }}
      >
        <div className="filter-dropdown-header">
          <div className="d-flex justify-content-between px-2">
            <button
              disabled={appliedListArray?.length === 0 && value?.length === 0}
              className="dropdown-button clear-all"
              onClick={clearAll}
            >
              Clear All
            </button>
            <button className="dropdown-button apply" onClick={apply}>
              Apply
            </button>
          </div>
          <input
            onKeyDown={(e) => {
              e.stopPropagation();
            }}
            autoFocus
            className="filter-search py-2 px-3"
            placeholder="Search Hospital"
            onChange={(e) => setSearchText(e.target.value?.trim())}
          ></input>
        </div>
        {showSelectAll && !searchText && displayedOptions?.length > 0 && (
          <MenuItem className={isAllSelected ? "selected" : ""} value="all">
            <Checkbox
              indeterminate={
                value?.length > 0 && value?.length < displayedOptions.length
              }
              checked={isAllSelected}
              size="small"
            />
            All
          </MenuItem>
        )}
        {displayedOptions &&
          hospitalNamesList &&
          displayedOptions?.map((val: any, index: number) => (
            <MenuItem
              key={index}
              value={val}
              className="d-flex align-item-center"
            >
              <Checkbox
                color="default"
                size="small"
                checked={value?.indexOf(val) > -1}
                className="me-2"
              />
              <span className={val?.toLowerCase()}>
                {hospitalNamesList?.data[val]}
              </span>
            </MenuItem>
          ))}
        {displayedOptions && displayedOptions?.length === 0 && (
          <div className="px-3 dropdown-empty-state">No Items</div>
        )}
      </Select>
    </div>
  );
}

export default HospitalFilter;
