import baseApi from "./baseApiService";
import { API_CONSTANTS } from "../../constants/apiConstants";
export const notificationApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    userNotifications: builder.query({
      query: (userId) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_USER_NOTIFICATIONS}${userId}`,
        method: "GET",
      }),
    }),
    getAllNotifications: builder.query({
      query: (getAllNotificationsRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_NOTIFICATIONS}`,
        method: "POST",
        body: JSON.stringify(getAllNotificationsRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getAllPendingActions: builder.query({
      query: (getAllPedingActionsRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_PENDING_ACTIONS}`,
        method: "POST",
        body: JSON.stringify(getAllPedingActionsRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    toggleUserEmailNotificationStatus: builder.query({
      query: (subscribeEmailNotificationsRequest) => ({
        url: subscribeEmailNotificationsRequest.subscribeEmailNotification
          ? `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.SUBSCRIBE_EMAIL_NOTIFICATION}`
          : `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.UNSUBSCRIBE_EMAIL_NOTIFICATION}`,
        method: "POST",
        body: JSON.stringify(subscribeEmailNotificationsRequest),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
    getSubscribedNotificationLevelByUserEmail: builder.query({
      query: (fetchByUserEmailRequest) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.FETCH_SUBSCRIBED_NOTIFICATION_LEVEL_BY_USER_EMAIL}`,
        method: "POST",
        body: JSON.stringify(fetchByUserEmailRequest),
        headers:{
          "content-type": "application/json",
          Accept: "*/*",
        }
      }),
    }),
  }),
});

export const {
  useUserNotificationsQuery,
  useLazyToggleUserEmailNotificationStatusQuery,
  useGetAllNotificationsQuery,
  useLazyGetAllNotificationsQuery,
  useGetSubscribedNotificationLevelByUserEmailQuery,
  useLazyGetSubscribedNotificationLevelByUserEmailQuery,
  useLazyGetAllPendingActionsQuery,
} = notificationApi;
