import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../../../constants/routePaths";

function MonitoringLanding() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname === routePaths.monitoring) {
      navigate(routePaths.applicationLogs);
    }
  }, [location]);

  return <Outlet />;
}

export default MonitoringLanding;
