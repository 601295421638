import React, { useMemo } from "react";
import "./EmailTemplateCards.scss";
import { ReactComponent as Edit } from "../../../../assets/svg/edit_rounded.svg";
import { Switch } from "@mui/material";
import { NavLink } from "react-router-dom";
import { routePaths } from "../../../../constants/routePaths";
import { Utility } from "../../../../utils/Utility";

function EmailTemplateCards(props: any) {
  const {
    isEmailTemplatePage,
    item,
    onClick,
    clickableCard,
    selectedEmailTemplateId,
    onToggleChange,
  } = props;
  const isActive = useMemo(
    () =>
      isEmailTemplatePage
        ? selectedEmailTemplateId === item?.emailTemplateId
        : selectedEmailTemplateId === item?.id,
    [isEmailTemplatePage, selectedEmailTemplateId, item]
  );
  const handleToggle = (isActive: boolean) => {
    onToggleChange && onToggleChange(item, isActive);
  };
  return (
    <div
      onClick={() => {
        onClick && onClick(item);
      }}
      key={item?.id}
      className={`${isEmailTemplatePage ? "" : "col-lg-3 col-md-6"} `}
    >
      <div
        className={`email-template-card p-3 ${
          isEmailTemplatePage ? "my-2 mx-4" : "m-4"
        } ${isActive ? "active" : ""} ${clickableCard ? "clickable-card" : ""}`}
      >
        <div className="d-flex justify-content-between align-items-center">
          <span
            className="email-template-name pe-3"
            title={item?.name || item?.emailTemplateName || ""}
          >
            {item?.name || item?.emailTemplateName}
          </span>
          {isEmailTemplatePage ? (
            <span className={item?.isActive ? "active" : "in-active"}>
              {item?.isActive ? "Active" : "Inactive"}
            </span>
          ) : (
            <span className="d-flex align-items-center">
              <span>{item?.isActive ? "Active" : "Inactive"}</span>
              <Switch
                checked={item?.isActive}
                onChange={(e) => {
                  handleToggle(e.target.checked);
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              />
            </span>
          )}
        </div>
        {!isEmailTemplatePage && (
          <div className="pt-2">Templates - {item?.count || 0}</div>
        )}
        <div
          title={`Last updated by ${
            item?.modifiedBy
          } on ${Utility.getDateInParticularFormat(item?.modifiedDate)}`}
          className="email-template-modified-info py-3"
        >
          Last updated by {item?.modifiedBy} on{" "}
          {Utility.getDateInParticularFormat(item?.modifiedDate)}
        </div>
        {!isEmailTemplatePage && (
          <div className="d-flex justify-content-end">
            <NavLink
              to={`${routePaths.settings}/${routePaths.email}?emailType=${item?.id}`}
              className="navigate-link d-flex"
              title={"Edit"}
            >
              <span>Edit</span>
              <span className="edit-icon ms-1">
                <Edit />
              </span>
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailTemplateCards;
