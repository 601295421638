export const routePaths = {
  base: "/",
  wild: "*",
  login: "/login",
  otp: "/otp",
  setPassword: "/setPassword/:userId",
  resetPassword: "/resetPassword/:userId",
  userManagement: "/userManagement",
  hospitalManagement: "/hospitalManagement",
  hospitalUserForm: "/hospitalUserForm/:tenantApplicationInformationId",
  notifications: "/notifications",
  deletePatient: "/deletePatient",
  emailTemplate: "emailTemplate",
  email: "email",
  updatePassword: "/updatePassword",
  dashboard: "/dashboard",
  monitoring: "/monitoring",
  fieldConfiguration: "/fieldConfiguration",
  settings: "/settings",
  registrationInstruction: "/registrationInstruction/:ehrTypeId",
  applicationMetrics: "applicationMetrics",
  applicationLogs: "applicationLogs",
  applicationLogsWithParams: "applicationLogs/:activityType?",
  error: "/error",
};
