export const metricsInfoTexts = {
  RegisteredHospitals:
    "The total number of hospitals that are registered from the admin portal.",
  LaunchedHospitals: "The Hospitals that has launched the SoF Application.",
  ApprovedButNeverLaunched:
    "The Hospital has been approved but did not launch the app.",
  NumberOfPatientsCreated:
    "The number of unique patients that are saved to the database.",
  NumberOfPatientsPresentations:
    "The number of patients that were launched and presented.",
  LaunchFailures: "The count of total app launch failures.",
  NumberOfPatientsNotAvailable:
    "The number of patients for whom the clinical information is not available to present.",
  PatientsDeleted: "The number of patients de-identified.",
};
