import React, { ReactNode, useMemo, useState } from "react";
import EmailTemplateCards from "../EmailTemplateCards/EmailTemplateCards";
import "./EmailTemplateLanding.scss";
import { useEmailTemplateTypesQuery } from "../../../../services/rtkQueryServices/masterDataApi";
import { useLazyUpdateEmailTemplateTypeStatusQuery } from "../../../../services/rtkQueryServices/emailTemplateApi";
import ConfirmationDialog from "../../../shared/ConfirmationDialog/ConfirmationDialog";

function EmailTemplateLanding() {
  const { data, refetch: refetchEmailTemplateType } =
    useEmailTemplateTypesQuery(null);
  const emailTemplateTypes = useMemo(() => data?.data, [data]);
  const [updateEmailTemplateTypeStatus] =
    useLazyUpdateEmailTemplateTypeStatusQuery();
  const updateStatus = (item: any, isActive: boolean) => {
    updateEmailTemplateTypeStatus({
      emailTemplateTypeId: item?.id,
      isActive: isActive,
    }).then((res) => {
      res?.isSuccess && refetchEmailTemplateType();
    });
  };
  const onToggleChange = (item: any, isActive: boolean) => {
    if (!isActive) {
      setOpenConfirmation({
        open: true,
        message: (
          <div>
            <div>Emails will not be sent to the users.</div>
            <div>Are you sure to proceed?</div>
          </div>
        ),
        onConfirm() {
          updateStatus(item, isActive);
          setOpenConfirmation({ open: false });
        },
        onCancel() {
          setOpenConfirmation({ open: false });
        },
      });
    } else {
      setOpenConfirmation({
        open: true,
        onConfirm() {
          updateStatus(item, isActive);
          setOpenConfirmation({ open: false });
        },
        message: "Are you sure to change the status to active?",
        onCancel() {
          setOpenConfirmation({ open: false });
        },
      });
    }
  };

  const [openConfirmation, setOpenConfirmation] = useState<{
    open: boolean;
    message?: ReactNode;
    onConfirm?: () => void;
    onCancel?: () => void;
  }>();

  return (
    <div className="email-template-container h-100 d-flex flex-column overflow-auto">
      <div className="email-template-heading m-4">Email Templates</div>
      <div className="row mx-0">
        {emailTemplateTypes &&
          emailTemplateTypes?.length > 0 &&
          emailTemplateTypes?.map((item: any) => {
            return (
              <EmailTemplateCards
                onToggleChange={onToggleChange}
                item={item}
              ></EmailTemplateCards>
            );
          })}
        <ConfirmationDialog
          open={openConfirmation?.open}
          message={openConfirmation?.message}
          cancelButtonName={"Cancel"}
          confirmButtonName={"Confirm"}
          onCancel={() => {
            openConfirmation?.onCancel();
          }}
          onConfirm={() => {
            openConfirmation?.onConfirm();
          }}
        />
      </div>
    </div>
  );
}

export default EmailTemplateLanding;
