import "./ConfirmationDialog.scss";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import React, { ReactNode } from "react";
import DialogContentText from "@mui/material/DialogContentText";
import { shared_LABELS } from "../../../constants/labels/Shared-labels";

interface ConfirmationDialogProps {
  open: boolean;
  title?: string;
  message: ReactNode;
  confirmButtonName?: string;
  cancelButtonName?: string;
  cancelOnClose?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onOk?: () => void;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title = "",
  message,
  confirmButtonName,
  cancelButtonName,
  cancelOnClose = true,
  onConfirm,
  onCancel,
  onOk,
}) => {
  return (
    <>
      <Dialog
        open={open}
        onClose={cancelOnClose && onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="d-flex justify-content-center dialog-content-text">
              {message}
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {onOk ? (
            <div className="d-flex justify-content-center w-100">
              {onOk && (
                <Button variant="contained" onClick={onOk} autoFocus>
                  {shared_LABELS.ok}
                </Button>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-between w-100">
              {onCancel && (
                <Button variant="outlined" onClick={onCancel}>
                  {cancelButtonName || shared_LABELS.cancel}
                </Button>
              )}
              {onConfirm && (
                <Button variant="contained" onClick={onConfirm} autoFocus>
                  {confirmButtonName || shared_LABELS.confirm}
                </Button>
              )}
            </div>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
