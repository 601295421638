import { ReactNode, useCallback, useEffect, useRef } from "react";
import "./Slider.scss";

interface SliderProps {
  isOpen?: boolean;
  title?: string;
  onClose: () => void;
  children: ReactNode;
  severity?: number;
  closeOnOverLayClick?: boolean;
}

const Slider: React.FC<SliderProps> = ({
  isOpen,
  title,
  onClose,
  children,
  closeOnOverLayClick = true,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      document.body.classList.add("slider-open");
    } else {
      document.body.style.overflow = "";
      document.body.classList.remove("slider-open");
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={"slider-overlay " + (isOpen ? "add-overlay" : "")}
        data-testid={isOpen ? "add-overlay" : "slider-overlay "}
        onClick={() => {
          if (closeOnOverLayClick) {
            onClose();
          }
        }}
      />
      <div className={"slider-view " + (isOpen ? "open-slider-view" : "")}>
        <div className="slider-content" ref={sliderRef}>
          <div
            className={
              "slider-header d-flex justify-content-between align-items-center "
            }
          >
            <div className="slider-title">{title}</div>
            <button type="button" className="slider-close" onClick={onClose}>
              &times;
            </button>
          </div>
          {isOpen && <div className="slider-body">{children}</div>}
        </div>
      </div>
    </>
  );
};
export default Slider;
