import { API_CONSTANTS } from "../../constants/apiConstants";
import baseApi from "./baseApiService";
export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: (filterCriteria) => ({
        url: `${API_CONSTANTS.BASE_URL}${API_CONSTANTS.GET_ALL_USERS}`,
        method: "POST",
        body: JSON.stringify(filterCriteria),
        headers: {
          "content-type": "application/json",
          Accept: "*/*",
        },
      }),
    }),
  }),
});

export const { useGetAllUsersQuery, useLazyGetAllUsersQuery } = userApi;
