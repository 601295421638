import React, { useEffect, useMemo } from "react";
import Modal from "../../shared/Modal/Modal";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";
import { Utility } from "../../../utils/Utility";
import { monitoring_LABELS } from "../../../constants/labels/Monitoring/monitoring_LABELS";
import { ReactComponent as LinkIcon } from "../../../assets/svg/link.svg";
import { ReactComponent as Failure } from "../../../assets/svg/error.svg";
import { ReactComponent as Success } from "../../../assets/svg/check_circle.svg";
import { Link } from "react-router-dom";
import Grid from "../../shared/Grid/Grid";
import { useLazyApplicationMetricsLogsQuery } from "../../../services/rtkQueryServices/monitoringService";
import { SOURCE } from "../../../enums/source";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import { ACTION_TYPE } from "../../../enums/actionType";

function ApplicationLogsSessionModal(props: any) {
  const { isOpen, setIsOpen, sessionId, tenantName } = props;
  const [getApplicationResourceMetrics, fetchedApplicationResourceMetrics] =
    useLazyApplicationMetricsLogsQuery();
  const applicationResourceMetrics = useMemo(
    () => fetchedApplicationResourceMetrics?.data?.data,
    [fetchedApplicationResourceMetrics]
  );
  const TimeCellRenderer = (props: any) => {
    return <>{Utility.convertSecondsToMinutesAndSeconds(props?.value)}</>;
  };

  useEffect(() => {
    isOpen && getApplicationResourceMetrics({ sessionId: props?.sessionId });
  }, [getApplicationResourceMetrics, isOpen, props]);

  const statusCellRenderer = (props: any) => {
    return (
      <>
        {props.value ? (
          <div
            className={`${props.value?.toLowerCase()} d-flex align-items-center `}
            title={props.value}
          >
            {props.value?.toLowerCase() === "success" ? (
              <Success />
            ) : (
              <Failure />
            )}
            <span className="ps-1">{props.value}</span>
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };
  const DescriptionCellRenderer = (props: any) => {
    return <>{props && ACTION_TYPE[props?.value]}</>;
  };
  const ResponseCellRenderer = (props: any) => {
    return (
      <div title={props?.value} className="grid-cell-ellipsis">
        {props?.value}
      </div>
    );
  };

  const SystemCellRenderer = (props: any) => {
    return <>{SOURCE[props?.value] && SOURCE[props?.value]}</>;
  };
  const gridColumnDefs: GridColumnDefs[] = [
    {
      headerName: "Description",
      field: "actionId",
      cellRenderer: DescriptionCellRenderer,
    },
    {
      headerName: "System",
      field: "sourceId",
      cellRenderer: SystemCellRenderer,
    },
    {
      headerName: "callStartTime",
      field: "activityStartTime",
      cellRenderer: UtcDateTime,
    },
    {
      headerName: monitoring_LABELS.elapsedTime,
      field: "activityElapsedTime",
      cellRenderer: TimeCellRenderer,
    },
    {
      headerName: "Count",
      field: "resourcesCount",
      cellRenderer(props) {
        return <div>{props?.value || 0}</div>;
      },
    },
    {
      headerName: monitoring_LABELS.status,
      field: "status",
      cellRenderer: statusCellRenderer,
      sortable: true,
    },
    {
      headerName: monitoring_LABELS.response,
      field: "description",
      cellRenderer: ResponseCellRenderer,
    },
  ];

  const ActivityGridHeader = () => {
    return (
      <div className="d-flex justify-content-between w-100 pe-4 pt-4">
        <div className="d-flex flex-column">
          <span className="hospital-name-modal-header">{tenantName}</span>
          <span className="launch-modal-header">Launch ID - {sessionId}</span>
        </div>
        <span>
          <Link
            className={`navigate-link ${
              applicationResourceMetrics?.cloudWatchLink ? "" : "link-disabled"
            }`}
            target="_blank"
            to={applicationResourceMetrics?.cloudWatchLink}
          >
            <span>
              <LinkIcon />
            </span>
            AWS cloud watch link
          </Link>
        </span>
      </div>
    );
  };
  return (
    <Modal
      isOpen={isOpen}
      title={"Hospital"}
      headerBg="#FFFFFF"
      headerColor="#13ada4"
      modalWidth="1820px"
      headerComponent={<ActivityGridHeader />}
      onClose={setIsOpen}
    >
      {isOpen && (
        <div className="hospital-grid-logs">
          <Grid
            rowData={applicationResourceMetrics?.resourceMetricLogs}
            columnDefs={gridColumnDefs}
          ></Grid>
        </div>
      )}
    </Modal>
  );
}

export default ApplicationLogsSessionModal;
