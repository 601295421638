import React from "react";
import "./LoginError.scss";
import { Link } from "react-router-dom";
import { routePaths } from "../../constants/routePaths";

function LoginError(props: any) {
  return (
    <div className="d-flex w-100 flex-column login-error-wrapper">
      <div className="p-3 my-3 error-information">{props.errorMessage}</div>
      <div className="support-contact-details p-3">
        <div>
          <Link to={routePaths.login}>Click here</Link> for login page.
        </div>
        <div>For support please reach out to administrator- </div>
        <div>eagleadmin@precedencehealthcare.com</div>
      </div>
    </div>
  );
}

export default LoginError;
