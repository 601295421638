import React, { useEffect, useMemo, useState } from "react";
import { Button, IconButton } from "@mui/material";
import EmailTemplateCards from "../EmailTemplateCards/EmailTemplateCards";
import { ReactComponent as Add } from "../../../../assets/svg/add.svg";
import { ReactComponent as BackArrow } from "../../../../assets/svg/arrow_back.svg";
import "./Email.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { routePaths } from "../../../../constants/routePaths";
import EmailTemplateEditor from "../EmailTemplateEditor/EmailTemplateEditor";
import { searchParam } from "../../../../constants/searchParam";
import { useLazyGetEmailTemplateVersionsByEmailTemplateIdQuery } from "../../../../services/rtkQueryServices/emailTemplateApi";

function Email() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const emailTypeId = useMemo(
    () => searchParams.get(searchParam.emailType),
    [searchParams]
  );
  const [
    getEmailTemplateVersionsByEmailTemplateId,
    emailTemplateVersionsByEmailTemplateId,
  ] = useLazyGetEmailTemplateVersionsByEmailTemplateIdQuery();
  const emailTemplateVersionsData = useMemo(
    () => emailTemplateVersionsByEmailTemplateId?.data?.data,
    [emailTemplateVersionsByEmailTemplateId]
  );
  useEffect(() => {
    emailTypeId && getEmailTemplateVersionsByEmailTemplateId(emailTypeId);
  }, [emailTypeId, getEmailTemplateVersionsByEmailTemplateId]);
  useEffect(() => {
    emailTemplateVersionsData &&
      setSelectEmailTemplateId(
        emailTemplateVersionsData?.templateVersions?.find(
          (item: any) => item?.isActive
        )?.emailTemplateId
      );
  }, [emailTemplateVersionsData]);
  const [selectedEmailTemplateId, setSelectEmailTemplateId] =
    useState<number>(null);
  const [clickedEmailTemplateId, setClickedEmailTemplateId] =
    useState<number>();
  const handleClick = (emailTemplate?: any) => {
    let item: any = emailTemplate
      ? { ...emailTemplate }
      : { emailTemplateId: null };
    if (item) {
      setClickedEmailTemplateId(item);
    } else {
      setClickedEmailTemplateId(item);
    }
  };

  return (
    <div className="h-100">
      <div className="email-template-sub-header d-flex justify-content-between mb-4">
        <div>
          <IconButton
            onClick={() => {
              navigate(`${routePaths.settings}/${routePaths.emailTemplate}`);
            }}
          >
            <BackArrow />
          </IconButton>
          Email Templates
        </div>
        <div>
          <Button
            className="create-template-button"
            variant="contained"
            onClick={() => {
              handleClick();
            }}
            disabled={!selectedEmailTemplateId}
          >
            <span className="px-2">
              <Add />
            </span>
            Create Template
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-between pb-4 email-template-content">
        <div className="h-100 overflow-auto email-template-card-container">
          <div
            className="mb-2 mt-4 mx-5 pb-2 email-template-heading"
            title={emailTemplateVersionsData?.templateTypeName}
          >
            {emailTemplateVersionsData?.templateTypeName}
          </div>
          <div className="d-flex flex-column mt-4">
            {emailTemplateVersionsData &&
              emailTemplateVersionsData?.templateVersions?.length > 0 &&
              emailTemplateVersionsData?.templateVersions?.map((item: any) => {
                return (
                  <EmailTemplateCards
                    onClick={handleClick}
                    isEmailTemplatePage
                    item={item}
                    clickableCard
                    selectedEmailTemplateId={selectedEmailTemplateId}
                  />
                );
              })}
          </div>
        </div>
        <EmailTemplateEditor
          clickedCard={clickedEmailTemplateId}
          setSelectEmailTemplateId={setSelectEmailTemplateId}
          templatePlaceHolders={emailTemplateVersionsData?.templatePlaceHolders}
          emailTypeId={emailTypeId}
          selectedEmailTemplateId={selectedEmailTemplateId}
          getEmailTemplateVersionsByEmailTemplateId={
            getEmailTemplateVersionsByEmailTemplateId
          }
        />
      </div>
    </div>
  );
}

export default Email;
