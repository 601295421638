export const monitoring_LABELS = {
  launchId: "Launch ID",
  hospitalName: "Hospital name",
  appLaunch: "App launched On",
  status: "Status",
  response: "Response",
  callTime: "Call time",
  resourcesFetched: "Resources fetched",
  elapsedTime: "Elapsed time",
};
