import { MONITORING_ACTIVITY } from "../../enums/monitoringActivity";

export const MonitoringNavTabData = [
  {
    name: MONITORING_ACTIVITY.PROVIDER,
    displayName: "Provider Activity",
  },
  {
    name: MONITORING_ACTIVITY.PATIENT,
    displayName: "Patient Activity",
  },
];
