import {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";
import { UserService } from "../UserService";
import { Mutex } from "async-mutex";
import { API_CONSTANTS } from "../../constants/apiConstants";

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: "",
  prepareHeaders(headers, api) {
    const token = UserService.accessToken;
    if (token && !headers.has("Authorization")) {
      headers.append("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error && result.error.status === 401 && api.endpoint !== "login") {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();
      try {
        const refreshResult = await baseQuery(
          {
            url: `${API_CONSTANTS.BASE_AUTHENTICATION_URL}${API_CONSTANTS.REFRESH_TOKEN}`,
            body: JSON.stringify({
              refreshToken: UserService.refreshToken,
              token: UserService.accessToken,
            }),
            method: "POST",
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          },
          api,
          extraOptions
        );
        if (refreshResult.data) {
          UserService.logIn((refreshResult?.data as any)?.data);
          result = await baseQuery(args, api, extraOptions);
        } else {
          UserService.logOut();
        }
      } catch (e: any) {
        UserService.logOut();
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();
      result = await baseQuery(args, api, extraOptions);
    }
  }

  return result;
};

export const baseApi = createApi({
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
});
export default baseApi;
