import React, { useEffect, useMemo, useState } from "react";
import { monitoring_LABELS } from "../../../constants/labels/Monitoring/monitoring_LABELS";
import { GridColumnDefs } from "../../../models/Grid/gridColumnDefs";
import { ReactComponent as Failure } from "../../../assets/svg/error.svg";
import { ReactComponent as Success } from "../../../assets/svg/check_circle.svg";
import { MONITORING_ACTIVITY } from "../../../enums/monitoringActivity";
import { useLazyApplicationLogsQuery } from "../../../services/rtkQueryServices/monitoringService";
import { Utility } from "../../../utils/Utility";
import Grid from "../../shared/Grid/Grid";
import "./ActivityGrid.scss";
import HospitalFilter from "../HospitalFilter/HospitalFilter";
import UtcDateTime from "../../shared/UtcDateTime/UtcDateTime";
import ApplicationLogsSessionModal from "../ApplicationLogsSessionModal/ApplicationLogsSessionModal";
import { useSearchParams } from "react-router-dom";
import { searchParam } from "../../../constants/searchParam";

function ActivityGrid(props: any) {
  const { activity, dateFilter } = props;
  const [getActivityData, fetchedActivityData] = useLazyApplicationLogsQuery();
  const activityData = useMemo(
    () => fetchedActivityData?.data?.data,
    [fetchedActivityData]
  );
  let [searchParams, setSearchParams] = useSearchParams();
  const [activeSessionDescriptionId, setActiveSessionDescriptionId] =
    useState<string>(null);
  const tenantIdSearchParam = useMemo(
    () => searchParams?.get(searchParam.tenantId),
    [searchParams]
  );
  const [filterCriteria, setFilterCriteria] = useState<any>({
    tenantList: tenantIdSearchParam,
    activityType: MONITORING_ACTIVITY[activity],
    pageNumber: 1,
    pageSize: 20,
  });
  const [resetSort, setResetSort] = useState<boolean>(false);
  const addHospitalFilter = (filteredList: string) => {
    if (tenantIdSearchParam && tenantIdSearchParam !== filteredList) {
      searchParams.delete(searchParam.tenantId);
      setSearchParams(searchParams);
    }
    setFilterCriteria({
      ...filterCriteria,
      tenantList: filteredList,
    });
  };

  useEffect(() => {
    if (activity !== filterCriteria.activityType) {
      setFilterCriteria({
        tenantList: tenantIdSearchParam,
        pageNumber: 1,
        pageSize: 20,
        activityType: MONITORING_ACTIVITY[activity],
      });
      setResetSort(true);
    }
  }, [activity]);

  useEffect(() => {
    filterCriteria.activityType &&
      getActivityData({ ...filterCriteria, ...dateFilter });
  }, [filterCriteria, dateFilter]);

  const statusCellRenderer = (props: any) => {
    return (
      <>
        <div
          className={`${props.value?.toLowerCase()} d-flex align-items-center `}
          title={props.value}
        >
          {props.value?.toLowerCase() === "success" ? <Success /> : <Failure />}
          <span className="ps-1">{props.value}</span>
        </div>
      </>
    );
  };

  const TimeCellRenderer = (props: any) => {
    return <>{Utility.convertSecondsToMinutesAndSeconds(props?.value)}</>;
  };

  const HospitalNameCellRenderer = (props: any) => {
    return <span className="monitoring-hospital-name">{props?.value}</span>;
  };

  const HospitalNameHeader = () => {
    return (
      <HospitalFilter
        appliedList={filterCriteria?.tenantList}
        setAppliedList={addHospitalFilter}
        placeHolder={"Hospital"}
      />
    );
  };

  const ApplicationLogsActions = (props: any) => {
    let launchId = props?.data?.sessionId;
    let tenantName = props?.data?.tenantName;
    return (
      <>
        <span
          className="navigate-link"
          onClick={() => {
            setActiveSessionDescriptionId(launchId);
          }}
        >
          View logs
        </span>
        {activeSessionDescriptionId && (
          <ApplicationLogsSessionModal
            isOpen={activeSessionDescriptionId === launchId}
            sessionId={launchId}
            tenantName={tenantName}
            setIsOpen={() => {
              setActiveSessionDescriptionId(null);
            }}
          />
        )}
      </>
    );
  };

  const LaunchCellRenderer = (props: any) => {
    return <span style={{ fontSize: "14px" }}>{props?.value}</span>;
  };
  const DescriptionCellRenderer = (props: any) => {
    return (
      <div title={props?.value} className="grid-cell-ellipsis">
        {props?.value}
      </div>
    );
  };

  const columnDefs: GridColumnDefs[] = [
    {
      headerName: monitoring_LABELS.launchId,
      field: "sessionId",
      sortable: true,
      cellRenderer: LaunchCellRenderer,
      style: { width: "150px" },
    },
    {
      headerName: monitoring_LABELS.hospitalName,
      field: "tenantName",
      cellRenderer: HospitalNameCellRenderer,
      valueFormatter: HospitalNameHeader,
    },
    {
      headerName: monitoring_LABELS.appLaunch,
      field: "activityStartTime",
      cellRenderer: UtcDateTime,
      sortable: true,
    },
    {
      headerName: monitoring_LABELS.elapsedTime,
      field: "activityElapsedTime",
      cellRenderer: TimeCellRenderer,
      sortable: true,
    },
    {
      headerName: monitoring_LABELS.status,
      field: "status",
      cellRenderer: statusCellRenderer,
      sortable: true,
    },
    {
      headerName: monitoring_LABELS.response,
      field: "description",
      cellRenderer: DescriptionCellRenderer,
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRenderer: ApplicationLogsActions,
    },
  ];
  const onPageChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: value,
    });
  };
  const onPageSizeChange = (value: any) => {
    setFilterCriteria({
      ...filterCriteria,
      pageNumber: 1,
      pageSize: value,
    });
  };
  const sortOrder = (columnName: string, order: string) => {
    if (order === "unsorted") {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: null,
        sortOrder: null,
      });
    } else {
      setFilterCriteria({
        ...filterCriteria,
        sortByColumnName: columnName,
        sortOrder: order,
      });
    }
  };
  return (
    <div className="activity-grid-wrapper">
      <Grid
        rowData={activityData?.data}
        columnDefs={columnDefs}
        showPagination
        sortBy={(columnName, order) => {
          sortOrder(columnName, order);
          setResetSort(false);
        }}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
        rowCount={activityData?.totalCount}
        pageSizeList={[10, 20, 50, 100]}
        pageSize={20}
        resetSort={resetSort}
      ></Grid>
    </div>
  );
}

export default ActivityGrid;
