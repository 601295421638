import "./Login.scss";
import { ReactComponent as BrandingImage } from "../../../src/assets/svg/logo.svg";
import { ReactComponent as Logo } from "../../../src/assets/svg/PrecedenceLogo.svg";
import { Outlet } from "react-router-dom";
import { LOGIN_LITERALS } from "../../constants/literals";
export default function Login() {
  return (
    <div className="login-container d-flex h-100vh">
      <div className=" d-flex align-items-center justify-content-center left-container">
        <div className="logo">
          <Logo />
        </div>
        <div className="d-flex align-items-center justify-content-center login-wrapper flex-column position-relative">
          <Outlet />
          <div className="support">{LOGIN_LITERALS.support}</div>
        </div>
      </div>
      <div className="right-container d-flex align-items-center justify-content-center p-2">
        <div>
          <BrandingImage className="branding-image" />
        </div>
      </div>
    </div>
  );
}
