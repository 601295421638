import * as dateFns from "date-fns";
const DateRangeDefaultData: any = [
  {
    label: "Last 7 Days",
    value: [
      dateFns.startOfDay(dateFns.subDays(new Date(), 6)),
      dateFns.endOfDay(new Date()),
    ],
  },
  {
    label: "Last 30 Days",
    value: [
      dateFns.startOfDay(dateFns.subDays(new Date(), 29)),
      dateFns.endOfDay(new Date()),
    ],
  },
  {
    label: "Last 90 Days",
    value: [
      dateFns.startOfDay(dateFns.subDays(new Date(), 89)),
      dateFns.endOfDay(new Date()),
    ],
  },
];
export default DateRangeDefaultData;
