export enum TENANT_STATUS {
  SAVED = 1,
  INITIATED,
  EXPIRED,
  SUBMITTED,
  LIVE,
  FAILED,
  DEACTIVATED,
  RE_INITIATED,
}
