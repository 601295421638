import React, { useEffect, useState } from "react";
import "./Dropdown.scss";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { TextField } from "@mui/material";
import { ReactComponent as DropdownIcon } from "../../../assets/svg/coolicon_down.svg";

function Dropdown(props: any) {
  const {
    label,
    menuItems,
    name,
    placeholder,
    formik,
    required,
    disabled,
    forceShowError,
  } = props;
  const [value, setValue] = useState<string>(formik?.values[name] || "");
  const [focused, setFocused] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  useEffect(() => {
    if (props?.clearValue) {
      setValue(null);
    }
  }, [props?.clearValue]);
  useEffect(() => {
    setValue(formik?.values[name]);
    if (formik && !formik.isDirty) {
      setIsDirty(false);
    }
  }, [formik]);

  const handleClick = (e: any) => {
    if (e.currentTarget.ariaSelected === "true") {
      setValue("");
      formik.setFieldValue(name, "");
    }
  };

  return (
    <div className="input-group">
      {label && (
        <label>
          {label}
          {required && <span className="required">*</span>}
        </label>
      )}
      <div
        className={`input-dropdown-control ${disabled && "dropdown-disabled"}`}
      >
        <TextField
          select
          name={name}
          required={required}
          margin="normal"
          value={value}
          onChange={(value) => {
            if (props.onChange) {
              props?.onChange(value.target.value);
            }
            if (formik?.handleChange) {
              formik.handleChange(value);
            }
            setValue(value.target.value);
          }}
          onFocus={() => {
            setFocused(true);
          }}
          onBlur={() => {
            setIsDirty(true);
            setFocused(false);
          }}
          fullWidth
          disabled={disabled}
          SelectProps={{
            IconComponent: DropdownIcon,
          }}
          label={value || focused ? "" : placeholder}
        >
          {menuItems &&
            menuItems?.length > 0 &&
            menuItems?.map((menuItem: any) => (
              <MenuItem
                key={menuItem?.value}
                value={menuItem?.value}
                onClick={(e) => {
                  handleClick(e);
                }}
              >
                {menuItem?.displayValue}
              </MenuItem>
            ))}
        </TextField>
      </div>
      {required && (isDirty || forceShowError) && !value && (
        <div className="error-message">
          <span className="custom-error-message">{label} is Required</span>
        </div>
      )}
    </div>
  );
}

export default Dropdown;
