import React, { useRef, useState, useEffect } from "react";
import InitialAvatar from "../InitialAvatar/InitialAvatar";
import "./Header.scss";
import { ReactComponent as NotificationLogo } from "../../../../../src/assets/svg/notifications.svg";
import { ReactComponent as Logo } from "../../../../../src/assets/svg/PrecedenceLogo.svg";
import { ReactComponent as HamBurger } from "../../../../../src/assets/svg/HamBurger.svg";
import { ReactComponent as OpenHamBurger } from "../../../../../src/assets/svg/OpenHamBurger.svg";
import { ReactComponent as Logout } from "../../../../../src/assets/svg/logout.svg";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import { StyledMenu } from "../Theme/StyledMenu";
import { UserService } from "../../../../services/UserService";
import { useLocation, useNavigate } from "react-router-dom";
import { routePaths } from "../../../../constants/routePaths";
import { useLazyGetAllNotificationsQuery } from "../../../../services/rtkQueryServices/notificationApi";
import Slider from "../../Slider/Slider";
import UserForm from "../../../UserForm/UserForm";
import { useLazyGetUserByEmailQuery } from "../../../../services/rtkQueryServices/UserApi";
import { useLazySignOutQuery } from "../../../../services/rtkQueryServices/authenticationService";
export interface notificationDropdown {
  name: string;
  content: string;
  time: any;
  isRead: boolean;
}
export interface HeaderProps {
  userName?: string;
  role?: string;
  profilePic?: string;
  showNotification?: boolean;
  containsUnreadNotifications?: boolean;
  notificationDropdown: notificationDropdown[];
  isActive?: boolean;
  seenNotification?: () => void;
  onSearch?: (value: string) => void;
  setIsSideNavOpen?: (value: boolean) => void;
}

const Header = (props: HeaderProps) => {
  const {
    profilePic,
    showNotification,
    containsUnreadNotifications,
    isActive,
  } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const [headerShadow, setHeaderShadow] = useState<boolean>(false);
  const [openSlider, setOpenSlider] = useState<boolean>(false);
  const [initialFormValue, setInitialFormValue] = useState();
  const [formType, setFormType] = useState<string>("View");
  const profileRef = useRef(null);
  const notificationRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [getUserDetails] = useLazyGetUserByEmailQuery();
  const [logOutQuery] = useLazySignOutQuery();
  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);
    function scrollEvent(event: Event) {
      if (window.scrollY > 20) {
        setHeaderShadow(true);
      } else {
        setHeaderShadow(false);
      }
    }
    window.addEventListener("keydown", focusSearchInput);
    function focusSearchInput(event: KeyboardEvent): void {
      if (event.ctrlKey && event.code === "KeyF" && inputRef.current) {
        inputRef.current.focus();
      }
    }
    return () => {
      window.removeEventListener("keydown", focusSearchInput);
    };
  });
  const [anchorEl, setAnchorEl] = useState<any>();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [getAllNotifications, notificationData] =
    useLazyGetAllNotificationsQuery();
  const handleNotification = (e: any) => {
    navigate(routePaths.notifications);
    if (location.pathname === "/notifications") {
      getAllNotifications({
        pageNumber: 1,
        pageSize: 20,
      });
    }
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = () => {
    logOutQuery({ userId: UserService.getClaims.UserId });
    UserService.logOut();
  };

  const userDetails = () => {
    let userEmailRequest = {
      Email : UserService.getClaims?.email
    }
    getUserDetails(
      userEmailRequest
    ).then((res) => {
      if (res?.data?.isSuccess) {
        setInitialFormValue(res?.data?.data);
      }
    });
  };

  return (
    <div className="header-wrapper">
      <header className={"main-header " + (headerShadow && "header-shadow")}>
        {isActive ? (
          <OpenHamBurger
            onClick={() => {
              props.setIsSideNavOpen(!isActive);
            }}
            className="cursor-pointer"
          />
        ) : (
          <HamBurger
            onClick={() => {
              props.setIsSideNavOpen(!isActive);
            }}
            className="cursor-pointer"
          />
        )}
        <div
          style={isActive ? { paddingLeft: "15px" } : { paddingLeft: "15px" }}
        >
          <Logo />
        </div>
        <div className="actions">
          <div className="account-action">
            {showNotification && (
              <>
                <div className="notification">
                  <div
                    className="ic-notification-container"
                    ref={notificationRef}
                  >
                    <Button
                      id="demo-customized-button"
                      aria-haspopup="true"
                      variant="text"
                      disableElevation
                      onClick={handleNotification}
                      className="h-100"
                    >
                      <span
                        className={
                          "icon " +
                          (containsUnreadNotifications ? "ic-unread " : "")
                        }
                      >
                        <NotificationLogo />
                      </span>
                    </Button>
                  </div>
                </div>
              </>
            )}
            <Button
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="text"
              disableElevation
              onClick={handleClick}
              className="h-100"
            >
              <div className="user-profile" ref={profileRef}>
                <div className="user-profile-control">
                  <InitialAvatar
                    name={UserService?.getClaims?.userName}
                    avatar={profilePic}
                    bgColor={"#13ADA4"}
                  ></InitialAvatar>
                  <div className="d-flex flex-column">
                    <span
                      className="user-name"
                      title={UserService?.getClaims?.userName}
                    >
                      {UserService?.getClaims?.userName}
                      <svg
                        width="10"
                        height="7"
                        viewBox="0 0 10 7"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="ms-3"
                      >
                        <path
                          d="M8.21973 0.969727L4.99998 4.18948L1.78023 0.969727L0.719727 2.03023L4.99998 6.31048L9.28023 2.03023L8.21973 0.969727Z"
                          fill="currentColor"
                        />
                      </svg>
                    </span>
                    <span className="user-name role">
                      {UserService.getClaims?.role}
                    </span>
                  </div>
                </div>
              </div>
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  userDetails();
                  setOpenSlider(true);
                  handleClose();
                }}
                disableRipple
              >
                <div className="d-flex justify-content-between align-items-center w-100 mx-3 profile-wrapper">
                  <span>View Profile</span>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  navigate(routePaths.updatePassword);
                  handleClose();
                }}
                disableRipple
              >
                <div className="d-flex justify-content-between align-items-center w-100 mx-3 profile-wrapper change-password-wrapper">
                  <span>Change Password</span>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  logOut();
                }}
                disableRipple
              >
                <div className="d-flex justify-content-between align-items-center w-100 mx-3 profile-wrapper logout-wrapper">
                  <span>Logout</span>
                  <Logout />
                </div>
              </MenuItem>
            </StyledMenu>
          </div>
        </div>
      </header>
      <Slider
        isOpen={openSlider}
        onClose={() => {
          setOpenSlider(!openSlider);
          setFormType("View");
        }}
        title={`User Profile`}
      >
        {openSlider && initialFormValue && (
          <UserForm
            formType={formType}
            setFormType={setFormType}
            formInitialValues={initialFormValue}
            onClose={() => {
              setOpenSlider(false);
              setFormType("View");
              setInitialFormValue(null);
            }}
          />
        )}
      </Slider>
    </div>
  );
};
export default Header;
