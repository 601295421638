import { useState } from "react";
import Header from "../../components/shared/Widget/Header/Header";
import SideNav from "../../components/shared/Widget/Side-nav/Side-nav";
import "./LandingLayout.scss";
import { Outlet } from "react-router-dom";

function LandingLayout() {
  const [mobileToggle, setMobileToggle] = useState<boolean>(false);
  const [isSideNavOpen, setIsSideNavOpen] = useState<boolean>(false);
  const notificationDropdown = [
    {
      name: "Support user",
      content: "Updated attribute to portable settings for customer Test",
      time: "Today at 9:50 AM",
      isRead: false,
    },
    {
      name: "support user",
      content: "Updated attribute to portable settings for customer Test",
      time: "Today at 9:50 AM",
      isRead: true,
    },
  ];

  function mobileMenuToggle() {
    if (mobileToggle) {
      setMobileToggle(false);
    } else {
      setMobileToggle(true);
    }
  }

  return (
    <div
      className={
        "d-flex admin-content-wrapper " + (mobileToggle ? "show-menu" : "")
      }
    >
      {mobileToggle}
      <div
        className={"admin-left-wrapper " + (isSideNavOpen ? "" : "inactive")}
      >
        <div
          className="hamburger-icon pr-4 pt-4 text-right cursor-pointer"
          onClick={mobileMenuToggle}
        >
          <span className="nav-menu-close">{/* <Close /> */}</span>
        </div>
        <div>
          <div>{isSideNavOpen}</div>
          <SideNav
            isSideNavOpen={isSideNavOpen}
            setIsSideNavOpen={setIsSideNavOpen}
          />
        </div>
      </div>
      {
        <Header
          userName="superadmin@techigai.io"
          role="Support user"
          showNotification={true}
          setIsSideNavOpen={(e) => {
            setIsSideNavOpen(e);
          }}
          isActive={isSideNavOpen}
          containsUnreadNotifications={
            notificationDropdown.filter((e) => e.isRead === false).length > 0
          }
          notificationDropdown={notificationDropdown}
        />
      }
      <div
        className={"admin-right-wrapper " + (isSideNavOpen ? "" : "inactive")}
      >
        <div
          onClick={mobileMenuToggle}
          className="nav-menu-open header-badge-wrapper"
        >
          <span className="material-icons nav-menu-open-icon">
            {/* <Menu /> */}
          </span>
        </div>
        <div className="admin-main-content p-4 h-100">
          {/* <Dashboard /> */}
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default LandingLayout;
