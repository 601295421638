import React, { useState, useEffect, ReactNode } from "react";
import SVG from "react-inlinesvg";
import { Tooltip } from "@mui/material";
import "./InitialAvatar.scss";

export interface InitialAvatarProps {
  dim?: string;
  initialsColor?: string;
  borderColor?: string;
  bgColor?: string;
  avatar?: string;
  name?: string;
  showTooltip?: boolean;
}

const InitialAvatar = (props: InitialAvatarProps) => {
  const dim = props.dim || "36px";
  const initialsColor = props.initialsColor;
  const borderColor = props.borderColor;
  const bgColor = props.bgColor;
  const avatar = props.avatar;
  const name = props.name || "Unknown";
  let [localName, setLocalName] = useState("");
  let [localAvatar, setLocalAvatar] = useState("");
  let [initials, setInitials] = useState("");
  let [isSVG, setIsSVG] = useState(false);
  const [avatarPicError, SetAvatarPicError] = useState(false);
  const showTooltip = props.showTooltip;

  useEffect(() => {
    if (name !== null && name !== undefined) {
      setLocalName(name.trim());
      setInitials(getInitials(localName));
    }
    if (avatar) {
      if (
        avatar.substring(avatar.lastIndexOf("."), avatar.length) === ".svg" ||
        avatar.includes("<svg")
      ) {
        setIsSVG(true);
      }
      setLocalAvatar(avatar);
    }
  }, [localAvatar, name, avatar, localName]);
  function getInitials(name: string): any {
    if (name) {
      const names = name.split(" ");
      let initials = names[0][0];
      initials +=
        names.length > 1
          ? names[names.length - 1][0]
          : names[0][1]
          ? names[0][1]
          : "";
      return initials.toUpperCase();
    }
  }
  const AvatarTemplate = () => {
    return (
      <>
        {localAvatar &&
          (isSVG ? (
            <SVG
              className="user-icon"
              onLoad={() => {
                SetAvatarPicError(false);
              }}
              onError={() => {
                SetAvatarPicError(true);
              }}
              src={localAvatar}
            ></SVG>
          ) : (
            <img
              className="user-image"
              onLoad={() => {
                SetAvatarPicError(false);
              }}
              onError={() => {
                SetAvatarPicError(true);
              }}
              src={localAvatar}
              alt="Undefined"
            ></img>
          ))}
        {(!localAvatar || avatarPicError) && (
          <span style={{ fontSize: "calc(" + dim + "* 0.4 )" }}>
            {initials}
          </span>
        )}
      </>
    );
  };

  return (
    <div className="initial-avatar">
      {showTooltip ? (
        <Tooltip title={localName} arrow>
          <div
            className="user-avatar-container"
            style={{
              height: dim,
              width: dim,
              backgroundColor: bgColor,
              borderColor: borderColor,
              color: initialsColor,
            }}
          >
            <AvatarTemplate></AvatarTemplate>
          </div>
        </Tooltip>
      ) : (
        <div
          className="user-avatar-container"
          style={{
            height: dim,
            width: dim,
            backgroundColor: bgColor,
            borderColor: borderColor,
            color: initialsColor,
          }}
        >
          <AvatarTemplate></AvatarTemplate>
        </div>
      )}
    </div>
  );
};
export default InitialAvatar;
