function Download(props: any) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width}
      height={props.height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_1_5265)">
        <path
          d="M4.00024 17.0001V19.0001C4.00024 19.5306 4.21096 20.0393 4.58603 20.4143C4.9611 20.7894 5.46981 21.0001 6.00024 21.0001H18.0002C18.5307 21.0001 19.0394 20.7894 19.4145 20.4143C19.7895 20.0393 20.0002 19.5306 20.0002 19.0001V17.0001"
          stroke={props.fillColor || "#13ADA4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M7.00024 11.0001L12.0002 16.0001L17.0002 11.0001"
          stroke={props.fillColor || "#13ADA4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 4V16"
          stroke={props.fillColor || "#13ADA4"}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
}

export default Download;
