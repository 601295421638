import React from "react";
import { Utility } from "../../../utils/Utility";
import "./UtcDateTime.scss";

function UtcDateTime(props: any) {
  return (
    <div className="date-time-container d-flex flex-column">
      <div className="date">
        {Utility.getDateInParticularFormat(props?.value)}
      </div>
      <div className="time">UTC- {Utility.getTime(props?.value)}</div>
    </div>
  );
}

export default UtcDateTime;
